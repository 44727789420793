<template>
  <div
    class="main-content sys-role-manage"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="#ffffff"
  >
  <dialog-file-view-private
      :dialogFileViewPrivateInfo="dialogFileViewPrivateInfo"
      @closeFileView="closeFileView"
    ></dialog-file-view-private>
    <div class="btn-area">
      <div class="right">
        <el-button
          type="default"
          size="small"
          @click="$router.push({ path: '/sales/myCustomer/myCustomer' })"
        >
          返 回
        </el-button>
      </div>
    </div>
    <div class="student_info">
      <div class="student_left">
        <dl class="left_dl">
          <dt><img :src="studentInfo.photo" alt="" ></dt>
          <dd>
            <p>姓名：{{ studentInfo.name }}</p>
            <p>联系方式：{{studentInfo.contact ? studentInfo.contact : "暂无"}}</p>
            <p>学段：{{studentInfo.sectionName ? studentInfo.sectionName : "暂无数据"}}</p>
            <p>账号：{{studentInfo.account}} <i title="点击复制" v-show="copyStr" class="icon_copy_student_info el-icon-document-copy" data-clipboard-target="#copy_student_info"></i></p>
            <input style="position:absolute; left: -100px; top: -100px;"  id="copy_student_info" v-model="copyStr" type="text">
          </dd>
        </dl>
        <div class="infoDiv">
          <span>基本信息</span>
          <ul>
            <li>出生日期：{{studentInfo.birthday ? studentInfo.birthday : "暂无数据"}}</li>
            <li>星座：{{studentInfo.constellationName ? studentInfo.constellationName:'暂无数据'}}</li>
            <li>家庭住址：{{studentInfo.provinceId ? studentInfo.provinceName + studentInfo.cityName + studentInfo.countyName:"暂无数据"}}</li>
            <li>所在学校：{{studentInfo.schoolName ? studentInfo.schoolName : "暂无数据"}}</li>
            <li>所在区域：{{studentInfo.schoolReach ? studentInfo.schoolReach:"暂无数据"}}</li>
            <li>孩子性格：{{studentInfo.childNature ? studentInfo.childNature : "暂无数据"}}</li>
            <li>孩子母亲：{{studentInfo.childMotherName ? studentInfo.childMotherName:"暂无数据"}}</li>
            <li>母亲电话：{{studentInfo.childMotherPhone ? studentInfo.childMotherPhone:"暂无数据"}}</li>
            <li>孩子父亲：{{studentInfo.childFatherName ? studentInfo.childFatherName:"暂无数据"}}</li>
            <li>父亲电话：{{studentInfo.childFatherPhone ? studentInfo.childFatherPhone:"暂无数据"}}</li>
            <li>微信/QQ：{{studentInfo.parentWechat ? studentInfo.parentWechat : "暂无数据"}}</li>
            <li>来源渠道：{{sourceTypeFn(studentInfo.sourceType)}}</li>
            <li v-show="studentInfo.sourceType==5">转介绍人姓名：{{studentInfo.referralstudent ? studentInfo.referralstudent : "暂无数据"}}</li>
            <li v-show="studentInfo.sourceType==5">转介绍人电话：{{studentInfo.referralphone ? studentInfo.referralphone : "暂无数据"}}</li>
            <li>导入方式：{{studentImportType(studentInfo.importType)}}</li>
            <li>首次上课时间：{{firstClassField}}</li>
            <li>正式上课时间：{{formalClassField}}</li>
            <li>首次上课时间备注：{{studentInfo.firstClassTimeRemark ? studentInfo.firstClassTimeRemark:"暂无数据"}}</li>
            <li>正式上课时间备注：{{studentInfo.formalClassTimeRemark ? studentInfo.formalClassTimeRemark:"暂无数据"}}</li>
          </ul>
        </div>
      </div>
      <div class="student_right">
          <div class="right_operation" v-show="statusShow!=false">
            <p>
              <el-button type="primary" size="small" @click="addOrderClick()"
              v-show="getStatus==1"
              >下单</el-button
            >
            <el-button type="primary" size="small" @click="daalEdit"
              >编辑</el-button
            >
            </p>
          <p>
            <el-button
              type="primary"
              icon="el-icon-arrow-left"
              size="small"
              @click="prevAndNextClick('next')"
              :disabled="!nextId"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-arrow-right"
              size="small"
              @click="prevAndNextClick('prev')"
              :disabled="!prevId"
            ></el-button>
          </p>
          </div>
          <div class="followUp_area">
            <div class="right_followUp">
              <div class="info_title">跟进记录</div>
              <div class="followUp_func" v-show="statusShow!=false">
                <div class="select-condition-item">
                  <span class="item-lable">客户意向程度：</span>
                  <el-select
                    v-model="studentInfo.intentionId"
                    placeholder="请选择"
                    size="small"
                    @change="intentionChange"
                  >
                    <el-option
                      v-for="item in intentionList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
                <el-button
                  type="primary"
                  size="small"
                  class="mL20"
                  @click="addFollowUpClick()"
                  >新增跟进记录</el-button
                >
              </div>
            </div>
        <table class="self_table mT10">
          <thead>
            <tr>
              <td>序号</td>
              <td>跟进记录</td>
              <td v-show="statusShow!=false">操作</td>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in followUpList">
              <tr :key="item.id">
                <td rowspan="2">{{ index + 1 }}</td>
                <td class="follow_content">
                  <div>
                    <span>跟进内容：</span>
                    <p>{{item.followContent}}</p>
                    <p>
                      <el-image
                    class="td-img"
                    v-for="(items,index) in item.photoList" :key="index"
                    style="width: 100px; height: 100px;margin-left:5px;"
                    :src="items.url"
                    :preview-src-list="item.imageArray"
                  ></el-image>
                    </p>
                  </div>
                  <div v-show="item.followupTags!=null">
                    <span>标签：</span>
                    <span>{{ dealTags(item.followupTags) }}</span>
                  </div>
                </td>
                <td rowspan="2" v-show="statusShow!=false">
                  <el-button type="text" @click="editFollowUpClick(item)"
                    >编辑</el-button
                  >
                </td>
              </tr>
              <tr :key="index">
                <td class="follow_info">
                  <span>跟进人：{{ item.saleName }}</span>
                  <span class="mL20">跟进时间：{{ item.createTime }}</span>
                  <span class="mL20"
                    >下次跟进时间：{{
                      item.nextFollowupTime ? item.nextFollowupTime : "暂未设置"
                    }}</span
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
       <el-divider></el-divider>
      <div class="course_info_see" style="margin-top:15px;">
        <div class="info_title">课程信息</div>
        <div class="required_subject">
          <span>选择必考科目：</span>
          <template v-for="item in subjectList_info">
            <el-checkbox
              v-model="item.select"
              v-show="item.value == 'sys.teach.subject.base'"
              class="mR10"
              :key="item.macroId"
              disabled
              >{{ item.name }}</el-checkbox
            >
          </template>
        </div>
        <div class="select_course">所选课程</div>
        <div class="select_subject" style="margin-top:10px;">
          <div
            class="select_subject_item"
            v-for="item in subjectList_info"
            :key="item.macroId"
            v-show="item.status"
          >
            <div class="item_subject_title left" style="margin-top:20px;">
              <el-button type="primary" size="small">{{ item.name }}</el-button>
            </div>
            <el-form >
              <div class="teacher_ask">
                <p>设置讲师要求</p>
                <el-row :gutter="20" class="el_row">
                  <el-col :span="6">
                    <el-form-item label="讲课速度">
                      <el-select
                        v-model="item.speedNeedId"
                        placeholder="请选择"
                        size="small"
                        disabled 
                      >
                        <el-option
                          v-for="item in teachSpeedList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="性别偏好" prop="sexNeedId">
                      <el-select
                        v-model="item.sexNeedId"
                        placeholder="请选择"
                        size="small"
                        disabled 
                      >
                        <el-option
                          v-for="item in teachSexList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="年龄偏好" prop="ageNeedId">
                      <el-select
                        v-model="item.ageNeedId"
                        placeholder="请选择"
                        size="small"
                        disabled 
                      >
                        <el-option
                          v-for="item in teachYearList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="风格偏好" prop="styleNeedId">
                      <el-select
                        v-model="item.styleNeedId"
                        placeholder="请选择"
                        size="small"
                        disabled 
                      >
                        <el-option
                          v-for="item in teachStyleList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="teacher_ask">
                <p style="font-weight: 600;font-size:16px;margin-left:30px;text-right:right;" >学生成绩</p>
                <ul class="paperUl">
                  <li v-for="(item,index) in item.xmtStudentAcademicRecordList" :key="index">
                    <span>{{item.examinationTime?item.examinationTime.substring(0,11):""}}</span>
                    <span>{{examinationTypeFn(item.examinationType)}}</span>
                    <span>{{item.subjectScore}}/{{item.subjectAllScore}}</span>
                    <span style="width:220px;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;-webkit-box-orient: vertical;display: -webkit-box;">{{item.remarks}}</span>
                    <span>
                    <span style="color:#1890ff;cursor:pointer;" @click="paperPreview(item)">预览</span>
                    </span>
                  </li>
                </ul>
              </div>
            </el-form>
          </div>
        </div>
      </div>
       <el-divider></el-divider>
       <div class="order_area">
        <div class="info_title">充值记录</div>
        <p style="padding:10px 0;">总计缴费金额：{{allRechargeMoney}}元</p>
        <el-table
          size="small"
          :data="rechargeData"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          border
          tooltip-effect="dark"
        >
          <el-table-column
            prop="orderNo"
            label="序号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="totalPrice"
            label="缴费金额"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="zdyGiveClassHour"
            label="赠送课时"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="courseTypeId"
            label="收款类型"
            :formatter="dealMoneyType"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="充值时间"
            align="center"
            width="160"
          ></el-table-column>
          <el-table-column
            label="审核状态"
            align="center"
            :formatter="formatterOrderStatus"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="300px" v-show="statusShow!=false">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click.stop="lookReason(scope.row)"
                v-show="scope.row.orderStatus==2"
                >查看拒绝原因</el-button
              >
              <el-button
                type="text"
                size="small"
                v-show="scope.row.orderStatus==1||scope.row.orderStatus==2"
                @click.stop="editOrderClick(scope.row)"
                >修改</el-button
              >
              <el-button
                type="text"
                size="small"
                @click.stop="seeOrderClick(scope.row)"
                >查看</el-button
              >
              <el-button
                type="text"
                size="small"
                v-show="scope.row.orderStatus==1||scope.row.orderStatus==2"
                @click.stop="removeOrderClick(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="order_area">
        <div class="info_title">下单记录</div>
        <el-table
          size="small"
          :data="orderList"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          border
          tooltip-effect="dark"
        >
          <el-table-column
            prop="orderNo"
            label="编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="productName"
            label="产品名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="orderType"
            :formatter="formatterOrderType"
            label="产品类型"
            align="center"
          ></el-table-column>
          <!-- <el-table-column
            prop="courseTypeId"
            :formatter="formatterCourseType"
            label="课程类型"
            align="center"
          ></el-table-column> -->
          <el-table-column
            :formatter="formatterClassHour"
            label="购课时长（课时）"
            align="center"
          ></el-table-column>
          <el-table-column
            :formatter="formatterTotalPrice"
            label="总价（元）"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="下单时间"
            align="center"
            width="160"
          ></el-table-column>
          <el-table-column
            :formatter="formatterOrderStatus"
            label="订单状态"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="300px" v-show="statusShow!=false">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click.stop="lookReason(scope.row)"
                v-show="scope.row.orderStatus==2"
                >查看拒绝原因</el-button
              >
              <el-button
                type="text"
                size="small"
                v-show="scope.row.orderStatus==1||scope.row.orderStatus==2"
                @click.stop="editOrderClick(scope.row)"
                >修改</el-button
              >
              <el-button
                type="text"
                size="small"
                @click.stop="seeOrderClick(scope.row)"
                >查看</el-button
              >
              <el-button
                type="text"
                size="small"
                v-show="scope.row.orderStatus==1||scope.row.orderStatus==2"
                @click.stop="removeOrderClick(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      </div>
    </div>
    <el-dialog
      title="编辑客户信息"
      :visible.sync="saveDialogVisible"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <div class="info-title">学生信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学生姓名" prop="name">
                <el-input v-model="saveRuleForm.name" :maxlength="5"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="contact">
                <el-input
                  v-model="saveRuleForm.contact"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="性别" prop="sex">
                <el-radio-group v-model="saveRuleForm.sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年级" prop="sex">
                <el-select
                  v-model="saveRuleForm.sectionId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sectionList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所在学校" prop="schoolName">
                <el-input v-model="saveRuleForm.schoolName"></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="12">
              <el-form-item label="学校所在区域" prop="schoolReach">
                <el-input v-model="saveRuleForm.schoolReach"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20">
             <el-col :span="12">
               <el-form-item label="来源渠道" prop="sourceType">
                <el-select
                  v-model="saveRuleForm.sourceType"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sourceTypeList2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
             </el-col>
             <el-col :span="12">
              <el-form-item label="孩子性格" prop="childNature">
                <el-input v-model="saveRuleForm.childNature"></el-input>
              </el-form-item>
            </el-col>
           </el-row>
           <el-row :gutter="20" v-show="saveRuleForm.sourceType=='5'">
            <el-col :span="12">
              <el-form-item label="转介绍人姓名" prop="referralstudent">
                <el-input v-model="saveRuleForm.referralstudent" :maxlength="5"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="转介绍人电话" prop="referralphone">
                <el-input v-model="saveRuleForm.referralphone"  :maxlength="11"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
            <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="出生日期" prop="birthday">
                <el-date-picker
                  :picker-options="expireTimeOption"
                  v-model="saveRuleForm.birthday"
                  type="date"
                  :default-value="new Date('2003-01-01')"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  @change="birthdayChange"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="星座" prop="constellationId">
                <el-select
                  v-model="saveRuleForm.constellationId"
                  placeholder="请选择"
                  size="small"
                  filterable 
                  :disabled="true"
                >
                  <el-option
                    v-for="item in constellationList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="家庭住址">
            <el-row type="flex">
              <el-col :span="8">
                <el-select
                  v-model="saveRuleForm.provinceId"
                  placeholder="请选择"
                  size="small"
                  @change="provinceChange"
                >
                  <el-option
                    v-for="item in provinceList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveRuleForm.cityId"
                  placeholder="请选择"
                  size="small"
                  @change="cityChange"
                >
                  <el-option
                    v-for="item in cityList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveRuleForm.countyId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in countyList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="学生其他需求">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  maxlength="500"
                  v-model="saveRuleForm.studentsOtherNeeds">
                </el-input>
          </el-form-item>
          <div class="info-title">家长信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="母亲姓名" prop="childMotherName">
                <el-input v-model="saveRuleForm.childMotherName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="母亲电话" prop="childMotherPhone">
                <el-input v-model="saveRuleForm.childMotherPhone" :maxlength="11"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="父亲姓名" prop="childFatherName">
                <el-input v-model="saveRuleForm.childFatherName" maxlength="11"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="父亲电话" prop="childFatherPhone">
                <el-input v-model="saveRuleForm.childFatherPhone" :maxlength="11"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="微信/QQ" prop="parentWechat">
            <el-input v-model="saveRuleForm.parentWechat"></el-input>
          </el-form-item>
          <div class="info-title">空闲时间</div>
          <div class="class_title">首次上课时间</div>
          <div class="table_area">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in firstClassTimeList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status">{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="class_tip">
              <span>上午：8:00-12:00</span>
              <span>下午：12:00-18:00</span>
              <span>晚上：18:00-22:00</span>
            </div>
            <el-form-item label="时间备注" prop="firstClassTimeRemark">
              <el-input v-model="saveRuleForm.firstClassTimeRemark"></el-input>
            </el-form-item>
          </div>
          <div class="class_title">正式上课时间</div>
          <div class="table_area">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in formalClassTimeList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status">{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="class_tip">
              <span>上午：8:00-12:00</span>
              <span>下午：12:00-18:00</span>
              <span>晚上：18:00-22:00</span>
            </div>
            <el-form-item label="时间备注" prop="formalClassTimeRemark">
              <el-input v-model="saveRuleForm.formalClassTimeRemark"></el-input>
            </el-form-item>
          </div>
          <div>
            <div class="info-title">课程信息</div>
            <el-tabs stretch v-model="subjectTabs">
              <el-tab-pane label="文化课" name="文化课">
                <div class="required_subject left">
                  <span>选择必考科目：</span>
                  <template v-for="item in subjectList">
                    <el-checkbox
                      v-model="item.select"
                      v-show="item.value == 'sys.teach.subject.base'"
                      class="mR10 mT10"
                      :key="item.macroId"
                      >{{ item.name }}</el-checkbox
                    >
                  </template>
                </div>
                <div class="left">
                  <template v-for="item in subjectList">
                    <el-button
                      type="primary"
                      v-model="item.status"
                      v-show="item.value == 'sys.teach.subject.base'"
                      class="mR10 mT10"
                      :key="item.macroId"
                      size="small"
                      :disabled="item.status"
                      @click="item.status = !item.status"
                      >{{ item.name }}</el-button
                    >
                  </template>
                </div>
              </el-tab-pane>
              <el-tab-pane label="小语种" name="小语种">
                <div class="left">
                  <template v-for="item in subjectList">
                    <el-button
                      type="primary"
                      v-model="item.status"
                      v-show="item.value == 'sys.teach.subject.language'"
                      class="mR10"
                      :key="item.macroId"
                      size="small"
                      :disabled="item.status"
                      @click="item.status = !item.status"
                      >{{ item.name }}</el-button
                    >
                  </template>
                </div>
              </el-tab-pane>
              <el-tab-pane label="艺术类" name="艺术类">
                <div class="left">
                  <template v-for="item in subjectList">
                    <el-button
                      type="primary"
                      v-model="item.status"
                      v-show="item.value == 'sys.teach.subject.art'"
                      class="mR10"
                      :key="item.macroId"
                      size="small"
                      :disabled="item.status"
                      @click="item.status = !item.status"
                      >{{ item.name }}</el-button
                    >
                  </template>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div class="select_subject">
              <div
                class="select_subject_item"
                v-for="(item,index) in subjectList"
                :key="index"
                v-show="item.status"
              >
                <div class="item_subject_title left" style="margin-top:30px;display:flex;align-items: center;justify-content: space-between;">
                  <span>{{ item.name }}</span>
                  <el-button type="danger" size="small" @click="removeSubject(index,item.id)">删除</el-button>
                </div>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="讲课速度">
                      <el-select
                        v-model="item.speedNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachSpeedList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="性别偏好" prop="sexNeedId">
                      <el-select
                        v-model="item.sexNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachSexList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="年龄偏好" prop="ageNeedId">
                      <el-select
                        v-model="item.ageNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachYearList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="风格偏好" prop="styleNeedId">
                      <el-select
                        v-model="item.styleNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachStyleList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <p style="font-weight: 600;font-size:16px;margin-left:30px;" >学生成绩 <el-button size="mini" type="primary" @click="addStudentResult(item,index)" style="margin-left:10px;">添加</el-button></p>
                <ul class="paperUl">
                  <li v-for="(item,indexs) in item.xmtStudentAcademicRecordList" :key="indexs">
                    <span>{{examinationTimeFn(item.examinationTime)}}</span>
                    <span>{{examinationTypeFn(item.examinationType)}}</span>
                    <span>{{item.subjectScore}}/{{item.subjectAllScore}}</span>
                    <span>{{item.remarks}}</span>
                    <span>
                    <span style="color:#1890ff;cursor:pointer;" @click="paperPreview(item)">预览</span>
                    <span style="color:#1890ff;cursor:pointer;padding:0 5px;" @click="editStudent(item)">编辑</span>
                    <span style="color:#1890ff;cursor:pointer;" @click="paperRemove(item,index,indexs)">删除</span>
                    </span>
                  </li>
                </ul>
                <!-- <el-form-item label="其他要求">
                  <el-input placeholder="请输入内容" v-model="item.content">
                  </el-input>
                </el-form-item> -->
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave('saveRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="followUpDialog.title"
      :visible.sync="followUpDialogVisible"
      :close-on-click-modal="false"
      width="900px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleFormFollowUp"
          status-icon
          ref="saveRuleFormFollowUp"
          label-width="100px"
          size="small"
        >
          <el-form-item label="下次跟进时间" prop="nextFollowupTime">
            <el-date-picker
              v-model="saveRuleFormFollowUp.nextFollowupTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              :picker-options="expireTimeOptionBefore"
              placeholder="选择日期时间"
              class="date_picker"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="跟进记录" prop="followContent">
            <el-input
                  type="textarea"
                  :rows="3"
                  maxlength="500"
                  show-word-limit
                  placeholder="请输入内容"
                  v-model="saveRuleFormFollowUp.followContent"
                >
                </el-input>
          </el-form-item>
        <el-form-item label="图片记录" prop="photo">
            <div class="img_list">
                <div
                  class="img_item img_item_true"
                  v-for="(item, index) in photoList"
                  :key="index"
                >
                  <div class="img_hover">
                    <span
                      class="iconfont icon-yulan1 icon_preview"
                      title="预览"
                      @click="imgItemSeeClick(item, index)"
                    ></span>
                    <span

                      class="iconfont icon-shanchu4 icon_del"
                      title="删除"
                      @click="imgItemDelClick(index)"
                    ></span>
                  </div>
                  <img :src="item.url" alt="" />
                </div>
                <div class="img_item" @click="imgSelectClick">
                  <span class="iconfont icon-danyehuaban icon_new"></span>
                  <input
                    type="file"
                    ref="uploadImg"
                    @change="imgSelectChange"
                    style="display: none"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                  />
                </div>
              </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelFollowUp()" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSaveFollowUp('saveRuleFormFollowUp')"
          size="small"
          :disabled="followDisabled"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="orderDialog.title"
      :visible.sync="orderDialogVisible"
      :close-on-click-modal="false"
      width="650px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleFormOrder"
          status-icon
          :rules="saveRuleOrder"
          ref="saveRuleFormOrder"
          label-width="90px"
          size="small"
        >
          <el-form-item label="产品类型" prop="orderType">
            <el-row>
              <el-col :span="7">
                 <el-select
                  v-model="saveRuleFormOrder.orderType"
                  placeholder="请选择"
                  @change="orderClick"
                  size="small"
                  :disabled="
                      orderDialog.type == 'see' || orderDialog.type == 'edit'
                    "
                >
                  <el-option value="5" label="一对一充值"></el-option>
                  <el-option value="2" label="一对多"></el-option>
                  <el-option value="3" label="试听课"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="课程类型"
            prop="courseTypeId"
            v-show="saveRuleFormOrder.orderType == 2"
          >
            <el-radio-group
              v-model="saveRuleFormOrder.courseTypeId"
              :disabled="orderDialog.type == 'see'"
              @change="orderClick"
            >
              <el-radio label="1">大班课</el-radio>
              <el-radio label="2">小班课</el-radio>
              <el-radio label="3">答疑课</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="选择产品"
            v-show="saveRuleFormOrder.orderType!=5"
            prop="productId"
            :disabled="orderDialog.type == 'see'"
          >
          <el-row>
            <el-col :span="7">
              <el-select
              v-model="saveRuleFormOrder.productId"
              placeholder="请选择"
              @change="productChange"
              :disabled="orderDialog.type == 'see'"
            >
              <el-option
                v-for="item in productList"
                :key="item.id"
                :label="item.productName"
                :value="item.id"
              ></el-option>
            </el-select>
            </el-col>
          </el-row>
          </el-form-item>
          <el-form-item
            label="产品规格"
            v-show="saveRuleFormOrder.orderType!=5"
          >
            <el-row type="flex" v-show="saveRuleFormOrder.orderType !=2">
              <el-col :span="5">
                <el-select
                  v-model="saveRuleFormOrder.sectionId"
                  placeholder="请选择学段"
                  @change="orderSectionChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.sectionList"
                    :key="item.sectionId"
                    :label="item.sectionName"
                    :value="item.sectionId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="5" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.teacherTitleId"
                  placeholder="请选择教师头衔"
                  @change="orderTeacherTitleChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.teacherTitleList"
                    :key="item.teacherTitleId"
                    :label="item.teacherTitleName"
                    :value="item.teacherTitleId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="6" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.classHourId"
                  placeholder="请选择课时"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.classHourList"
                    :key="item.id"
                    :label="item.classHourName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row type="flex" v-show="saveRuleFormOrder.orderType == 2">
              <el-col :span="12">
                <el-select
                  v-model="saveRuleFormOrder.periodId"
                  placeholder="请选择学段"
                  @change="orderSectionChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.periodList"
                    :key="item.periodId"
                    :label="item.periodName"
                    :value="item.periodId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.subjectId"
                  placeholder="请选择学科"
                  @change="orderSubjectChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.subjectList"
                    :key="item.subjectId"
                    :label="item.subjectName"
                    :value="item.subjectId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="mT10"
              v-show="saveRuleFormOrder.orderType == 2"
            >
              <el-col :span="12">
                <el-select
                  v-model="saveRuleFormOrder.teacherTitleId"
                  placeholder="请选择教师头衔"
                  @change="orderTeacherTitleChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.teacherTitleList"
                    :key="item.teacherTitleId"
                    :label="item.teacherTitleName"
                    :value="item.teacherTitleId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.classHourId"
                  placeholder="请选择课时"
                  @change="orderClassHourChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.classHourList"
                    :key="item.id"
                    :label="item.classHourName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="mT10"
              v-show="saveRuleFormOrder.classHourId != -1"
            >
              <el-col :span="12">
                <div class="course_info">
                  <span>课时单价：</span>
                  <span>{{ saveRuleFormOrder.singlePrice }}元</span>
                </div>
              </el-col>
              <el-col :span="12" class="mL20">
                <div class="course_info">
                  <span>总价：</span>
                  <span>{{ saveRuleFormOrder.orderType!=2?saveRuleFormOrder.totalPrice:saveRuleFormOrder.zdyActualPrice }}元</span>
                </div>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="mT20"
              v-show="saveRuleFormOrder.classHourId == -1"
            >
              <el-col :span="8">
                <el-form-item label="课时数：" prop="zdyClassHour">
                  <input
                    type="text"
                    :disabled="orderDialog.type == 'see'"
                    v-model="saveRuleFormOrder.zdyClassHour"
                    class="self_input"
                    @keyup="filterZdyClassHour"
                    onpaste="return false"
                    @blur="zdyClassHourBlur()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="单价：" prop="zdySinglePrice">
                  <input
                    type="text"
                    :disabled="orderDialog.type == 'see'"
                    v-model="saveRuleFormOrder.zdySinglePrice"
                    class="self_input"
                    onpaste="return false"
                    @keyup="fileterZdySinglePrice"
                    @blur="zdySinglePriceBlur()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="总金额：" prop="zdyTotalPrice">
                  <input
                    :disabled="orderDialog.type == 'see'"
                    type="text"
                    v-model="saveRuleFormOrder.zdyTotalPrice"
                    class="self_input"
                    onpaste="return false"
                    @keyup="filterZdyTotalPrice"
                    @blur="zdyTotalPriceBlur"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" v-show="saveRuleFormOrder.classHourId == -1">
                <el-col :span="8">
                <el-form-item label="赠送课时：" prop="zdyGiveClassHour">
                  <input
                    type="text"
                    :disabled="orderDialog.type == 'see'"
                     @keyup="saveRuleFormOrder.zdyGiveClassHour=saveRuleFormOrder.zdyGiveClassHour.replace(/[^\d]/g,'')"
                    v-model="saveRuleFormOrder.zdyGiveClassHour"
                    class="self_input"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="优惠金额：" prop="zdyDiscountAmount">
                  <input
                    :disabled="orderDialog.type == 'see'"
                    type="text"
                    v-model="saveRuleFormOrder.zdyDiscountAmount"
                    class="self_input"
                    onpaste="return false"
                    @keyup="filterZdyDiscountAmount"
                    @blur="zdyDiscountAmountBlur()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实际支付：" prop="zdyActualPrice">
                  <input
                    :disabled="orderDialog.type == 'see'"
                    type="text"
                    v-model="saveRuleFormOrder.zdyActualPrice"
                    onpaste="return false"
                    @keyup="filterZdyActualPrice"
                    @blur="zdyActualPriceBlur"
                    class="self_input"
                  />
                </el-form-item>
              </el-col>
            </el-row> 
          </el-form-item>
          <el-form-item label="缴费金额" prop="totalPrice" v-show="saveRuleFormOrder.orderType==5">
            <el-input style="width:140px;" maxlength="7" @blur="totalPriceBlur()" v-model="saveRuleFormOrder.totalPrice" placeholder="请输入缴费金额"></el-input>
            <span style="color:#909399;font-size:12px;margin-left:10px;">{{translateTotal}}</span>
          </el-form-item>
          <el-form-item label="赠送课时" prop="zdyGiveClassHour" v-show="saveRuleFormOrder.orderType==5&&saveRuleFormOrder.moneyType!=4">
            <el-input style="width:140px;" type="number" min="0" v-model="saveRuleFormOrder.zdyGiveClassHour" placeholder="请输入赠送课时"></el-input>
          </el-form-item>
          <el-form-item label="优惠金额" prop="payType" v-show="saveRuleFormOrder.orderType==2">
            <el-input style="width:140px;" @blur="morenAmount()" v-model="saveRuleFormOrder.zdyDiscountAmount"></el-input>
          </el-form-item>
          <el-form-item label="支付方式" prop="payType">
            <el-radio-group
              v-model="saveRuleFormOrder.payType"
              :disabled="orderDialog.type == 'see'"
            >
              <el-radio label="2">线下支付</el-radio>
              <el-radio label="3">余额支付</el-radio>
              <!-- <el-radio label="1">线上支付</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="收款方式"
            prop="payMent"
            v-show="
              (saveRuleFormOrder.orderType == 5 ||
                saveRuleFormOrder.orderType == 2||saveRuleFormOrder.orderType == 3) &&
                saveRuleFormOrder.payType == 2
            "
          >
            <el-radio-group
              v-model="saveRuleFormOrder.payMent"
              :disabled="orderDialog.type == 'see'"
            >
              <el-radio label="1">支付宝</el-radio>
              <el-radio label="2">微信</el-radio>
              <el-radio label="3">对公账户</el-radio>
              <el-radio label="4">POS机</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="收款类型" prop="moneyType" v-show="saveRuleFormOrder.orderType!=3">
            <el-radio-group
              v-model="saveRuleFormOrder.moneyType"
              :disabled="orderDialog.type == 'see'"
            >
              <el-radio label="1">全款</el-radio>
              <el-radio label="2">定金</el-radio>
              <el-radio label="3">尾款</el-radio>
              <el-radio label="4">特殊订单</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="付款凭证"
            prop="certificate"
            v-show="saveRuleFormOrder.payType == 2"
          >
            <el-row align="middle" type="flex">
              <el-col :span="12">
                <div class="certificate_box">
                   <el-image 
                    class="td-img"
                    style="width: 100px; height: 100px"
                    :src="saveRuleFormOrder.certificate" 
                    v-show="saveRuleFormOrder.certificate"
                      :preview-src-list="srcList"
                  >
                  </el-image>
                </div>
                <div class="certificate_tip">
                  <span v-show="saveRuleFormOrder.certificate"
                    >点击图片查看大图</span
                  >
                  <span v-show="!saveRuleFormOrder.certificate"
                    >暂未上传凭证</span
                  >
                </div>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  :action="fileUpload"
                  :on-success="certificateSuccess"
                  :on-error="certificateError"
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-button
                    size="small"
                    type="primary"
                    :disabled="orderDialog.type == 'see'"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="辅导协议"
            prop="coachAgreement"
           
          >
            <el-row align="middle" type="flex">
              <el-col :span="12">
                <div class="coachAgreement_tip">
                  <a target="view_window" :href="saveRuleFormOrder.coachAgreement" v-show="saveRuleFormOrder.coachAgreement">{{
                    saveRuleFormOrder.coachAgreementName
                  }}</a>
                  <span v-show="!saveRuleFormOrder.coachAgreement"
                    >还未上传辅导协议</span
                  >
                </div>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  :action="fileUpload"
                  :on-success="coachAgreementSuccess"
                  :on-error="coachAgreementError"
                  :show-file-list="false"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-button
                    size="small"
                    type="primary"
                    :disabled="orderDialog.type == 'see'"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-row align="middle" type="flex">
              <el-col :span="17">
                <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入内容"
              v-model="saveRuleFormOrder.remark"
            >
            </el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        v-show="orderDialog.type != 'see'"
      >
        <el-button @click="orderDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          :disabled="isDisable"
          @click="confirmSaveOrder('saveRuleFormOrder')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
  title="提示"
  :visible.sync="centerDialogVisible"
  width="30%"
  center>
  <span>{{centerData}}</span>
  <div slot="footer" class="dialog-footer" >
      <el-button type="primary" @click="centerDialogVisible = false">好，知道了</el-button>
    </div>
</el-dialog>
<el-dialog
      :title="studentResultDialog.title"
      :visible.sync="studentResultSync"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
         <el-form  
          :model="studentRuleForm"
          status-icon
          :rules="studentResultRules"
          ref="studentRuleForm"
          label-width="100px"
          size="small"
        >
           <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="考试时间" prop="examinationTime">
                 <el-date-picker
                  class="time_selector"
                  v-model="studentRuleForm.examinationTime"
                  type="date"
                  :picker-options="expireTimeOption"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="考试类型" prop="examinationType">
                 <el-select
                      v-model="studentRuleForm.examinationType"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in examinationTypeList"
                        :key="item.macroId"
                        :label="item.name"
                        :value="item.macroId"
                      ></el-option>
                    </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="12">
              <el-form-item label="成绩" prop="subjectScore">
                <el-input
                  v-model="studentRuleForm.subjectScore"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总分" prop="subjectAllScore" >
                <el-input
                  v-model="studentRuleForm.subjectAllScore"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="备注说明" prop="remarks">
                <el-input
                  v-model="studentRuleForm.remarks"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="试卷上传" prop="testPapers">
                <el-upload  
                :action="fileUpload" 
                :file-list="testPapers" 
                list-type="picture-card" 
                :on-preview="handlePictureCardPreview" 
                multiple
                :on-remove="handleRemove" 
                :limit="8" 
                accept="image/jpeg, image/png/, image/gif"
                :on-success="handleAvatarSuccess"
                ><i class="el-icon-plus"></i>
              </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="studentResultSync = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmStudent('studentRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
  <img width="100%" :src="dialogImageUrl" alt="">
</el-dialog>
<el-dialog
      title="试卷预览"
      :visible.sync="previewDialogVisible"
      :close-on-click-modal="false"
      width="650px"
      center
    >
      <div class="dialog-content" style="margin-top:30px;">
         <el-form  
          :model="previewRuleForm"
          status-icon
          :rules="previewResultRules"
          ref="previewRuleForm"
          label-width="100px"
          size="small"
        >
           <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="考试时间：" prop="examinationTime">
                {{previewRuleForm.examinationTime}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="考试类型：" prop="examinationType">
                {{examinationTypeFn(previewRuleForm.examinationType)}}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="12">
              <el-form-item label="成绩：" prop="subjectScore">
                {{previewRuleForm.subjectScore}}分
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总分：" prop="subjectAllScore">
                {{previewRuleForm.subjectAllScore}}分
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="备注说明：" prop="remarks">
                {{previewRuleForm.remarks}}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="试卷上传：" prop="testPapers">
                <el-image v-for="(ele,index) in previewImage" :key="index"
                      style="width: 100px; height: 100px;margin-right:10px;"
                      :src="ele"
                      :preview-src-list="getPreviewImgList(index)">
                </el-image>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      title="详情"
      :visible.sync="detailDialogVisible"
      width="800px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="detail_type">学生概况</div>
        <div>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>学生姓名：</span>
              <span>{{ studentDetail.name }}</span>
            </el-col>
            <el-col :span="8">
              <span>联系电话：</span>
              <span>{{
                studentDetail.contact ? studentDetail.contact : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>年级：</span>
              <span>{{
                studentDetail.sectionName
                  ? studentDetail.sectionName
                  : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>性别：</span>
              <span v-show="studentDetail.sex==1">男</span>
              <span v-show="studentDetail.sex==2">女</span>
            </el-col>
            <el-col :span="8">
              <span>地址：</span>
              <span>{{
                studentDetail.address ? studentDetail.address : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>学校：</span>
              <span>{{
                studentDetail.schoolName ? studentDetail.schoolName : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
        </div>
        <div class="detail_type">订单信息</div>
        <div v-show="infoOrderType!=5">
          <el-row :gutter="20" class="row_detail row_detail2" type="flex" align="bottom" v-show="orderDetail.totalPrice">
            <el-col :span="8">
              <span>总价：</span>
              <span>{{orderDetail.zdyActualPrice ? orderDetail.zdyActualPrice : orderDetail.totalPrice}}</span>
            </el-col>
            <el-col :span="8">
              <span>单价：</span>
              <span>{{orderDetail.zdySinglePrice ? orderDetail.zdySinglePrice : orderDetail.singlePrice}}</span>
            </el-col>
            <el-col :span="8">
              <span>课时数：</span>
              <span>{{orderDetail.zdyClassHour ? orderDetail.zdyClassHour : orderDetail.classHour}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail row_detail2" type="flex" align="bottom" v-show="orderDetail.zdyDiscountAmount!=null&&orderDetail.orderType==2">
            <el-col :span="8">
              <span>优惠金额：</span>
              <span>{{orderDetail.zdyDiscountAmount?orderDetail.zdyDiscountAmount:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>实际支付：</span>
              <span>{{orderDetail.zdyActualPrice?orderDetail.zdyActualPrice:0}}</span>
            </el-col>
          </el-row>
          <!-- 自定义课时 -->
           <el-row :gutter="20" class="row_detail row_detail2" type="flex" align="bottom" v-show="orderDetail.zdyClassHour">
            <el-col :span="8">
              <span>单价：</span>
              <span>{{orderDetail.zdySinglePrice?orderDetail.zdySinglePrice:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>课时数：</span>
              <span>{{orderDetail.zdyClassHour?orderDetail.zdyClassHour:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>总金额：</span>
              <span>{{orderDetail.zdyTotalPrice?orderDetail.zdyTotalPrice:0}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail row_detail2" type="flex" align="bottom" v-show="orderDetail.zdyClassHour">
            <el-col :span="8">
              <span>优惠金额：</span>
              <span>{{orderDetail.zdyDiscountAmount?orderDetail.zdyDiscountAmount:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>赠送课时：</span>
              <span>{{orderDetail.zdyGiveClassHour?orderDetail.zdyGiveClassHour:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>实际支付：</span>
              <span>{{orderDetail.zdyActualPrice?orderDetail.zdyActualPrice:0}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>收款方式：</span>
              <span>{{orderDetail.payMentName ? orderDetail.payMentName : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>收款类型：</span>
              <span>{{orderDetail.moneyTypeName ? orderDetail.moneyTypeName : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>产品类型：</span>
              <span>{{orderDetail.productTypeName ? orderDetail.productTypeName : '暂无数据'}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>产品名称：</span>
              <span>{{orderDetail.productName}}</span>
            </el-col>
            <el-col :span="8">
              <span v-if="orderDetail.productType==2||orderDetail.productType==5">学段：</span>
              <span v-else>年级：</span>
              <span>{{orderDetail.sectionName ? orderDetail.sectionName : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>教师职称：</span>
              <span>{{orderDetail.teacherTitleName ? orderDetail.teacherTitleName : '暂无数据'}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>订单编号：</span>
              <span>{{orderDetail.orderNo}}</span>
            </el-col>
            <el-col :span="8">
              <span>下单时间：</span>
              <span>{{orderDetail.createTime}}</span>
            </el-col>
            <el-col :span="8">
              <span>订单状态：</span>
              <span>{{orderDetail.orderStatusName}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>付款凭证：</span>
              <el-image 
                    class="td-img"
                    style="width: 100px; height: 100px;z-index:10000;"
                    :src="orderDetail.certificate" 
                    v-if="orderDetail.certificate"
                      :preview-src-list="srcList"
                  >
                  </el-image>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>辅导协议：</span>
              <span v-if="orderDetail.coachAgreementName"><a target="view_window" :href="orderDetail.coachAgreement">{{orderDetail.coachAgreementName}}</a> </span>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>归属部门：</span>
              <span>{{orderDetail.orgName ? orderDetail.orgName : orderDetail.teachManageOrgrName}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>订单提交人：</span>
              <span>{{orderDetail.orderSourceName ? orderDetail.orderSourceName+'（'+orderDetail.orderSourceRealName+'）' : orderDetail.teachManagerName+'（'+orderDetail.orderSourceRealName+'）'}}</span>
            </el-col>
            <el-col :span="8">
              <span>归属校区：</span>
              <span>{{orderDetail.schoolName ? orderDetail.schoolName : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>备注：</span>
              <span>{{orderDetail.remark ? orderDetail.remark : '暂无数据'}}</span>
            </el-col>
          </el-row>
        </div>
        <div v-show="infoOrderType==5">
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom" v-show="orderDetail.totalPrice">
            <el-col :span="8" >
              <span style="font-weight: bold;color:#ff3f3f;">缴费金额：</span>
              <span style="font-weight: bold;color:#ff3f3f;">{{orderDetail.totalPrice}}</span>
            </el-col>
            <el-col :span="8">
              <span style="font-weight: bold;color:#ff3f3f;">赠送课时：</span>
              <span style="font-weight: bold;color:#ff3f3f;">{{orderDetail.zdyGiveClassHour}}</span>
            </el-col>
            <el-col :span="8">
              <span>支付方式：</span>
              <span>{{orderDetail.payMentName}}（{{orderDetail.moneyTypeName}}）</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>付款凭证：</span>
              <el-image
                    class="td-img"
                    style="width: 100px; height: 100px;z-index:10000;"
                    :src="orderDetail.certificate"
                    v-if="orderDetail.certificate"
                      :preview-src-list="srcList"
                  >
                  </el-image>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>辅导协议：</span>
              <span v-if="orderDetail.coachAgreementName"><a target="view_window" :href="orderDetail.coachAgreement">{{orderDetail.coachAgreementName}}</a> </span>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>归属部门：</span>
              <span>{{orderDetail.orgName ? orderDetail.orgName : orderDetail.teachManageOrgrName}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>订单状态：</span>
              <span>{{orderDetail.orderStatusName}}</span>
            </el-col>
            <el-col :span="8">
              <span>订单提交人：</span>
              <span>{{orderDetail.orderSourceName ? orderDetail.orderSourceName+'（'+orderDetail.orderSourceRealName+'）' : orderDetail.teachManagerName+'（'+orderDetail.orderSourceRealName+'）'}}</span>
            </el-col>
            <el-col :span="8">
              <span>归属校区：</span>
              <span>{{orderDetail.schoolName ? orderDetail.schoolName : '暂无数据'}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>备注：</span>
              <span>{{orderDetail.remark ? orderDetail.remark : '暂无数据'}}</span>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailDialogVisible=false" type="primary" size="small"
          >返 回</el-button
        >
      </span>
    </el-dialog>
    <div class="imgDolg" v-show="imgPreview.show" @click.stop="imgPreview.show = false">
      <i class="el-icon-close" id="imgDolgClose" @click.stop="imgPreview.show = false"></i>
      <img @click.stop="imgPreview.show = true" :src="imgPreview.img" />
    </div>
    <orderInfo ref="orderInfo" :orderInfoStatus="orderInfoStatus"  :orderInfoParams="orderInfoParams" @CB_orderInfoShow="CB_orderInfoShow"></orderInfo>
    <downOrder ref="downOrder" :downOrderStatus="downOrderStatus" :downParams="downParams" @CB_downOrderStatus="CB_downOrderStatus"></downOrder>
  </div>
</template>

<script>
import ClipboardJS from "clipboard";
import headPng from "@/assets/image/head.png";
import orderInfo from '../../finance/dialog/orderInfo.vue';
import {
  productListNoPage,
  productInfoDetail
} from "@/api/operates/productManage";
import {
  studentUpdate,
  studentInfo,
  infoByParentCodeAndValue,
  saleBack,
  academicrecordSave,//学生添加试卷信息接口
  getObjectById,//学生添加试卷信息接口
  academicrecordRemove,//学生删除试卷接口
  academicrecordUpdate,//学生编辑试卷接口
} from "@/api/sales/saleManage";
import {
  followupSave,
  followUpInfo,
  followUpUpdate,
  followUpListPage,
  followUpList,
  prevAndNext,
  addOrder,
  orderDetail,
  orderList,
  orderRemove,
  checkOneToOneOrder,
  logicalDeletion,

} from "@/api/sales/myCustomer";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  fileUpload,
  reg_Num
} from "@/api/public";
import { systemAreaList } from "@/api/system/systemManage";
import {
  fileUploads,
  removeByStuId,
  oneToOneList,//学员详情里的一对一订单列表
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import dayjs from "dayjs";
import dialogFileViewPrivate from "../../teachBusiness/expertTeachBusiness/chat/dialog-file-view-private.vue";
import downOrder from '../../teachBusiness/expertTeachBusiness/dialog/studentManageFile/downOrder.vue'
export default {
  components: {
    dialogFileViewPrivate,
    downOrder,
    orderInfo
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      // 当数据为空时，不进行校验
      if (!value) {
        return callback()
      }
     const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/
       setTimeout(() => {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号码'))
        }
      }, 100)
    }
    let check_referralphone = (rule, value, callback) => {
      if (this.saveRuleForm.sourceType == '5') {
        if (!value) {
        return callback()
      }
     const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/
       setTimeout(() => {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号码'))
        }
      }, 100)
      } else {
        callback();
      }
    };
    let check_zdyClassHour = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback(new Error("自定义课时数不能为空！"));
        } else if (!reg_Num(2).test(value)) {
          callback(new Error("自定义课时数应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdySinglePrice = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback(new Error("自定义课时单价不能为空！"));
        } else if (!reg_Num(2).test(value)) {
          callback(new Error("自定义课时单价应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdyGiveClassHour = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback();
        } else if (!reg_Num(2).test(value)) {
          callback(new Error("赠送课时数应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdyDiscountAmount = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback();
        } else if (!reg_Num(4).test(value)) {
          callback(new Error("优惠金额应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdyActualPrice = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback(new Error("课时总价不能为空！"));
        } else if (!reg_Num(4).test(value)) {
          callback(new Error("课时总价应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_certificate = (rule, value, callback) => {
      if (this.saveRuleFormOrder.payType == 2) {
        if (!value) {
          callback(new Error("线下支付时付款凭证不能为空！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_productId = (rule, value, callback) => {
      if (this.saveRuleFormOrder.orderType != 5) {
        if (!value) {
          callback(new Error("产品不能为空！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_totalPrice = (rule, value, callback) => {
      if (this.saveRuleFormOrder.orderType == 5&&this.saveRuleFormOrder.moneyType!=4) {
        var reg = /^\d+$|^\d+[.]?\d+$/;
        if (!value) {
          callback(new Error("缴费金额不能为空！"));
        }else if(!reg.test(value)){
          callback(new Error("请输入正确格式金额！"));
        }else {
          callback();
        }
      }else if(this.saveRuleFormOrder.orderType == 5&&this.saveRuleFormOrder.moneyType==4){
          var regF=/^(^-?\d+$)$/
          if (!value) {
            callback(new Error("缴费金额不能为空！"));
          }else if(!regF.test(value)){
            callback(new Error("请输入正确格式金额！"));
          }else {
            callback();
          }
        }
        else {
          callback();
        }
    };
    return {
      copyStr: '', // 
      orderInfoStatus:false,
      orderInfoParams:{
        id:"",
        studentId:""
      },
      downOrderStatus:false,
      downParams:{
        id:""
      },
      translateTotal:'',
      rechargeData:[],
      photoList:[],
      updateStudentFlag:'',
      imgPreview:{
        img:"",
        show:false
      },
      editId:"",
      editOrderId:"",
      getStatus:'',
      loading: false,
      fileUpload,
      headPng,
      id: "",
      saleId: "",
      prevId: "",
      nextId: "",
      contract: "", //签约状态null：全部 0：未注册  1：注册已试听  2：注册已签约 4：跟进中
      isShow: false,
      saleData: {
        saleId: "",
        saleName: "",
        record: true
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs()
                  .subtract(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(7, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(30, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          { 
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(90, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      followDisabled:false,
      subjectTabs:'文化课',
      firstClassField:"",
      formalClassField:"",
      examinationTypeList:[
        {
          macroId:"1",
          name:"模拟考试"
        },
        {
          macroId:"2",
          name:"期中"
        },
        {
          macroId:"3",
          name:"期末"
        },
        {
          macroId:"4",
          name:"月考"
        },
        {
          macroId:"5",
          name:"随堂练"
        },
      ],
      centerDialogVisible:false,
      orderDetail:[],
      orderSrc:[],
      srcList:[],
      studentDetail:{},
      centerData:"",
      timePickerValue: "",
      studentList: [],
      listParams: {
        isRelease: 0,
        beginDate: null,
        endDate: null,
        pageNumber: 1,
        pageSize: 10,
        sectionId: "",
        keyword: "",
        sourceType: ""
      },
      detailDialogVisible:false,
      infoOrderType:"",
      sourceList: [
        {
          value: "1",
          label: "公共池"
        },
        {
          value: "2",
          label: "自主登录"
        },
        {
          value: "3",
          label: "销售添加"
        },
        {
          value: "4",
          label: "教务添加"
        }
      ],
       sourceTypeList2:[
         {
          value: "1",
          label: "地推活动"
        },
        {
          value: "2",
          label: "网络活动"
        },
         {
          value: "3",
          label: "美团活动"
        },
        {
          value: "4",
          label: "A级客户"
        },
        {
          value: "5",
          label: "家长转介绍"
        },
      ],
      sourceTypeList:[
        {
          value: "",
          label: "全部"
        },
        {
          value: "1",
          label: "地推活动"
        },
        {
          value: "2",
          label: "线上活动"
        },
         {
          value: "3",
          label: "家长转介绍"
        },
      ],
      orderDParams:{},
      orderDStatus:false,
      constellationList: [],
      tagsTotal: 0,
      total: 0,
      saveDialogVisible: false,
      statusShow:"",
      saveRuleForm: {
        name: "",
        contact: "",
        sex: "1",
        sectionId: "",
        birthday: "",
        provinceId: "",
        cityId: "",
        countyId: "",
        schoolName: "",
        sourceType: "",
        areaId: "",
        constellationId: "",
        childNature: "",
        childMotherName: "",
        childMotherPhone: "",
        childFatherName: "",
        childFatherPhone: "",
        parentWechat: "",
        firstClassTime: "",
        firstClassTimeRemark: "",
        formalClassTime: "",
        formalClassTimeRemark: "",
        yuwen: 0,
        shuxue: 0,
        yingyu: 0,
        lishi: 0,
        zhengzhi: 0,
        dili: 0,
        wuli: 0,
        huaxue: 0,
        shengwu: 0,
        qitaxueke:0,
        xmtStudentSubjectRelationEntityList: []
      },
      allRechargeMoney:0,
      isDisable:false,
      saveRuleFormFollowUp: {
        studentId: "",
        saleId: "",
        followupTags: "",
        nextFollowupTime: "",
        followContent: ""
      },
      //当选择自定义课时时，销售人员可以自己设置课时、单价、赠送课时、优惠金额等；注：课时X单价-优惠金额=总价
      saveRuleFormOrder: {
        orderType: "5", //1一对一  2一对多  3试听课
        payType: "2", //1线上支付   2线下支付
        courseTypeId: "1", //1大班课  2小班课  3答疑课
        productId: "",
        certificate: "", //付款凭证
        coachAgreement: "", //辅导协议
        sectionId: "", //学段
        subjectId: "",
        teacherTitleId: "",
        classHour: "",
        classHourId: "", //不用传到后台
        singlePrice: "",
        totalPrice: "",
        zdyClassHour: "",
        zdySinglePrice: "",
        zdyGiveClassHour: 0,
        zdyDiscountAmount: "",
        zdyActualPrice: "",
        studentId: this.id,
        productPackageId: "",
        remark: "",
        payMent: "1", //1支付宝  2微信  3对公账户  4pos机
        moneyType: "1" //1全款   2定金  3尾款
      },
      curEditOrder: "",
      productList: [],


      firstClassTimeStatus:[
        {name:'周一上午',key:'a'},
        {name:'周一下午',key:'h'},
        {name:'周一晚上',key:'o'},
        {name:'周二上午',key:'b'},
        {name:'周二下午',key:'i'},
        {name:'周二晚上',key:'p'},
        {name:'周三上午',key:'c'},
        {name:'周三下午',key:'j'},
        {name:'周三晚上',key:'q'},
        {name:'周四上午',key:'d'},
        {name:'周四下午',key:'k'},
        {name:'周四晚上',key:'r'},
        {name:'周五上午',key:'e'},
        {name:'周五下午',key:'l'},
        {name:'周五晚上',key:'s'},
        {name:'周六上午',key:'f'},
        {name:'周六下午',key:'m'},
        {name:'周六晚上',key:'t'},
        {name:'周日上午',key:'g'},
        {name:'周日下午',key:'n'},
        {name:'周日晚上',key:'u'},
      ],
      firstClassTimeList: [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ],
      formalClassTimeList: [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ],
      firstClassTimeList_info: [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ],
      formalClassTimeList_info: [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ],
      saveRules: {
        referralphone:[{
          validator: check_referralphone, trigger: "blur" 
        }],
        name: [
          { required: true, message: "学生姓名不能为空", trigger: "blur" }
        ],
         childMotherPhone : [
          {
             validator: checkPhone, message: '请输入正确的手机号码'
          }
        ],
        childFatherPhone : [
          {
             validator: checkPhone, message: '请输入正确的手机号码'
          }
        ]
      },
      saveRulesFollowUp: {
        nextFollowupTime: [
          { required: true, message: "下次跟进时间不能为空", trigger: "blur" }
        ],
        followContent: [
          { required: true, message: "跟进记录不能为空", trigger: "blur" }
        ]
      },
      saveRuleOrder: {
        zdyClassHour: [{ validator: check_zdyClassHour, trigger: "blur" }],
        zdySinglePrice: [{ validator: check_zdySinglePrice, trigger: "blur" }],
        zdyGiveClassHour: [
          { validator: check_zdyGiveClassHour, trigger: "blur" }
        ],
        zdyDiscountAmount: [
          { validator: check_zdyDiscountAmount, trigger: "blur" }
        ],
        zdyActualPrice: [{ validator: check_zdyActualPrice, trigger: "blur" }],
        certificate: [{ validator: check_certificate, trigger: "change" }],
        productId: [{ validator: check_productId, trigger: "change" }],
        totalPrice: [{ validator: check_totalPrice, trigger: "blur" }]
      },
      provinceList: [],
      cityList: [],
      countyList: [],
      selectStudentList: [],
      selectStudentListObj: [],
      apportionDialog: {
        title: "分配",
        type: "1"
      },
      sectionList: [],
      diyColumnVisible: false,
      saleList: [],
      saleParams: {
        roleSign: "sales",
        status: 1
      },
      dialogFileViewPrivateInfo: {
        show: false,
        type: "img",
        src: "",
      },
      saveApplyVisible: false,
      selectFile: [],
      parentId: "0",
      areaId: "",
      subjectList: [],
      subjectList_info: [],
      teachSpeedList: [],
      teachYearList: [],
      teachStyleList: [],
      teachSexList: [
        // {
        //   value: 0,
        //   label: "均可"
        // },
        {
          value: 1,
          label: "男老师"
        },
        {
          value: 2,
          label: "女老师"
        },
        {
          value: 0,
          label: "均可"
        }
      ],
      curOptStudent: {},
      isOpen: false,
      saveApplyData: {
        allNum: 0,
        saveNum: ""
      },
      studentInfo: {},
      classTimeTyp: 1,
      intentionList: [
        {
          value: "1",
          label: "A意向较强"
        },
        {
          value: "2",
          label: "B意向简单"
        },
        {
          value: "3",
          label: "C了解阶段"
        },
        {
          value: "4",
          label: "D无意向"
        }
      ],
      followUpDialog: {
        title: "新增跟进记录",
        type: "save"
      },
      orderDialog: {
        title: "下单",
        type: "save"
      },
      detailDialogVisibl:false,
      followUpDialogVisible: false,
      content_height: "160",
      followTagList: [],
      followUpList: [],
      orderDialogVisible: false,
      productDetail: {
        productPackage: [],
        sectionList: [],
        sectionIdList: [],
        subjectList: [],
        subjectIdList: [],
        teacherTitleList: [],
        teacherTitleIdList: [],
        classHourList: [],
        classHourIdList: []
      },
      orderList: [],
      expireTimeOption: {
        disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() > Date.now();
        }
      },
      expireTimeOptionBefore:{
         disabledDate (date) {
            return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
         }
      },
      studentResultSync:false,
      studentResultDialog:{
        title:"添加试卷信息",
        type:"add"
      },
     studentResultRules:{
        examinationTime: [
          { required: true, message: "考试时间不能为空", trigger: "blur" },
        ],
        subjectScore: [
           { required: true,
            validator: (rule, value, callback) => {
              let regNum = /^[0-9]+$/
              if (value === '') {
                callback(new Error('成绩不能为空不能为空'))
              } else if (!regNum.test(value)) {
                callback(new Error('仅限输入数字格式'))
              } else {
                callback()
              }
            },
            trigger: 'blur' }
        ],
        subjectAllScore: [
          { required: true,
            validator: (rule, value, callback) => {
              let regNum = /^[0-9]+$/
              if (value === '') {
                callback(new Error('总分不能为空不能为空'))
              } else if (!regNum.test(value)) {
                callback(new Error('仅限输入数字格式'))
              } else {
                callback()
              }
            },
            trigger: 'blur' }
        ],
       
      },
     
      studentRuleForm:{
        examinationType:"",//考试类型,
        subjectScore:"",//成绩
        subjectAllScore:0,//总分
        examinationTime:"",//考试时间
        remarks:"",//备注说明
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      testPapers:[],//存放试卷图片
      getMacroId:"",
      getSubjectId:"",
      previewDialogVisible:false,
      editStudentDialog:false,
      previewImage:[],
      paperId:'',
      previewResultRules:{},
      previewRuleForm:{

      },
    };
  },
  methods: {
    CB_orderDStatus(){
      this.orderDStatus
    },
    getSectionList() {
      return getSelectList({
        parentCode: sectionCode
      }).then(res => {
        if (res.code == 0) {
          this.sectionList = res.rows;
        }
      });
    },
    getFollowTagList() {
      return getSelectList({
        parentCode: followTags
      }).then(res => {
        if (res.code == 0) {
          for (let i = 0, len = res.rows.length; i < len; i++) {
            res.rows[i].select = false;
          }
          this.followTagList = res.rows;
        }
      });
    },
    getSubjectList() {
      return infoByParentCodeAndValue({
        parentCode: subjectCode
      }).then(res => {
        if (res.code == 0) {
          for (let i = 0, len = res.rows.length; i < len; i++) {
            res.rows[i].status = false;
            res.rows[i].select = false;
            res.rows[i].subjectScore = "";
            res.rows[i].subjectAllScore = "";
            res.rows[i].sexNeedId = "";
            res.rows[i].styleNeedId = "";
            res.rows[i].speedNeedId = "";
            res.rows[i].ageNeedId = "";
            res.rows[i].content = "";
            res.rows[i].xmtStudentAcademicRecordList =[]
          }
          this.subjectList = res.rows;
          this.subjectList_info = JSON.parse(JSON.stringify(res.rows));
          console.log(this.subjectList_info,'subjectList_info///')
        }
      });
    },
    CB_downOrderStatus(){
      this.downOrderStatus=false;
      this.getOrderList();
      this.oneToOneListFn();
    },
    closeFileView() {
      this.dialogFileViewPrivateInfo.show = false;
    },
    getTeachSpeedList() {
      return getSelectList({
        parentCode: teachSpeed
      }).then(res => {
        if (res.code == 0) {
          this.teachSpeedList = res.rows;
          this.teachSpeedList.unshift({
            macroId: 0,
            name: "均可",
          });
        }
      });
    },
    getTeacYearList() {
      return getSelectList({
        parentCode: teachYear
      }).then(res => {
        if (res.code == 0) {
          this.teachYearList = res.rows;
          this.teachYearList.unshift({
            macroId: 0,
            name: "均可",
          });
        }
      });
    },
    getConstellationList() {
      return getSelectList({
        parentCode: constellation
      }).then(res => {
        if (res.code == 0) {
          this.constellationList = res.rows;
        }
      });
    },
    getTeachStyleList() {
      return getSelectList({
        parentCode: teachStyle
      }).then(res => {
        if (res.code == 0) {
          this.teachStyleList = res.rows;
          this.teachStyleList.unshift({
            macroId: 0,
            name: "均可",
          });
         
        }
      });
    },
    getProvinceList() {
      return systemAreaList({
        parentId: this.parentId
      }).then(res => {
        if (res.code == 0) {
          this.provinceList = res.rows;
        }
      });
    },
    getCityList() {
      return systemAreaList({
        parentId: this.saveDialogVisible
          ? this.saveRuleForm.provinceId
          : this.studentInfo.provinceId
      }).then(res => {
        if (res.code == 0) {
          this.cityList = res.rows;
        }
      });
    },
    getCountyList() {
      return systemAreaList({
        parentId: this.saveDialogVisible
          ? this.saveRuleForm.cityId
          : this.studentInfo.cityId
      }).then(res => {
        if (res.code == 0) {
          this.countyList = res.rows;
        }
      });
    },
    async provinceChange() {
      await this.getCityList();
      this.saveRuleForm.cityId = this.cityList[0].areaId;
      this.cityChange();
    },
    async cityChange() {
      await this.getCountyList();
      this.saveRuleForm.countyId = this.countyList[0].areaId;
    },
    initClassTime() {
      this.formalClassTimeList = [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ];
      this.firstClassTimeList = [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ];
    },
    initClassTime_info() {
      this.formalClassTimeList_info = [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ];
      this.firstClassTimeList_info = [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ]
    },
    async daalEdit() {
      if(this.updateStudentFlag=='N'){
        this.msgWarn("该生已下单，不可再编辑！");
        return;
      }
      this.saveDialogVisible = true;
      this.subjectTabs = "文化课";
      await this.getProvinceList();
      for (var i = 0; i < this.subjectList.length; i++) {
        this.subjectList[i].xmtStudentAcademicRecordList = [];
      }
      await studentInfo(this.id).then(res => {
        if (res.code == 0) {
          this.saveRuleForm = {
            studentsOtherNeeds:res.rows.studentsOtherNeeds,
            name: res.rows.name,
            contact: res.rows.contact,
            sex: String(res.rows.sex),
            sectionId: res.rows.sectionId,
            birthday: res.rows.birthday,
            provinceId: res.rows.provinceId,
            cityId: res.rows.cityId,
            countyId: res.rows.countyId,
            schoolName: res.rows.schoolName,
            sourceType: res.rows.sourceType==0?'':String(res.rows.sourceType),
            areaId: this.saveRuleForm.areaId,
            constellationId: res.rows.constellationId,
            childNature: res.rows.childNature,
            childMotherName: res.rows.childMotherName,
            childMotherPhone: res.rows.childMotherPhone,
            childFatherName: res.rows.childFatherName,
            childFatherPhone: res.rows.childFatherPhone,
            referralphone:res.rows.referralphone,
            referralstudent:res.rows.referralstudent,
            parentWechat: res.rows.parentWechat,
            firstClassTime: res.rows.firstClassTime,
            firstClassTimeRemark: res.rows.firstClassTimeRemark,
            formalClassTime: res.rows.formalClassTime,
            formalClassTimeRemark: res.rows.formalClassTimeRemark,
            qitaxueke:res.rows.qitaxueke,
            yuwen: res.rows.yuwen,
            shuxue: res.rows.shuxue,
            yingyu: res.rows.yingyu,
            lishi: res.rows.lishi,
            zhengzhi: res.rows.zhengzhi,
            dili: res.rows.dili,
            wuli: res.rows.wuli,
            huaxue: res.rows.huaxue,
            shengwu: res.rows.shengwu,
            xmtStudentSubjectRelationEntityList: [],
            id: res.rows.id,
            schoolReach:res.rows.schoolReach,
            contract:res.rows.contract
          };
          if (this.saveRuleForm.provinceId) {
            this.getCityList();
          }
          if (this.saveRuleForm.cityId) {
            this.getCountyList();
          }
          this.initClassTime();
          for (let i = 0, len = this.firstClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.firstClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (
                res.rows.firstClassTime &&
                res.rows.firstClassTime.includes(
                  this.firstClassTimeList[i][j].key
                )
              ) {
                this.firstClassTimeList[i][j].status = true;
              }
            }
          }
          for (let i = 0, len = this.formalClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.formalClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (
                res.rows.formalClassTime &&
                res.rows.formalClassTime.includes(
                  this.formalClassTimeList[i][j].key
                )
              ) {
                this.formalClassTimeList[i][j].status = true;
              }
            }
          }
          for (let i = 0, len = this.subjectList.length; i < len; i++) {
            this.subjectList[i].status = false;
            this.subjectList[i].select = false;
            this.subjectList[i].subjectScore = "";
            this.subjectList[i].subjectAllScore = "";
            this.subjectList[i].sexNeedId = "";
            this.subjectList[i].styleNeedId = "";
            this.subjectList[i].speedNeedId = "";
            this.subjectList[i].ageNeedId = "";
            this.subjectList[i].content = "";
            if (
              (this.subjectList[i].name == "其他学科" && res.rows.qitaxueke == 1) ||
              (this.subjectList[i].name == "语文" && res.rows.yuwen == 1) ||
              (this.subjectList[i].name == "数学" && res.rows.shuxue == 1) ||
              (this.subjectList[i].name == "英语" && res.rows.yingyu == 1) ||
              (this.subjectList[i].name == "历史" && res.rows.lishi == 1) ||
              (this.subjectList[i].name == "政治" && res.rows.zhengzhi == 1) ||
              (this.subjectList[i].name == "地理" && res.rows.dili == 1) ||
              (this.subjectList[i].name == "物理" && res.rows.wuli == 1) ||
              (this.subjectList[i].name == "化学" && res.rows.huaxue == 1) ||
              (this.subjectList[i].name == "生物" && res.rows.shengwu == 1)
            ) {
              this.subjectList[i].select = true;
            }
          }
          for (let i = 0, len = this.subjectList.length; i < len; i++) {
            for (
              let j = 0,
                lenJ = res.rows.xmtStudentSubjectRelationEntityList.length;
              j < lenJ;
              j++
            ) {
              if (
                this.subjectList[i].macroId ==
                res.rows.xmtStudentSubjectRelationEntityList[j].subjectId
              ) {
                this.subjectList[i].xmtStudentAcademicRecordList=res.rows.xmtStudentSubjectRelationEntityList[j].xmtStudentAcademicRecordList;
                this.subjectList[i].status = true;
                this.subjectList[i].subjectScore =
                  res.rows.xmtStudentSubjectRelationEntityList[j].subjectScore;
                this.subjectList[i].subjectAllScore =
                  res.rows.xmtStudentSubjectRelationEntityList[
                    j
                  ].subjectAllScore;
                this.subjectList[i].sexNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].sexNeedId;
                this.subjectList[i].styleNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].styleNeedId;
                this.subjectList[i].speedNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].speedNeedId;
                this.subjectList[i].ageNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].ageNeedId;
                this.subjectList[i].content =
                  res.rows.xmtStudentSubjectRelationEntityList[j].content;
                   this.subjectList[i].id =
                  res.rows.xmtStudentSubjectRelationEntityList[j].id;
              }
            }
          }
        }
      });
    },
     morenAmount(){
      this.saveRuleFormOrder.zdyActualPrice=Number(this.saveRuleFormOrder.zdyTotalPrice)-Number(this.saveRuleFormOrder.zdyDiscountAmount);
    },
    totalPriceBlur(){
      var totalPriceNum=this.saveRuleFormOrder.totalPrice;
      this.numberToString(totalPriceNum)
    },
    removeSubject(index,id){
      if(id){
        this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeByStuId({studentId:this.saveRuleForm.id,subjectId:id}).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.subjectList[index].status=false;
              this.subjectList[index].speedNeedId="";
              this.subjectList[index].sexNeedId="";
              this.subjectList[index].ageNeedId="";
              this.subjectList[index].styleNeedId="";
              this.subjectList[index].xmtStudentAcademicRecordList=[];
            }
          });
        })
        .catch(() => {});
      }else{
        this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.msgSuccess("操作成功！");
          this.subjectList[index].status=false;
          this.subjectList[index].speedNeedId="";
          this.subjectList[index].sexNeedId="";
          this.subjectList[index].ageNeedId="";
          this.subjectList[index].styleNeedId="";
          this.subjectList[index].xmtStudentAcademicRecordList=[];
        })
        .catch(() => {});
      }
    },
    deleteSubject(item) {
      for (var i = 0; i < this.subjectList.length; i++) {
        console.log(this.subjectList[i]);
        if (item.name == this.subjectList[i].name) {
          this.subjectList[i].status = false;
        }
      }
    },
    CB_orderInfoShow(){
      this.orderInfoStatus=false;
    },
    confirmSave(formName,num) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let arr = [],
            firstClassTimeListArr = [],
            formalClassTimeListArr = [];
          this.saveRuleForm.yuwen = 0;
          this.saveRuleForm.shuxue = 0;
          this.saveRuleForm.yingyu = 0;
          this.saveRuleForm.lishi = 0;
          this.saveRuleForm.zhengzhi = 0;
          this.saveRuleForm.dili = 0;
          this.saveRuleForm.wuli = 0;
          this.saveRuleForm.huaxue = 0;
          this.saveRuleForm.shengwu = 0;
          this.saveRuleForm.qitaxueke = 0;
          for (let i = 0, len = this.subjectList.length; i < len; i++) {
            if (this.subjectList[i].select) {
              switch (this.subjectList[i].name) {
                case "语文":
                  this.saveRuleForm.yuwen = 1;
                  break;
                case "数学":
                  this.saveRuleForm.shuxue = 1;
                  break;
                case "英语":
                  this.saveRuleForm.yingyu = 1;
                  break;
                case "历史":
                  this.saveRuleForm.lishi = 1;
                  break;
                case "政治":
                  this.saveRuleForm.zhengzhi = 1;
                  break;
                case "地理":
                  this.saveRuleForm.dili = 1;
                  break;
                case "物理":
                  this.saveRuleForm.wuli = 1;
                  break;
                case "化学":
                  this.saveRuleForm.huaxue = 1;
                  break;
                case "生物":
                  this.saveRuleForm.shengwu = 1;
                  break;
                 case "其他学科":
                  this.saveRuleForm.qitaxueke = 1;
                  break;
              }
            }
            if (this.subjectList[i].status) {
              if(this.subjectList[i].id!=undefined||this.subjectList[i].id!=''){//传Id
                   arr.push({
                      studentId: this.saveRuleForm.id,
                      subjectId: this.subjectList[i].macroId,
                      subjectScore: this.subjectList[i].subjectScore,
                      subjectAllScore: this.subjectList[i].subjectAllScore,
                      sexNeedId: this.subjectList[i].sexNeedId,
                      styleNeedId: this.subjectList[i].styleNeedId,
                      speedNeedId: this.subjectList[i].speedNeedId,
                      ageNeedId: this.subjectList[i].ageNeedId,
                      content: this.subjectList[i].content,
                      id:this.subjectList[i].id,
                      xmtStudentAcademicRecordList:this.subjectList[i].xmtStudentAcademicRecordList
                    });
              }else{
                  arr.push({
                    studentId: this.saveRuleForm.id,
                    subjectId: this.subjectList[i].macroId,
                    subjectScore: this.subjectList[i].subjectScore,
                    subjectAllScore: this.subjectList[i].subjectAllScore,
                    sexNeedId: this.subjectList[i].sexNeedId,
                    styleNeedId: this.subjectList[i].styleNeedId,
                    speedNeedId: this.subjectList[i].speedNeedId,
                    ageNeedId: this.subjectList[i].ageNeedId,
                    content: this.subjectList[i].content,
                    xmtStudentAcademicRecordList:this.subjectList[i].xmtStudentAcademicRecordList
                  });
              }
            }
          }
          for (let i = 0, len = this.firstClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.firstClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (this.firstClassTimeList[i][j].status) {
                firstClassTimeListArr.push(this.firstClassTimeList[i][j].key);
              }
            }
          }
          for (let i = 0, len = this.formalClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.formalClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (this.formalClassTimeList[i][j].status) {
                formalClassTimeListArr.push(this.formalClassTimeList[i][j].key);
              }
            }
          }
          this.saveRuleForm.firstClassTime = firstClassTimeListArr.join(",");
          this.saveRuleForm.formalClassTime = formalClassTimeListArr.join(",");
          this.saveRuleForm.xmtStudentSubjectRelationEntityList = arr;
          studentUpdate(this.saveRuleForm).then(res => {
            if (res.code == 0) {
              if(num!=2){
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                 this.getStudenInfo();
              }else{
                this.getStudenInfo();
              }
            }
          });
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    cancelFollowUp(){
      this.followUpDialogVisible = false;
      this.photoList=[];
    },
    confirmSaveFollowUp(formName) {
      let tags = [];
      for (let i = 0, len = this.followTagList.length; i < len; i++) {
        if (this.followTagList[i].select) {
          tags.push(this.followTagList[i].macroId);
        }
      }
      tags = tags.join(",");
      if (this.saveRuleFormFollowUp.followContent.trim() == "" && !tags) {
        this.msgWarn("跟进记录和跟进标签至少要填写一项！");
        return;
      }
      this.followDisabled = true;
      // this.saveRuleFormFollowUp.followupTags = 
      // this.saveRuleFormFollowUp.studentId = this.id;
      // this.saveRuleFormFollowUp.saleId = this.saleId;
      let photo=[];
      this.photoList.forEach((item) => {
        photo.push({
          url: item.url
        });
      });
      if (this.followUpDialog.type == "save") {
        followupSave({
          followupTags:tags == "" ? null : tags,
          studentId:this.id,
          saleId:this.saleId,
          followContent:this.saveRuleFormFollowUp.followContent,
          nextFollowupTime:this.saveRuleFormFollowUp.nextFollowupTime,
          photo
        }).then(res => {
          if (res.code == 0) {
            this.followUpDialogVisible = false;
             setTimeout(() => {
              this.followDisabled = false
              }, 1000)
            this.msgSuccess("新增跟进记录成功！");
            this.getFollowUpList();
          }
        });
      } else {
        followUpUpdate({
          followupTags:tags == "" ? null : tags,
          studentId:this.id,
          saleId:this.saleId,
          followContent:this.saveRuleFormFollowUp.followContent,
          nextFollowupTime:this.saveRuleFormFollowUp.nextFollowupTime,
          photo,
          id:this.saveRuleFormFollowUp.id
        }).then(res => {
          if (res.code == 0) {
            this.followUpDialogVisible = false;
            setTimeout(() => {
              this.followDisabled = false
              }, 1000)
            this.msgSuccess("修改跟进记录成功！");
            this.getFollowUpList();
          }
        });
      }
    },
    birthdayChange(){
      let birthdayArray=this.saveRuleForm.birthday.split('-');
      let month =Number(birthdayArray[1]);//月
      let date =Number(birthdayArray[2]);//日
      console.log(birthdayArray)
      if(month==1 &&date>=20||month==2 &&date<=18){
          this.saveRuleForm.constellationId=256;
      }
      if (month == 2 && date >=19 || month == 3 && date <=20) {
          this.saveRuleForm.constellationId=257;
      }
      if (month == 3 && date >=21 || month == 4 && date <=19){
          this.saveRuleForm.constellationId=246;
      }
      if (month == 4 && date >=20 || month == 5 && date <=20){
        this.saveRuleForm.constellationId=247;
      }
      if (month == 5 && date >=21 || month == 6 && date <=21){
        this.saveRuleForm.constellationId=248;
      }
      if (month == 6 && date >=22 || month == 7 && date <=22){
        this.saveRuleForm.constellationId=249;
      }
      if (month == 7 && date >=23 || month == 8 && date <=22){
        this.saveRuleForm.constellationId=250;
      }
      if (month == 8 && date >=23 || month == 9 && date <=22){
        this.saveRuleForm.constellationId=251;
      }
      if (month == 9 && date >=23 || month == 10 && date <=22){
        this.saveRuleForm.constellationId=252;
      }
      if (month == 10 && date >=23 || month == 11 && date <=21){
        this.saveRuleForm.constellationId=253;
      }
      if (month == 11 && date >=22 || month == 12 && date <=21){
         this.saveRuleForm.constellationId=254;
      }
      if (month == 12 && date >=22 || month == 1 && date <=19){
        this.saveRuleForm.constellationId=255;
      }
    },
    confirmSaveOrder(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
           this.isDisable = true;
          let obj = {};
          if (this.saveRuleFormOrder.orderType ==3) {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              payType: this.saveRuleFormOrder.payType,
              productId: this.saveRuleFormOrder.productId,
              sectionId: this.saveRuleFormOrder.sectionId,
              teacherTitleId: this.saveRuleFormOrder.teacherTitleId,
              studentId: this.id,
              coachAgreement: this.saveRuleFormOrder.coachAgreement,
              coachAgreementName:this.saveRuleFormOrder.coachAgreementName,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent
            };
            if (this.saveRuleFormOrder.classHourId == -1) {
              obj.zdyClassHour = this.saveRuleFormOrder.zdyClassHour;
              obj.zdySinglePrice = this.saveRuleFormOrder.zdySinglePrice;
              obj.zdyGiveClassHour = this.saveRuleFormOrder.zdyGiveClassHour;
              obj.zdyDiscountAmount = this.saveRuleFormOrder.zdyDiscountAmount;
              obj.zdyActualPrice = this.saveRuleFormOrder.zdyActualPrice;
              obj.zdyTotalPrice = this.saveRuleFormOrder.zdyTotalPrice;
              obj.classHour = "";
              obj.singlePrice = "";
              obj.totalPrice = "";
            } else {
              obj.zdyClassHour = "";
              obj.zdySinglePrice = "";
              obj.zdyGiveClassHour = 0;
              obj.zdyDiscountAmount = "";
              obj.zdyActualPrice = "";
              obj.zdyTotalPrice = "";
              obj.productPackageId = this.saveRuleFormOrder.productPackageId;
              obj.classHour = this.saveRuleFormOrder.classHour;
              obj.singlePrice = this.saveRuleFormOrder.singlePrice;
              obj.totalPrice = this.saveRuleFormOrder.totalPrice;
            }
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
       
            }
          } else if (this.saveRuleFormOrder.orderType == 2) {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              courseTypeId: this.saveRuleFormOrder.courseTypeId,
              payType: this.saveRuleFormOrder.payType,
              productId: this.saveRuleFormOrder.productId,
              sectionId: this.saveRuleFormOrder.sectionId,
              studentId: this.id,
              teacherTitleId: this.saveRuleFormOrder.teacherTitleId,
              subjectId: this.saveRuleFormOrder.subjectId,
              coachAgreement: this.saveRuleFormOrder.coachAgreement,
              coachAgreementName:this.saveRuleFormOrder.coachAgreementName,
              classHour: this.saveRuleFormOrder.classHour,
              singlePrice: this.saveRuleFormOrder.singlePrice,
              // totalPrice: this.saveRuleFormOrder.totalPrice,
              productPackageId: this.saveRuleFormOrder.productPackageId,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
              zdyActualPrice:this.saveRuleFormOrder.zdyActualPrice,
              zdyTotalPrice:this.saveRuleFormOrder.totalPrice,
              zdyDiscountAmount:this.saveRuleFormOrder.zdyDiscountAmount
            };
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          } else {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              payType: this.saveRuleFormOrder.payType,
              sectionId: this.saveRuleFormOrder.sectionId,
              subjectId: this.saveRuleFormOrder.subjectId,
              studentId: this.id,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
              totalPrice:this.saveRuleFormOrder.totalPrice,
              zdyGiveClassHour:this.saveRuleFormOrder.zdyGiveClassHour
            };
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          }
          if (this.orderDialog.type == "edit") {
            await orderRemove([this.curEditOrder]).then(res => {
              if(res.code == 0&&res.msg=='操作成功！'){

              }else{
                  this.msgWarn(res.rows);
                  this.isDisable = false;
                  return false;
              }
            })
          }
          addOrder(obj).then(res => {
            if (res.code == 0&&res.msg=='下单成功') {
              this.orderDialogVisible = false;
               setTimeout(() => {
                this.isDisable = false;
                }, 1000)
              this.msgSuccess("下单成功！");
              this.getOrderList();
              this.oneToOneListFn();

            }else {
              this.msgError(res.rows);
              this.isDisable = false;
            }
          });
        } else {
          this.isDisable = false;
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    getFollowUpList() {
      followUpList({ studentId: Number(this.id) }).then(res => {
        if (res.code == 0) {
          for(var i=0;i<res.rows.length;i++){
              res.rows[i].imageArray=new Array();
              for(var k=0;k<res.rows[i].photoList.length;k++){
                res.rows[i].imageArray.push(res.rows[i].photoList[k].url)
              }
          }
           this.followUpList = res.rows;
           
        }
      });
    },
    imgItemSeeClick(item,index){
      this.dialogFileViewPrivateInfo = {
          show: true,
          type: "img",
          src: item.fileUrl ? item.fileUrl : item.url,
          curIndex: index ? index : 0,
          imgList:
            index != undefined
              ? this.photoList.map((item) => {
                  return item.fileUrl ? item.fileUrl : item.url;
                })
              : [item.fileUrl ? item.fileUrl : item.url],
        };
    },
    imgItemDelClick(index){
      this.photoList.splice(index, 1);
    },
    imgSelectClick(){
      this.$refs.uploadImg.value = "";
      this.$refs.uploadImg.click();
    },
    imgSelectChange(){
      if(this.photoList.length==8){
        this.msgWarn("最多只能上传八张图片！");
        return;
      }
      if (
        !/\.(png|jpg|gif|jpeg|webp)$/.test(this.$refs.uploadImg.files[0].name)
      ) {
        this.msgWarn("只能是图片格式的文件！");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.uploadImg.files[0]);
      fileUploads(formData).then((uploadRes) => {
        if (uploadRes[0].url) {
          this.photoList.push({
            url: uploadRes[0].url,
          });
        }
      });
    },
    
    dealMoneyType(row){
      let type
      switch (row.moneyType) {
        case 1:
          type = '全款'
          break;
        case 2:
          type = '定金'
          break;
        case 3:
          type = '尾款'
          break;
        case 4:
          type = '特殊订单'
          break;
        case 5: 
        type = '余额转充值'
          break;
          
        default:
          type = ''
          break;
      }
      return type
    },
    //试卷预览
    paperPreview(item){
      this.previewDialogVisible=true;
      this.paperId=item.id;
      if(item.id){
        getObjectById(
          item.id
        )
          .then(res => {
            if (res.code == 0) {
                this.previewRuleForm=res.rows;
                this.previewImage=res.rows.testPapers?res.rows.testPapers.split(','):[];
                this.previewRuleForm.examinationTime=res.rows.examinationTime.split(' ')[0];
            }
          })
          .catch(() => {});
      }else{
        this.previewRuleForm.examinationType=item.examinationType;
        this.previewRuleForm.subjectScore=item.subjectScore;
        this.previewRuleForm.subjectAllScore=item.subjectAllScore;
        this.previewRuleForm.remarks=item.remarks;
        this.previewImage=item.testPapers.split(',');
        let date = new Date(item.examinationTime);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        this.previewRuleForm.examinationTime = y + '-' + m + '-' + d;
      }
    },
    getPreviewImgList(index){
          let tempImgList = [...this.previewImage];//所有图片地址
                if (index == 0) return tempImgList;
                // 调整图片顺序，把当前图片放在第一位
                let start = tempImgList.splice(index);
                let remain = tempImgList.splice(0, index);
                return start.concat(remain);//将当前图片调整成点击缩略图的那张图片
    },
    zdySinglePriceBlur(){
      this.saveRuleFormOrder.zdyActualPrice=Number(this.saveRuleFormOrder.zdyClassHour)*Number(this.saveRuleFormOrder.zdySinglePrice)-Number(this.saveRuleFormOrder.zdyDiscountAmount)
    },
    beforeAvatarUpload(file) {
      console.log('file', file)
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
        return false;
      }else{
      }
    },
    showImg(e){
       if (e.target.tagName == 'IMG') {
        this.imgPreview.img = e.target.src
        this.imgPreview.show = true
      }
      },
      studentImportType(item){
      switch (item) {
          case 1:
            return '批量导入';
            break;
          case 2:
            return '销售添加';
            break;
          case 3:
            return '自主登录';
            break;
          case 4:
            return '教务添加';
            break;
          case 5:
            return '邀请码';
            break;
          default:
            return "暂无数据";
        }
    },
    //试卷编辑
    editStudent(item){
      this.editId=item.id;
      this.testPapers=[]
      this.studentResultSync=true;
      this.studentResultDialog={
        title:"编辑试卷信息",
        type:"edit"
      }
      if(item.id){
        getObjectById(
         item.id
        )
      .then(res => {
        if (res.code == 0) {
            console.log(res.rows.testPapers,'res.rows.testPapers...')
            this.studentRuleForm=res.rows;
            let getTestPapers=res.rows.testPapers.split(',');
            if(res.rows.testPapers!='undefined'||res.rows.testPapers!=''){
              getTestPapers.forEach(item => {  //处理图片需要转格式
              this.testPapers.push({
                "url": item,
              })
            })
            }else{
              this.testPapers=[];
            }
        }
      })
      .catch(() => {});
      }else{
        this.studentRuleForm.examinationType=item.examinationType;
        this.studentRuleForm.subjectScore=item.subjectScore;
        this.studentRuleForm.subjectAllScore=item.subjectAllScore;
        this.studentRuleForm.remarks=item.remarks;
        let getTestPapers=item.testPapers.split(',');
        if(item.testPapers!='undefined'){
          getTestPapers.forEach(item => {  //处理图片需要转格式
            this.testPapers.push({
              "url": item,
            })
          })
        }else{
          this.testPapers=[];
        }
        let date = new Date(item.examinationTime);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        this.studentRuleForm.examinationTime = y + '-' + m + '-' + d;
      }
      
    },
    handleClickItem(){
      this.$nextTick(()=>{
			let domImageMask = document.querySelector(".el-image-viewer__mask");
			 if (!domImageMask) {
		 	  return;
		 	}
			domImageMask.addEventListener("click", () => {
			  	 document.querySelector(".el-image-viewer__close").click();
			});
		})
    },
    //试卷删除
    paperRemove(item,index,indexs){
      if(item.id==''||item.id==undefined){
        this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.subjectList[index].xmtStudentAcademicRecordList.splice(indexs,1);
      });
        
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        academicrecordRemove(
         item.id
        )
          .then(res => {
            if (res.code == 0) {
              this.msgSuccess("删除成功！");
              this.daalEdit();
               this.subjectList[index].xmtStudentAcademicRecordList.splice(indexs,1);
            }
          })
          .catch(() => {});
      });
    },
    async getStudenInfo() {
      await this.getProvinceList();
      studentInfo(this.id).then(async res => {
        if (res.code == 0) {
          this.updateStudentFlag=res.rows.updateStudentFlag;
          this.getStatus=res.rows.status;
          this.saleId = res.rows.saleId;
          this.studentInfo = {
            photo:res.rows.photo,
            importType:res.rows.importType,
            constellationName: res.rows.constellationName,
            name: res.rows.name,
            contact: res.rows.contact,
            sex: String(res.rows.sex),
            sectionId: res.rows.sectionId,
            birthday: res.rows.birthday,
            provinceId: res.rows.provinceId,
            provinceName: "",
            cityId: res.rows.cityId,
            cityName: "",
            countyId: res.rows.countyId,
            countyName: "",
            schoolReach:res.rows.schoolReach,
            schoolName: res.rows.schoolName,
            sourceType: String(res.rows.sourceType),
            areaId: this.saveRuleForm.areaId,
            constellationId: res.rows.constellationId,
            childNature: res.rows.childNature,
            childMotherName: res.rows.childMotherName,
            childMotherPhone: res.rows.childMotherPhone,
            childFatherName: res.rows.childFatherName,
            childFatherPhone: res.rows.childFatherPhone,
            parentWechat: res.rows.parentWechat,
            firstClassTime: res.rows.firstClassTime,
            firstClassTimeRemark: res.rows.firstClassTimeRemark,
            formalClassTime: res.rows.formalClassTime,
            formalClassTimeRemark: res.rows.formalClassTimeRemark,
            yuwen: res.rows.yuwen,
            shuxue: res.rows.shuxue,
            yingyu: res.rows.yingyu,
            lishi: res.rows.lishi,
            zhengzhi: res.rows.zhengzhi,
            dili: res.rows.dili,
            wuli: res.rows.wuli,
            huaxue: res.rows.huaxue,
            shengwu: res.rows.shengwu,
            qitaxueke:res.rows.qitaxueke,
            xmtStudentSubjectRelationEntityList: [],
            id: res.rows.id,
            eferralstudent:res.rows.referralstudent,
            referralphone:res.rows.referralphone,
            account: res.rows.account,
            intentionId: res.rows.intentionId
              ? String(res.rows.intentionId)
              : "",
              contract:res.rows.contract
          };
          this.copyStr = `${this.studentInfo.name}同学你好，你的登录账号为：${this.studentInfo.account}，初始密码为：${this.studentInfo.account.substring(this.studentInfo.account.length - 6)}，登录后可在设置中修改账号密码和绑定手机号！`
          let firstClassTimeArray=res.rows.firstClassTime.split(',');
          let formalClassTimeArray=res.rows.formalClassTime.split(',');
          this.firstClassField='';
          this.formalClassField='';
          var a="",b=""
          for(var q=0;q<this.firstClassTimeStatus.length;q++){
            for(var q2=0;q2<firstClassTimeArray.length;q2++){
               if(this.firstClassTimeStatus[q].key==firstClassTimeArray[q2]){
                  a+=this.firstClassTimeStatus[q].name+','
                }
            }
          }
          this.firstClassField=a.substring(0,a.length-1)
          for(var k=0;k<this.firstClassTimeStatus.length;k++){
            for(var k2=0;k2<formalClassTimeArray.length;k2++){
              if(this.firstClassTimeStatus[k].key==formalClassTimeArray[k2]){
                b+=this.firstClassTimeStatus[k].name+',';
              }
            }
          }
           this.formalClassField=b.substring(0,b.length-1)
          if (res.rows.sex == 1) {
            this.studentInfo.sexName = "男";
          } else if (res.rows.sex == 2) {
            this.studentInfo.sexName = "女";
          } else {
            this.studentInfo.sexName = "";
          }
          if (this.studentInfo.sourceType) {
            for (let i = 0, len = this.sourceList.length; i < len; i++) {
              if (this.studentInfo.sourceType == this.sourceList[i].value) {
                this.studentInfo.sourceTypeName = this.sourceList[i].label;
                break;
              }
            }
          }
          if (this.studentInfo.sectionId) {
            for (let i = 0, len = this.sectionList.length; i < len; i++) {
              if (this.studentInfo.sectionId == this.sectionList[i].macroId) {
                this.studentInfo.sectionName = this.sectionList[i].name;
              }
            }
          }
          if (this.studentInfo.provinceId) {
            for (let i = 0, len = this.provinceList.length; i < len; i++) {
              if (this.provinceList[i].areaId == this.studentInfo.provinceId) {
                this.studentInfo.provinceName = this.provinceList[i].name;
              }
            }
            await this.getCityList();
            for (let i = 0, len = this.cityList.length; i < len; i++) {
              if (this.cityList[i].areaId == this.studentInfo.cityId) {
                this.studentInfo.cityName = this.cityList[i].name;
              }
            }
            await this.getCountyList();
            for (let i = 0, len = this.countyList.length; i < len; i++) {
              if (this.countyList[i].areaId == this.studentInfo.countyId) {
                this.studentInfo.countyName = this.countyList[i].name;
              }
            }
          }
          this.initClassTime_info();
          for (
            let i = 0, len = this.firstClassTimeList_info.length;
            i < len;
            i++
          ) {
            for (
              let j = 0, lenJ = this.firstClassTimeList_info[i].length;
              j < lenJ;
              j++
            ) {
              if (
                res.rows.firstClassTime &&
                res.rows.firstClassTime.includes(
                  this.firstClassTimeList_info[i][j].key
                )
              ) {
                this.firstClassTimeList_info[i][j].status = true;
              }
            }
          }
          for (
            let i = 0, len = this.formalClassTimeList_info.length;
            i < len;
            i++
          ) {
            for (
              let j = 0, lenJ = this.formalClassTimeList_info[i].length;
              j < lenJ;
              j++
            ) {
              if (
                res.rows.formalClassTime &&
                res.rows.formalClassTime.includes(
                  this.formalClassTimeList_info[i][j].key
                )
              ) {
                this.formalClassTimeList_info[i][j].status = true;
              }
            }
          }
          for (let i = 0, len = this.subjectList_info.length; i < len; i++) {
            this.subjectList_info[i].status = false;
            this.subjectList_info[i].select = false;
            this.subjectList_info[i].subjectScore = "";
            this.subjectList_info[i].subjectAllScore = "";
            this.subjectList_info[i].sexNeedId = "";
            this.subjectList_info[i].styleNeedId = "";
            this.subjectList_info[i].speedNeedId = "";
            this.subjectList_info[i].ageNeedId = "";
            this.subjectList_info[i].content = "";
            if (
              (this.subjectList_info[i].name == "其他学科" &&
                res.rows.qitaxueke == 1) ||
              (this.subjectList_info[i].name == "语文" &&
                res.rows.yuwen == 1) ||
              (this.subjectList_info[i].name == "数学" &&
                res.rows.shuxue == 1) ||
              (this.subjectList_info[i].name == "英语" &&
                res.rows.yingyu == 1) ||
              (this.subjectList_info[i].name == "历史" &&
                res.rows.lishi == 1) ||
              (this.subjectList_info[i].name == "政治" &&
                res.rows.zhengzhi == 1) ||
              (this.subjectList_info[i].name == "地理" && res.rows.dili == 1) ||
              (this.subjectList_info[i].name == "物理" && res.rows.wuli == 1) ||
              (this.subjectList_info[i].name == "化学" &&
                res.rows.huaxue == 1) ||
              (this.subjectList_info[i].name == "生物" && res.rows.shengwu == 1)
            ) {
              this.subjectList_info[i].select = true;
            }
          }
          for (let i = 0, len = this.subjectList_info.length; i < len; i++) {
            //  this.subjectList_info[i].listArray=new Array();
            for (
              let j = 0,
                lenJ = res.rows.xmtStudentSubjectRelationEntityList.length;
              j < lenJ;
              j++
            ) {
              if (
                this.subjectList_info[i].macroId ==
                res.rows.xmtStudentSubjectRelationEntityList[j].subjectId
              ) {
                this.subjectList_info[i].xmtStudentAcademicRecordList=res.rows.xmtStudentSubjectRelationEntityList[j].xmtStudentAcademicRecordList;
                this.subjectList_info[i].status = true;
                this.subjectList_info[i].subjectScore =
                  res.rows.xmtStudentSubjectRelationEntityList[j].subjectScore;
                this.subjectList_info[i].subjectAllScore =
                  res.rows.xmtStudentSubjectRelationEntityList[
                    j
                  ].subjectAllScore;
                this.subjectList_info[i].sexNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].sexNeedId;
                this.subjectList_info[i].styleNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].styleNeedId;
                this.subjectList_info[i].speedNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].speedNeedId;
                this.subjectList_info[i].ageNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].ageNeedId;
                this.subjectList_info[i].content =
                  res.rows.xmtStudentSubjectRelationEntityList[j].content;
              }
            }
          }
        }
      })
    },
    addFollowUpClick() {

      this.followUpDialogVisible = true;
      this.followUpDialog = {
        title: "新增跟进记录",
        type: "save"
      };
      for (let i = 0, len = this.followTagList.length; i < len; i++) {
        this.followTagList[i].select = false;
      }
      this.saveRuleFormFollowUp = {
        nextFollowupTime: '',
        followContent: '',
        id: ''
      };
      this.saveRuleFormFollowUp.nextFollowupTime="";
      this.photoList=[];
    },
    dealNumTofix2(str) {
      if (str != "" && str.substr(0, 1) == ".") {
        str = "";
      }
      str = str.replace(/^0*(0\.|[1-9])/, "$1"); //解决 粘贴不生效
      str = str.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      str = str.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      str = str.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      str = str.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (str.indexOf(".") < 0 && str != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (str.substr(0, 1) == "0" && str.length == 2) {
          str = str.substr(1, str.length);
        }
      }
      return str;
    },
    zdyClassHourBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdyClassHour) {
        // 处理单价和总价
        if (zdySinglePrice) {
          zdyTotalPrice = zdyClassHour * zdySinglePrice;
        } else if (zdyTotalPrice) {
          zdySinglePrice =
            Math.round((zdyTotalPrice / zdyClassHour) * 100) / 100;
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
        zdyTotalPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    zdyActualPriceBlur() {
      let { zdyDiscountAmount, zdyTotalPrice, zdyActualPrice } =
        this.saveRuleFormOrder;
      if (zdyActualPrice && zdyTotalPrice) {
        if (zdyActualPrice > zdyTotalPrice) {
          this.msgWarn("实际支付不能大于总价格，请重新输入！");
          zdyActualPrice = zdyTotalPrice;
        }
        zdyDiscountAmount = zdyTotalPrice - zdyActualPrice;
      } else if (zdyActualPrice && zdyDiscountAmount) {
        if (zdyActualPrice < zdyDiscountAmount) {
          zdyDiscountAmount = "";
        }
        zdyTotalPrice = zdyActualPrice - zdyDiscountAmount;
      } else {
        zdyTotalPrice = zdyActualPrice - zdyDiscountAmount;
      }
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    zdyDiscountAmountBlur() {
      let { zdyDiscountAmount, zdyTotalPrice, zdyActualPrice } =
        this.saveRuleFormOrder;
      if (zdyDiscountAmount && zdyTotalPrice) {
        if (Number(zdyDiscountAmount) >Number(zdyTotalPrice) ) {
          this.msgWarn("优惠价格不能大于总价！");
          zdyDiscountAmount = "";
        }
      }
      zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    zdyTotalPriceBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdyTotalPrice) {
        // 处理课时和单价
        if (zdyClassHour && zdySinglePrice) {
          if (zdyClassHour * zdySinglePrice !== zdyTotalPrice) {
            zdyClassHour = "";
            zdySinglePrice = "";
          }
        } else if (zdyClassHour && !zdySinglePrice) {
          zdySinglePrice =
            Math.round((zdyTotalPrice / zdyClassHour) * 100) / 100;
        } else if (!zdyClassHour && zdySinglePrice) {
          let remainder = zdyTotalPrice % zdySinglePrice;
          zdyClassHour =
            Math.round((zdyTotalPrice / zdySinglePrice) * 100) / 100;
          if (remainder) {
            this.msgWarn(
              `计算出的课时数为${zdyClassHour},不合法，请重新输入！`
            );
            zdyClassHour = "";
          }
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    zdySinglePriceBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdySinglePrice) {
        if (zdySinglePrice === "0") {
          this.msgWarn(
            "单价不合法，必须是必须是大于0的最多保留两位小数的数字！"
          );
          zdySinglePrice = "";
        }
        // 处理课时和总价
        if (zdyClassHour) {
          zdyTotalPrice = zdySinglePrice * zdyClassHour;
        } else if (zdyTotalPrice) {
          let remainder = zdyTotalPrice % zdySinglePrice;
          zdyClassHour =
            Math.round((zdyTotalPrice / zdySinglePrice) * 100) / 100;
          if (remainder) {
            this.msgWarn(
              `计算出的课时数为${zdyClassHour},不合法，请重新输入！`
            );
            zdyClassHour = "";
          }
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
        zdyTotalPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    filterZdyClassHour() {
      // 课时大于等于1的正整数
      this.saveRuleFormOrder.zdyClassHour =
        this.saveRuleFormOrder.zdyClassHour.replace(/[^\d]/g, "");
      if (this.saveRuleFormOrder.zdyClassHour.length == 1) {
        this.saveRuleFormOrder.zdyClassHour =
          this.saveRuleFormOrder.zdyClassHour.replace(/[^1-9]/gi, "");
      } else if (this.saveRuleFormOrder.zdyClassHour.length > 1) {
        this.saveRuleFormOrder.zdyClassHour =
          this.saveRuleFormOrder.zdyClassHour.replace(/^0/, "");
      }
    },
    fileterZdySinglePrice() {
      this.saveRuleFormOrder.zdySinglePrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdySinglePrice
      );
    },
    filterZdyDiscountAmount() {
      this.saveRuleFormOrder.zdyDiscountAmount = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyDiscountAmount
      );
    },
    filterZdyTotalPrice() {
      this.saveRuleFormOrder.zdyTotalPrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyTotalPrice
      );
    },
    filterZdyActualPrice() {
      this.saveRuleFormOrder.zdyActualPrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyActualPrice
      );
    },
    sourceTypeFn(val){
        switch (val) {
        case '1':
          return "地推活动";
        case '2':
          return "网络活动";
        case '3':
          return "美团活动";
        case '4':
          return "A级客户";
        case '5':
          return "家长转介绍";
        default:
          return "暂无数据";
      }
    },
    editFollowUpClick(row) {
      this.followUpDialog = {
        title: "修改跟进记录",
        type: "edit"
      };
      for (let i = 0, len = this.followTagList.length; i < len; i++) {
        if (
          row.followupTags &&
          row.followupTags
            .split(",")
            .includes(String(this.followTagList[i].macroId))
        ) {
          this.followTagList[i].select = true;
        } else {
          this.followTagList[i].select = false;
        }
      }
      this.followUpDialogVisible = true;
      this.saveRuleFormFollowUp = {
        nextFollowupTime: row.nextFollowupTime,
        followContent: row.followContent,
        id: row.id
      };
      this.photoList = row.photoList.map((item) => {
        return item;
      });
     
    },
    dealTags(value) {
      if (value) {
        let arr = value.split(","),
          tags = [];
        for (let i = 0, len = this.followTagList.length; i < len; i++) {
          if (arr.includes(String(this.followTagList[i].macroId))) {
            tags.push(this.followTagList[i].name);
          }
        }
        return tags.join("，") ? tags.join("，") : "暂无";
      }
      return "暂无";
    },
    intentionChange() {
      studentUpdate({
        id: this.id,
        intentionId: this.studentInfo.intentionId
      }).then(res => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.saveDialogVisible = false;
          this.getStudenInfo();
        }
      });
    },
    prevAndNextClick(value) {
      prevAndNext({
        id: this.id,
        contract: this.contract == -1 ? null : this.contract
      }).then(res => {
        this.prevId = res.msg.xmtStudentUp;
        this.nextId = res.msg.xmtStudentDown;
        if (value == "prev") {
          this.$router.push({
            // name: "awaitFollowUp_studentInfo",
            name:"myCustomer_studentInfo",
            params: {
              id: this.prevId,
            }
          });
        } else if (value == "next") {
          this.$router.push({
            // name: "awaitFollowUp_studentInfo",
            name:"myCustomer_studentInfo",
            params: {
              id: this.nextId
            }
          });
        }
      });
    },
    addOrderClick() {
       this.downOrderStatus=true;
        this.downParams={
          id:this.id,
          type:"add"
        }
//     this.orderDialog = {
//         title: "新增订单",
//         type: "add"
//       };

//       this.orderDialogVisible = true;
//       this.saveRuleFormOrder = {
//         orderType: "5", //1一对一  2一对多  3试听课
//         payType: "2", //1线上支付   2线下支付
//         courseTypeId: "1", //1大班课  2小班课  3答疑课
//         productId: "",
//         certificate: "", //付款凭证
//         coachAgreement: "", //辅导协议
//         sectionId: "", //学段
//         subjectId: "",
//         teacherTitleId: "",
//         classHour: "",
//         classHourId: "", //不用传到后台
//         singlePrice: "",
//         totalPrice: "",
//         zdyClassHour: "",
//         zdySinglePrice: "",
//         zdyGiveClassHour: 0,
//         zdyDiscountAmount: "",
//         zdyActualPrice: "",
//         studentId: this.id,
//         productPackageId: "",
//         remark: "",
//         payMent: "1", //1支付宝  2微信  3对公账户  4pos机
//         moneyType: "1"
//       };
//       this.orderClick();
    },
    async orderClick() {
        await this.getProductList();
        this.translateTotal=""
        if(this.saveRuleFormOrder.orderType!=5){//一对一充值除外
        if (this.productList.length > 0) {
          this.saveRuleFormOrder.productId = this.productList[0].id;
          this.getProductDetail();
        } else {
          this.saveRuleFormOrder.sectionId = "";
          this.saveRuleFormOrder.productId = "";
          this.saveRuleFormOrder.periodId = "";
          this.saveRuleFormOrder.subjectId = "";
          this.saveRuleFormOrder.teacherTitleId = "";
          this.saveRuleFormOrder.classHourId = "";
          this.saveRuleFormOrder.singlePrice = "";
          this.saveRuleFormOrder.totalPrice = "";
        }
      }
    }, 
   
    getProductList2() {
      return productListNoPage({
        productType: this.saveRuleFormOrder.orderType,
        courseTypeId:
          this.saveRuleFormOrder.orderType == 2
            ? this.saveRuleFormOrder.courseTypeId
            : null
      }).then(res => {
        if (res.code == 0) {
          this.productList=[];
          for(var i=0;i<res.rows.length;i++){
            if(res.rows[i].id==this.saveRuleFormOrder.productId){
              this.productList.push(res.rows[i]);
            }
          }
        }
      });
    },
    getProductList() {
      if(this.saveRuleFormOrder.orderType==3){//试听课
        return productListNoPage({
        isSubscribe:1,
        courseTypeId:
          this.saveRuleFormOrder.orderType == 2
            ? this.saveRuleFormOrder.courseTypeId
            : null
      }).then(res => {
        if (res.code == 0) {
          this.productList = res.rows;
          
        }
      });
      }
      // else if(this.saveRuleFormOrder.orderType==1){//一对一
      //   return productListNoPage({
      //   productType: this.saveRuleFormOrder.orderType,
      //   isSubscribe:0,
      //   courseTypeId:
      //     this.saveRuleFormOrder.orderType == 2
      //       ? this.saveRuleFormOrder.courseTypeId
      //       : null
      // }).then(res => {
      //   if (res.code == 0) {
      //     this.productList = res.rows;
      //     console.log(res.rows,'res.rowsres.rows...')
      //   }
      // });
      // }
      else if(this.saveRuleFormOrder.orderType==2){//一对多
          return productListNoPage({
            productType: this.saveRuleFormOrder.orderType,
            courseTypeId:
              this.saveRuleFormOrder.orderType == 2
                ? this.saveRuleFormOrder.courseTypeId
                : null
          }).then(res => {
            if (res.code == 0) {
              this.productList = res.rows;
            }
          });
      } 
      // return productListNoPage({
      //   productType: this.saveRuleFormOrder.orderType,
      //   courseTypeId:
      //     this.saveRuleFormOrder.orderType == 2
      //       ? this.saveRuleFormOrder.courseTypeId
      //       : null
      // }).then(res => {
      //   if (res.code == 0) {
      //     this.productList = res.rows;
      //   }
      // });
    },
    lookReason(row){
      this.centerDialogVisible=true;
      this.centerData=row.disagreeCause?row.disagreeCause:"";
    },
    productChange() {
      this.getProductDetail();
    },
    getProductDetail() {
      console.log(this.saveRuleFormOrder.productId,'123666')
      return productInfoDetail(this.saveRuleFormOrder.productId).then(res => {
        if (res.code == 0) {
          this.productDetail.productPackage =
            res.rows.xmtProductPackageEntityList;
          if (this.saveRuleFormOrder.orderType == 2) {
            this.dealPeriod();
          } else {
            this.dealSection();
          }
        }
      });
    },
     dealPeriod() {
      this.productDetail.periodList = [];
      this.productDetail.periodIdList = [];
      this.saveRuleFormOrder.periodId = "";
      for (
        let i = 0, len = this.productDetail.productPackage.length;
        i < len;
        i++
      ) {
        if (
          !this.productDetail.periodIdList.includes(
            this.productDetail.productPackage[i].periodId
          ) &&
          this.productDetail.productPackage[i].periodId
        ) {
          this.productDetail.periodList.push({
            periodName: this.productDetail.productPackage[i].periodName,
            periodId: this.productDetail.productPackage[i].periodId,
          });
          this.productDetail.periodIdList.push(
            this.productDetail.productPackage[i].periodId
          );
        }
      }
      if (this.productDetail.periodList.length > 0) {
        this.saveRuleFormOrder.periodId =
          this.productDetail.periodList[0].periodId;
      }
      this.dealSubject();
    },
    dealSection() {
      this.productDetail.sectionList = [];
      this.productDetail.sectionIdList = [];
      for (
        let i = 0, len = this.productDetail.productPackage.length;
        i < len;
        i++
      ) {
        if (
          !this.productDetail.sectionIdList.includes(
            this.productDetail.productPackage[i].sectionId
          )
        ) {
          this.productDetail.sectionList.push({
            sectionName: this.productDetail.productPackage[i].sectionName,
            sectionId: this.productDetail.productPackage[i].sectionId
          });
          this.productDetail.sectionIdList.push(
            this.productDetail.productPackage[i].sectionId
          );
        }
      }
      this.saveRuleFormOrder.sectionId = this.productDetail.sectionList[0].sectionId;
      this.dealSubject();
    },
    dealSubject() {
      if (this.saveRuleFormOrder.orderType == 2) {
        this.productDetail.subjectList = [];
        this.productDetail.subjectIdList = [];
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            !this.productDetail.subjectIdList.includes(
              this.productDetail.productPackage[i].subjectId
            )
          ) {
            this.productDetail.subjectList.push({
              subjectName: this.productDetail.productPackage[i].subjectName,
              subjectId: this.productDetail.productPackage[i].subjectId
            });
            this.productDetail.subjectIdList.push(
              this.productDetail.productPackage[i].subjectId
            );
          }
        }
        this.saveRuleFormOrder.subjectId = this.productDetail.subjectList[0].subjectId;
      }
      this.dealTeacherTitle();
    },
    examinationTimeFn(row){
        let date = new Date(row);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        return y + '-' + m + '-' + d;
    },
    examinationTypeFn(row){
      if(row==1){
        return '模拟考试'
      }else if(row==2){
        return '期中'
      }else if(row==3){
        return '期末'
      }else if(row==4){
        return '月考'
      }else if(row==5){
        return '随堂练'
      }
    },
    dealTeacherTitle() {
      this.productDetail.teacherTitleList = [];
      this.productDetail.teacherTitleIdList = [];
      if (this.saveRuleFormOrder.orderType == 3) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.sectionId ==
              this.productDetail.productPackage[i].sectionId &&
            !this.productDetail.teacherTitleIdList.includes(
              this.productDetail.productPackage[i].teacherTitleId
            )
          ) {
            this.productDetail.teacherTitleList.push({
              teacherTitleName: this.productDetail.productPackage[i]
                .teacherTitleName,
              teacherTitleId: this.productDetail.productPackage[i]
                .teacherTitleId
            });
            this.productDetail.teacherTitleIdList.push(
              this.productDetail.productPackage[i].teacherTitleId
            );
          }
        }
        this.saveRuleFormOrder.teacherTitleId = this.productDetail.teacherTitleList[0].teacherTitleId;
      } else if (this.saveRuleFormOrder.orderType == 2) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            this.saveRuleFormOrder.subjectId ==
              this.productDetail.productPackage[i].subjectId &&
            !this.productDetail.teacherTitleIdList.includes(
              this.productDetail.productPackage[i].teacherTitleId
            )
          ) {
            this.productDetail.teacherTitleList.push({
              teacherTitleName: this.productDetail.productPackage[i]
                .teacherTitleName,
              teacherTitleId: this.productDetail.productPackage[i]
                .teacherTitleId
            });
            this.productDetail.teacherTitleIdList.push(
              this.productDetail.productPackage[i].teacherTitleId
            );
          }
        }
        this.saveRuleFormOrder.teacherTitleId = this.productDetail.teacherTitleList[0].teacherTitleId;
      }
      this.dealClassHour();
    },
    dealClassHour() {
      this.productDetail.classHourList = [];
      this.productDetail.classHourIdList = [];
      if (this.saveRuleFormOrder.orderType == 3) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.sectionId ==
              this.productDetail.productPackage[i].sectionId &&
            this.saveRuleFormOrder.teacherTitleId ==
              this.productDetail.productPackage[i].teacherTitleId &&
            !this.productDetail.classHourIdList.includes(
              this.productDetail.productPackage[i].id
            )
          ) {
            this.productDetail.classHourList.push({
              classHour: this.productDetail.productPackage[i].classHour,
              classHourName:
                this.productDetail.productPackage[i].classHour + "课时",
              id: this.productDetail.productPackage[i].id,
              singlePrice: this.productDetail.productPackage[i].singlePrice,
              totalPrice: this.productDetail.productPackage[i].totalPrice
            });
            this.productDetail.classHourIdList.push(
              this.productDetail.productPackage[i].id
            );
          }
        }
        this.productDetail.classHourList.push({
          classHour: "自定义课时",
          classHourName: "自定义课时",
          id: -1
        });
      } else if (this.saveRuleFormOrder.orderType == 2) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            this.saveRuleFormOrder.subjectId ==
              this.productDetail.productPackage[i].subjectId &&
            this.saveRuleFormOrder.teacherTitleId ==
              this.productDetail.productPackage[i].teacherTitleId &&
            !this.productDetail.classHourIdList.includes(
              this.productDetail.productPackage[i].id
            )
          ) {
            this.productDetail.classHourList.push({
              classHour: this.productDetail.productPackage[i].classHour,
              classHourName:
                this.productDetail.productPackage[i].classHour + "课时",
              id: this.productDetail.productPackage[i].id,
              singlePrice: this.productDetail.productPackage[i].singlePrice,
              totalPrice: this.productDetail.productPackage[i].totalPrice
            });
            this.productDetail.classHourIdList.push(
              this.productDetail.productPackage[i].id
            );
          }
        }
      }
      this.saveRuleFormOrder.productPackageId = this.productDetail.classHourList[0].id;
      this.saveRuleFormOrder.classHour = this.productDetail.classHourList[0].classHour;
      this.saveRuleFormOrder.classHourId = this.productDetail.classHourList[0].id;
      if(this.saveRuleFormOrder.zdyClassHour){
        this.saveRuleFormOrder.classHourId = -1;
      }else{
         this.saveRuleFormOrder.classHourId = this.productDetail.classHourList[0].id;
      }
      this.saveRuleFormOrder.singlePrice = this.productDetail.classHourList[0].singlePrice;
      this.saveRuleFormOrder.totalPrice = this.productDetail.classHourList[0].totalPrice;
      this.saveRuleFormOrder.zdyTotalPrice =this.productDetail.classHourList[0].totalPrice;
        this.saveRuleFormOrder.zdyActualPrice =this.productDetail.classHourList[0].totalPrice;
  },
    orderSectionChange() {
      console.log(this.saveRuleFormOrder.sectionId)
      // this.saveRuleFormOrder.zdyClassHour=""
      // this.saveRuleFormOrder.zdySinglePrice=""
      // this.saveRuleFormOrder.zdyTotalPrice=""
      // this.saveRuleFormOrder.zdyGiveClassHour=""
      // this.saveRuleFormOrder.zdyDiscountAmount=""
      // this.saveRuleFormOrder.zdyActualPrice=""
      this.dealSubject();
    },
    orderSubjectChange() {
      this.dealTeacherTitle();
    },
    orderTeacherTitleChange() {
      this.dealClassHour();
    },
    orderClassHourChange() {
      for (
        let i = 0, len = this.productDetail.classHourList.length;
        i < len;
        i++
      ) {
        if (
          this.productDetail.classHourList[i].id ==
            this.saveRuleFormOrder.classHourId &&
          this.saveRuleFormOrder.classHourId != -1
        ) {
          this.saveRuleFormOrder.productPackageId = this.productDetail.classHourList[
            i
          ].id;
          this.saveRuleFormOrder.classHour = this.productDetail.classHourList[
            i
          ].classHour;
          this.saveRuleFormOrder.classHourId = this.productDetail.classHourList[
            i
          ].id;
          this.saveRuleFormOrder.singlePrice = this.productDetail.classHourList[
            i
          ].singlePrice;
          this.saveRuleFormOrder.totalPrice = this.productDetail.classHourList[
            i
          ].totalPrice;
        } else {
          this.saveRuleFormOrder.productPackageId = "";
          this.saveRuleFormOrder.classHour = "";
          this.saveRuleFormOrder.classHourId = this.productDetail.classHourList[
            i
          ].id;
          this.saveRuleFormOrder.singlePrice = "";
          this.saveRuleFormOrder.totalPrice = "";
        }
      }
    },
    certificateSuccess(response, file) {
      if (response[0].url) {
        this.saveRuleFormOrder.certificate = response[0].url;
        this.srcList=[]
        this.srcList.push( response[0].url)
      }
    },
    certificateError(err, file) {
      this.msgError("付款凭证上传失败！");
    },
    coachAgreementSuccess(response, file) {
      if (response[0].url) {
        this.saveRuleFormOrder.coachAgreement = response[0].url;
        this.saveRuleFormOrder.coachAgreementName = response[0].originalName;
      }
    },
    coachAgreementError(err, file) {
      this.msgError("辅导协议上传失败！");
    },
    getOrderList() {
      orderList({ studentId: this.id }).then(res => {
        if (res.code == 0) {
          this.orderList = res.rows;
        }
      });
    },
    oneToOneListFn() {
      oneToOneList({ studentId: this.id }).then(res => {
        if (res.code == 0) {
          let totalPrice =0;
         this.allRechargeMoney= res.rows.reduce((totalPrice, item) => totalPrice + item.totalPrice, 0)
          this.rechargeData = res.rows;
        }
      });
    },
    editOrderClick(row) {
      // this.orderDialog = {
      //   title: "修改订单",
      //   type: "edit"
      // };
      // this.orderDialogVisible = true;
      // this.srcList=[]
      // this.getOrderDetail(row.id);
      this.downOrderStatus=true;
      this.downParams={
        id:row.studentId,
        type:"edit",
        classId:row.id,
        productId:row.productId
      }
    },
    //删除订单
    removeOrderClick(row){
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          logicalDeletion({id:row.id}).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getOrderList();
              this.oneToOneListFn();
            }
          });
        })
        .catch(() => {});
    },
    seeOrderClick(row) {
      this.orderInfoStatus=true;
      this.orderInfoParams={
        id:row.id,
        studentId:row.studentId
      }
      // this.detailDialogVisible=true;
      // orderDetail(row.id).then((res) => {
      //   if (res.code == 0) {
      //     this.orderDetail = res.rows;
      //     this.orderSrc.push(res.rows.certificate)
      //     this.infoOrderType=res.rows.orderType;
      //     switch (this.orderDetail.payMent) {
      //       case 1:
      //         this.orderDetail.payMentName = "支付宝";
      //         break;
      //       case 2:
      //         this.orderDetail.payMentName = "微信";
      //         break;
      //       case 3:
      //         this.orderDetail.payMentName = "对公账户";
      //         break;
      //       case 4:
      //         this.orderDetail.payMentName = "POS";
      //         break;
      //       default:
      //         this.orderDetail.payMentName = "";
      //     }
      //     switch (this.orderDetail.moneyType) {
      //       case 1:
      //         this.orderDetail.moneyTypeName = "全款";
      //         break;
      //       case 2:
      //         this.orderDetail.moneyTypeName = "定金";
      //         break;
      //       case 3:
      //         this.orderDetail.moneyTypeName = "尾款";
      //         break;
      //       case 4:
      //         this.orderDetail.moneyTypeName = "特殊订单";
      //       case 5: 
      //         this.orderDetail.moneyTypeName = "余额转充值";
      //         break;
      //       default:
      //         this.orderDetail.moneyTypeName = "";
      //     }
      //     switch (this.orderDetail.orderStatus) {
      //       case 1:
      //         this.orderDetail.orderStatusName = "待财务审核";
      //         break;
      //       case 2:
      //         this.orderDetail.orderStatusName = "财务审核拒绝";
      //         break;
      //       case 3:
      //         this.orderDetail.orderStatusName = "教务主管分配中";
      //         break;
      //       case 4:
      //         this.orderDetail.orderStatusName = "待排课";
      //         break;
      //       case 5:
      //         this.orderDetail.orderStatusName = "排课中";
      //         break;
      //       case 6:
      //         this.orderDetail.orderStatusName = "已退款";
      //         break;
      //       case 7:
      //         this.orderDetail.orderStatusName = "订单已完成";
      //         break;
      //       case 8:
      //         this.orderDetail.orderStatusName = "订单作废";
      //         break;
      //       case 9:
      //         this.orderDetail.orderStatusName = "退款中";
      //         break;
      //       default:
      //         this.orderDetail.orderStatusName = "";
      //     }
      //     switch (this.orderDetail.productType) {
      //       case 1:
      //       case 3:
      //         this.orderDetail.productTypeName =
      //           this.orderDetail.isSubscribe == 1 ? "试听课" : "一对一";
      //         break;
      //       case 2:
      //         case 5:
      //         this.orderDetail.productTypeName = "一对多";
      //         break;
      //       case 4:
      //         this.orderDetail.productTypeName = "公开课";
      //         break;
      //       default:
      //         this.orderDetail.productTypeName = "";
      //     }
      //   }
      // });
      // studentInfo(row.studentId).then((res) => {
      //   if (res.code == 0) {
      //     this.studentDetail = res.rows;
      //     switch (this.studentDetail.sex) {
      //       case 1:
      //         this.studentDetail.sexName = "男";
      //         break;
      //       case 2:
      //         this.studentDetail.sexName = "女";
      //       default:
      //         this.studentDetail.sexName = "";
      //     }
      //     if (this.studentDetail.provinceName) {
      //       this.studentDetail.address =
      //         this.studentDetail.provinceName +
      //         "" +
      //         this.studentDetail.cityName +
      //         "" +
      //         this.studentDetail.countyName;
      //     }
      //   }
      // });
    },
    getOrderDetail(id) {
      orderDetail(id).then(res => {
        if (res.code == 0) {
         if(res.rows.productType==2){
           this.editOrderId=res.rows.productId;
         }else{
           this.editOrderId=res.rows.productId;
         }
          this.curEditOrder = id;
          this.srcList.push(res.rows.certificate);
          this.saveRuleFormOrder = {
            orderType: String(res.rows.orderType), //1一对一  2一对多  3试听课
            payType: String(res.rows.payType), //1线上支付   2线下支付
            courseTypeId: res.rows.courseTypeId
              ? String(res.rows.courseTypeId)
              : null, //1大班课  2小班课  3答疑课
            productId:res.rows.productPid,
            certificate: res.rows.certificate, //付款凭证
            coachAgreement: res.rows.coachAgreement, //辅导协议
            sectionId: res.rows.sectionId, //学段
            subjectId: res.rows.subjectId,
            teacherTitleId: res.rows.teacherTitleId,
            classHour: res.rows.classHour,
            classHourId: res.rows.classHourId, //不用传到后台
            singlePrice: res.rows.singlePrice,
            totalPrice: res.rows.totalPrice,
            zdyClassHour: res.rows.zdyClassHour,
            zdySinglePrice: res.rows.zdySinglePrice,
            zdyGiveClassHour: res.rows.zdyGiveClassHour,
            zdyDiscountAmount: res.rows.zdyDiscountAmount,
            zdyActualPrice: res.rows.zdyActualPrice,
            zdyTotalPrice:res.rows.zdyTotalPrice,
            studentId: this.id,
            productPackageId: res.rows.productPackageId,
            remark: res.rows.remark,
            payMent: res.rows.payMent ? String(res.rows.payMent) : null,
            moneyType: res.rows.moneyType ? String(res.rows.moneyType) : null,
            coachAgreementName: res.rows.coachAgreementName,
          };
          //  this.orderClick();
          this.numberToString(res.rows.totalPrice)
          this.orderClick();
        }
      });
    },
     numberToString(n){
       if (!/^(0|\-*[1-9]\d*)(\.\d+)?$/.test(n)) return "数据非法";
	var fuhao = (n.toString().indexOf("-")==0?"负":"");
    
    var unit = "千百拾亿千百拾万千百拾元角分", str = "";
        n += "00";
     //如果是负数就就截取
   	if(fuhao == "负"){
		n = n.substring(1, n.length);
   	}
    var p = n.indexOf('.');
    if (p >= 0)
        n = n.substring(0, p) + n.substr(p+1, 2);
        unit = unit.substr(unit.length - n.length);
    for (var i=0; i < n.length; i++)
        str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
    this.translateTotal=fuhao+str.replace(/零(千|百|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万|壹(拾)/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整")
    },
    formatterOrderType(row) {
      switch (row.orderType) {
        case 1:
          return "一对一充值";
        case 2:
        case 5:
          return "一对多";
        case 3:
          return "试听课";
      }
    },
    formatterCourseType(row) {
      switch (row.courseTypeId) {
        case 1:
          return "大班课";
        case 2:
          return "小班课";
        case 3:
          return "答疑课";
        default:
          return "";
      }
    },
    formatterClassHour(row) {
      if (row.zdyClassHour) {
        return row.zdyClassHour;
      } else {
        return row.classHour;
      }
    },
    formatterTotalPrice(row) {
      if (row.zdyActualPrice) {
        return row.zdyActualPrice;
      } else {
        return row.totalPrice;
      }
    },
    formatterOrderStatus(row) {
      switch (row.orderStatus) {
        case 1:
          return "待财务审核";
        case 2:
          return "财务审核拒绝";
        case 3:
          return "教务主管分配中";
        case 4:
          return "待排课";
        case 5:
          return "排课中";
        case 6:
          return "已退款";
        case 7:
          return "订单已完成";
        case 8:
          return "订单已作废";
        case 9:
          return "退款中";
      }
    },
    //添加学生成绩
    addStudentResult(item,index){
      // this.getMacroId=item.id;
      this.getSubjectId=item.macroId;
      this.studentResultSync=true;
      this.studentRuleForm={
        examinationType:"",//考试类型,
        subjectScore:"",//成绩
        subjectAllScore:"",//总分
        examinationTime:"",//考试时间
        remarks:"",//备注说明
      }
      this.testPapers=[];
      this.studentResultDialog={
        title:"添加试卷信息",
        type:"add"
      }
    },
     handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleAvatarSuccess(file, fileList){
        this.testPapers.push({url:file[0].url});
      },
    confirmStudent(formName){
    this.$refs[formName].validate(valid => {
        if (valid) {
            this.studentRuleForm.testPapers='';
            let papersList = this.testPapers.map((obj)=>{return obj.url})
            this.studentRuleForm.testPapers=papersList.join(',');
            this.studentRuleForm.studentId=this.id;
            // this.studentRuleForm.subjectId=this.getMacroId;
            if(this.studentResultDialog.type=='add'){
              for(var i=0;i<this.subjectList.length;i++){
                  if( this.subjectList[i].macroId==this.getSubjectId){
                    this.subjectList[i].xmtStudentAcademicRecordList.push(this.studentRuleForm)
                  }
              }
            }else if(this.studentResultDialog.type=='edit'){
              for(var i=0;i<this.subjectList.length;i++){
                if(this.subjectList[i].id){
                  for(var j=0;j<this.subjectList[i].xmtStudentAcademicRecordList.length;j++){
                    if(this.subjectList[i].xmtStudentAcademicRecordList[j].id==this.editId){
                      this.subjectList[i].xmtStudentAcademicRecordList[j]=this.studentRuleForm;
                    }
                  }
                }
                  
              }
            }
            this.studentResultSync=false;
              }else {
                this.msgWarn("必填字段不能为空！");
                return false;
              }
      })  
    },
    handleRemove(file, fileList) {
      for (var i=0;i<this.testPapers.length;i++) {
        if (this.testPapers[i].url === file.url) {
            this.testPapers.splice(i,1);
        }
      }
      },
  },
  async beforeRouteUpdate(to, from, next) {
    this.loading = true;
    this.id = to.params.id;
    await this.getSectionList();
    await this.getConstellationList();
    await this.getTeachSpeedList();
    await this.getTeacYearList();
    await this.getTeachStyleList();
    await this.getSubjectList();
    await this.getFollowTagList();
    this.getStudenInfo();
    this.getFollowUpList();
    this.prevAndNextClick("");
    this.getOrderList();
    this.oneToOneListFn();
    this.loading = false;
    next()
  },
  async mounted() {
    const clipboard = new ClipboardJS(".icon_copy_student_info");
      clipboard.on("success", (e) => {
        this.$message.success('复制成功！')
      });
      clipboard.on("error", (e) => {
        this.$message.error('复制失败！')
        console.error('复制失败！', e)
      });
    this.loading = true;
    this.id = this.$route.params.id;
    this.contract = this.$route.params.contract;
    this.statusShow=this.$route.params.show;
    await this.getSectionList();
    await this.getConstellationList();
    await this.getTeachSpeedList();
    await this.getTeacYearList();
    await this.getTeachStyleList();
    await this.getSubjectList();
    await this.getFollowTagList();
    this.getStudenInfo();
    this.getFollowUpList();
    this.prevAndNextClick("");
    this.getOrderList();
    this.oneToOneListFn();
    this.loading = false;
  }
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 0 20px;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    .right {
      flex: 1;
      display: flex;
      height: 50px;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .student_info{
    display: flex;
    padding: 10px 0 0 0;
    .student_left{
      width:400px;
      min-width: 320px;
      .left_dl{
        display: flex;
        align-items: center;
        img{
          width: 100px;
          height: 100px;
          margin-right: 20px;
          border-radius: 50%;
        }
        p:nth-child(1){
          font-size:18px;
          font-weight: 500;
        }
        p:nth-child(2), p:nth-child(3),  p:nth-child(4){
          margin-top:10px;
          font-size:14px;
        }
      }
      .icon_copy_student_info{
        cursor: pointer;
      }
      .infoDiv{
        margin-top:40px;
        span{
          font-size:18px;
        }
        ul{
          margin-top:10px;
        }
        ul li{
          // height:30px;
          margin-left:20px;
          margin-top:12px;
        }
      }
    }
    .student_right{
      flex:1;
      .right_operation{
        display:flex;
        align-items: center;
        justify-content: space-between;
      }
      .right_followUp{
        display:flex;
        align-items: center;
        justify-content: space-between;
        margin-top:15px;
      }
      .teacher_ask{
        display: flex;
        >p{
          width:110px;
          padding-top:10px;
          font-weight: 600;
          font-size:16px;
          margin-left:30px;
        }
        .el_row{
          flex:1;
        }
      }
    }
  }
  .student_info_main {
    margin: 10px 0;
    display: flex;
    background:yellow;
    img {
      width: 100px;
      height: 100px;
      margin-right: 20px;
    }
    .base_info {
      width: 480px;
    }
    .opt {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .label_info {
    display: flex;
    height: 26px;
    line-height: 26px;
    > span:nth-of-type(1) {
      width: 116px;
      text-align: right;
    }
    > span:nth-of-type(2) {
      flex: 1;
    }
  }
  .course_info {
    display: flex;
    height: 40px;
    line-height: 40px;
    > span:nth-of-type(1) {
      width: 80px;
      text-align: right;
    }
    > span:nth-of-type(2) {
      flex: 1;
    }
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .self_input {
    padding-bottom: 4px;
    color: #606266;
    text-align: center;
    width: 90%;
    overflow: hidden;
    outline: none;
    border: none;
    border-bottom: 1px solid #c0c4cc;
  }
  .self_input:focus {
    border-bottom: 1px solid #1890ff;
  }
  .required_subject {
    height:auto;
    margin-bottom: 10px;
  }
  .select_subject,.course_info_see {
    .select_subject_item {
     .item_subject_title span:nth-child(1){
        font-weight: 900;
        padding-left: 32px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
      .item_subject_title span:nth-child(2){
        font-weight: 500;
        font-size: 16px;
        margin-left:20px;
        color:#000;
      }
      .item_score {
        display: flex;
        /deep/ .el-input {
          width: 40%;
        }
        > span {
          width: 20px;
          text-align: center;
        }
      }
    }
  }
  .student_name {
    padding: 10px 0;
    font-size: 24px;
    font-weight: 800;
  }
  .info_title {
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
  }
  .info_time {
    background:pink;
    .class_title {
      height: 30px;
      line-height: 30px;
      text-indent: 12px;
      > span {
        cursor: pointer;
      }
    }
  }
  .table_area {
    margin-left: 20px;
    padding: 10px 4px;
    .class_tip {
      height: 40px;
      line-height: 40px;
      text-indent: 4px;
      span {
        margin-right: 20px;
      }
    }
  }
  .self_table {
    width: 100%;
    border: 1px solid #ebeef5;
    border-collapse: collapse;
    border-spacing: 0px;
    tr {
      border: 1px solid #ebeef5;
      td {
        padding: 10px;
        border: 1px solid #ebeef5;
        text-align: center;
        vertical-align: middle;
        height: 40px;
      }
      .follow_content {
        >>>p{
          margin-top:10px;
        }
        text-align: left;
        > div:nth-of-type(1) {
          // display: flex;
          // height: 80px;
          overflow-y: auto;
        }
        > div:nth-of-type(2) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .follow_info {
        text-align: right;
        font-size: 14px;
        color: #333;
      }
    }
  }
  .active {
    color: #1890ff;
  }
  .dialog-content {
    min-height: 80px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .time_selector {
      width: 260px;
      
    }
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .select-condition-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .item-lable {
      word-break: keep-all;
    }
  }
  .followUp_func {
    display: flex;
    height: 40px;
    align-items: center;
  }
  /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #1890ff;
    border-color: #1890ff;
  }
  /deep/ .el-checkbox.is-disabled.is-checked .el-checkbox__label {
    color: #1890ff;
  }
  .paperUl{
    padding:0 20px;
    flex:1;
  }
  .paperUl li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:13px;
   
    
  }
  .imgDolg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0,0,0, 0.7);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  #imgDolgClose {
    position: fixed;
    top: 35px;
    cursor: pointer;
    right: 7%;
    font-size: 50px;
    color: white;
  }
  img{
    width: 40%;
    display: block;
  }
}
.detail_type {
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color:#000;
    }
     .row_detail {
      padding: 6px 0;
    }
    .row_detail2{
      span{
        font-weight: bold;
        color:#ff3f3f;
      }
    }
}
>>>.td-img .el-icon-circle-close {
    color: white !important;
  }
// .el-picker-panel__footer  .el-button--text.el-picker_link-btn{
//   display: none !important;
// }

 >>>a{text-decoration:none;}
.img_list {
        width: 100%;
        min-height: 120px;
        overflow: auto;
        .img_item {
          position: relative;
          margin: 10px 10px 10px 0px;
          float: left;
          cursor: pointer;
          height: 100px;
          width: 100px;
          border: 1px solid #dddddd;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          .img_hover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 6;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .icon_del {
              margin-left: 10px;
              cursor: pointer;
              color: #ffffff;
            }
            .icon_preview {
              cursor: pointer;
              color: #ffffff;
              font-size: 14px;
            }
          }
          &:hover {
            .img_hover {
              display: flex;
            }
          }
          img {
            width: 100px;
            height: 100px;
          }
          .icon_new {
            font-size: 40px;
          }
        }
      }
</style>
