<template>
  <div class="main-content sys-role-manage">
    <dialog-file-view-private
      :dialogFileViewPrivateInfo="dialogFileViewPrivateInfo"
      @closeFileView="closeFileView"
    ></dialog-file-view-private>
    <div class="btn-area">
      <div class="select-condition-item">
        <span class="item-lable">客户状态：</span>
        <el-radio-group v-model="listParams.contract" @change="contractFn()">
          <el-radio :label="-1">全部</el-radio>
          <el-radio :label="0">首次待跟进</el-radio>
          <el-radio :label="4">跟进中</el-radio>
          <el-radio :label="1">已试听</el-radio>
          <el-radio :label="2">已签约</el-radio>
          <el-radio :label="6">无效客户</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="listParams.sectionId"
            placeholder="请选择"
            size="small"
            @click="() => ((listParams.pageNumber = 1), getStudentList())"
          >
            <el-option
              v-for="item in sectionListSearch"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">分配时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>

        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.keyword"
            placeholder="学生姓名/联系方式"
            size="small"
            style="min-width: 120px"
            @click="() => ((listParams.pageNumber = 1), getStudentList())"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="() => ((listParams.pageNumber = 1), getStudentList())"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">设置时间：</span>
          <el-date-picker
            size="small"
            v-model="intentionTimeValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="intentionTimeChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">意向程度：</span>
          <el-select
            v-model="listParams.intentionId"
            placeholder="请选择"
            size="small"
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in intentionList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!-- <div class="btn-area select-condition">
      <div class="select-condition-item">
          <span class="item-lable">下次跟进时间：</span>
          <el-date-picker
            size="small"
            v-model="nextTimePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="nextPickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
    </div> -->
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="saveApplyClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-lingqu3" />
            </svg>
            <span>领取</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="studentList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="studentListTable"
        @selection-change="handleSelectionChange"
        @row-click="studentItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="no"
          label="编号（添加时间）"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column prop="name" label="姓名（性别）" align="center">
          <template slot-scope="scope">
            <div
              style="text-decoration: underline; cursor: pointer"
              @click="daalDetail(scope.row)"
            >
              {{ scope.row.name }}{{ scope.row.sex == 1 ? "（男）" : "（女）" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="account" label="账号" align="center" width="120">
          <template slot-scope="scope">
            <div
              style="text-decoration: underline; cursor: pointer"
              @click="getBasic(scope.row)"
            >
              {{ scope.row.account }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="sectionName"
          label="年级"
          align="center"
           width="150"
        ></el-table-column> -->
        <el-table-column
          prop="intentionId"
          label="意向程度"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <p>{{ intentionId(scope.row) }}</p>
            <p>{{ scope.row.intentionTime }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="allocateTime"
          label="分配时间"
          align="center"
          width="150"
        ></el-table-column>
        <!-- <el-table-column
          prop="beginTime"
          :formatter="formatterBeginTime"
          label="最近/下次跟进时间"
          align="center"
        >
          <template slot-scope="scope">
            <p>近：{{formatterBeginTime(scope.row)}}</p>
            <p>下：{{formatterSearchBeginTime(scope.row)}}</p>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-show="isShow"
              @click.stop="daalDelete(scope.row)"
              >删除</el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              @click.stop="dealDisable(scope.row)"
              v-show="scope.row.status == 1"
              >禁用</el-button
            >
            <el-button
              type="text"
              size="small"
              @click.stop="dealAble(scope.row)"
              v-show="scope.row.status == 0"
              >启用</el-button
            > -->
            <el-button
              type="text"
              size="small"
              @click.stop="daalSaleBack(scope.row)"
              >释放</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="scope.row.status == 1"
              @click.stop="daalAddOrder(scope.row)"
              >下单</el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              @click.stop="daalDetail(scope.row)"
              >查看详情</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area page-area2">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :visible.sync="saveDialogVisible"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <div class="info-title">学生信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学生姓名" prop="name">
                <el-input v-model="saveRuleForm.name" :maxlength="5"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式" prop="contact">
                <el-input
                  v-model="saveRuleForm.contact"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="性别" prop="sex">
                <el-radio-group v-model="saveRuleForm.sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年级" prop="sectionId">
                <el-select
                  v-model="saveRuleForm.sectionId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sectionList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所在学校" prop="schoolName">
                <el-input v-model="saveRuleForm.schoolName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="学校所在区域" prop="schoolReach">
                <el-input v-model="saveRuleForm.schoolReach"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="来源渠道" prop="sourceType">
                <el-select
                  v-model="saveRuleForm.sourceType"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sourceTypeList2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="孩子性格" prop="childNature">
                <el-input v-model="saveRuleForm.childNature"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="saveRuleForm.sourceType == '5'">
            <el-col :span="12">
              <el-form-item label="转介绍人姓名" prop="referralstudent">
                <el-input
                  v-model="saveRuleForm.referralstudent"
                  :maxlength="5"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="转介绍人电话" prop="referralphone">
                <el-input
                  v-model="saveRuleForm.referralphone"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="出生日期" prop="birthday">
                <el-date-picker
                  v-model="saveRuleForm.birthday"
                  type="date"
                  :default-value="new Date('2003-01-01')"
                  :picker-options="expireTimeOption"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  @change="birthdayChange"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="星座" prop="constellationId">
                <el-select
                  v-model="saveRuleForm.constellationId"
                  placeholder="请选择"
                  size="small"
                  filterable
                  :disabled="true"
                >
                  <el-option
                    v-for="item in constellationList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="家庭住址">
            <el-row type="flex">
              <el-col :span="8">
                <el-select
                  v-model="saveRuleForm.provinceId"
                  placeholder="请选择"
                  size="small"
                  @change="provinceChange"
                >
                  <el-option
                    v-for="item in provinceList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveRuleForm.cityId"
                  placeholder="请选择"
                  size="small"
                  @change="cityChange"
                >
                  <el-option
                    v-for="item in cityList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveRuleForm.countyId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in countyList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="学生其他需求">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              maxlength="500"
              v-model="saveRuleForm.studentsOtherNeeds"
            >
            </el-input>
          </el-form-item>
          <div class="info-title">家长信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="母亲姓名" prop="childMotherName">
                <el-input v-model="saveRuleForm.childMotherName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="母亲电话" prop="childMotherPhone">
                <el-input
                  v-model="saveRuleForm.childMotherPhone"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="父亲姓名" prop="childFatherName">
                <el-input v-model="saveRuleForm.childFatherName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="父亲电话" prop="childFatherPhone">
                <el-input
                  v-model="saveRuleForm.childFatherPhone"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="微信/QQ" prop="parentWechat">
            <el-input v-model="saveRuleForm.parentWechat"></el-input>
          </el-form-item>
          <div class="info-title">空闲时间</div>
          <div class="class_title">首次上课时间</div>
          <div class="table_area">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in firstClassTimeList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status">{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="class_tip">
              <span>上午：8:00-12:00</span>
              <span>下午：12:00-18:00</span>
              <span>晚上：18:00-22:00</span>
            </div>
            <el-form-item label="上课时间备注" prop="firstClassTimeRemark">
              <el-input v-model="saveRuleForm.firstClassTimeRemark"></el-input>
            </el-form-item>
          </div>
          <div class="class_title">正式上课时间</div>
          <div class="table_area">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in formalClassTimeList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status">{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="class_tip">
              <span>上午：8:00-12:00</span>
              <span>下午：12:00-18:00</span>
              <span>晚上：18:00-22:00</span>
            </div>
            <el-form-item label="上课时间备注" prop="formalClassTimeRemark">
              <el-input v-model="saveRuleForm.formalClassTimeRemark"></el-input>
            </el-form-item>
          </div>
          <!-- <div class="open_tip" v-show="saveDialog.type == 'save'" style="display:none;">
            <el-button type="text" @click="isOpen = !isOpen">{{
              isOpen ? "收缩" : "展开"
            }}</el-button>
          </div> -->
          <div class="open_tip" style="display: none">
            <el-button type="text" @click="isOpen = !isOpen">{{
              isOpen ? "收缩" : "展开"
            }}</el-button>
          </div>
          <div v-show="isOpen || saveDialog.type == 'edit'">
            <div class="info-title">课程信息</div>
            <el-tabs stretch>
              <el-tab-pane label="文化课">
                <div class="required_subject left">
                  <span>选择必考科目：</span>
                  <template v-for="item in subjectList">
                    <el-checkbox
                      v-model="item.select"
                      v-show="item.value == 'sys.teach.subject.base'"
                      class="mR10 mT10"
                      :key="item.macroId"
                      >{{ item.name }}</el-checkbox
                    >
                  </template>
                </div>
                <div class="left">
                  <template v-for="item in subjectList">
                    <el-button
                      type="primary"
                      v-model="item.status"
                      v-show="item.value == 'sys.teach.subject.base'"
                      class="mR10 mT10"
                      :key="item.macroId"
                      size="small"
                      :disabled="item.status"
                      @click="item.status = !item.status"
                      >{{ item.name }}</el-button
                    >
                  </template>
                </div>
              </el-tab-pane>
              <el-tab-pane label="小语种">
                <div class="left">
                  <template v-for="item in subjectList">
                    <el-button
                      type="primary"
                      v-model="item.status"
                      v-show="item.value == 'sys.teach.subject.language'"
                      class="mR10"
                      :key="item.macroId"
                      size="small"
                      :disabled="item.status"
                      @click="item.status = !item.status"
                      >{{ item.name }}</el-button
                    >
                  </template>
                </div>
              </el-tab-pane>
              <el-tab-pane label="艺术类">
                <div class="left">
                  <template v-for="item in subjectList">
                    <el-button
                      type="primary"
                      v-model="item.status"
                      v-show="item.value == 'sys.teach.subject.art'"
                      class="mR10"
                      :key="item.macroId"
                      size="small"
                      :disabled="item.status"
                      @click="item.status = !item.status"
                      >{{ item.name }}</el-button
                    >
                  </template>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div class="select_subject">
              <div
                class="select_subject_item"
                v-for="item in subjectList"
                :key="item.macroId"
                v-show="item.status"
              >
                <div class="item_subject_title left">
                  <span>{{ item.name }}</span>
                  <span @click="addStudentResult()">添加学生成绩</span>
                </div>
                <!-- <el-form-item label="科目成绩">
                  <div class="item_score">
                  
                    <el-button
                      type="danger"
                      @click="deleteSubject(item)"
                      class="mL20"
                      >删除</el-button
                    >
                  </div>
                </el-form-item> -->
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="讲课速度">
                      <el-select
                        v-model="item.speedNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachSpeedList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="性别偏好" prop="sexNeedId">
                      <el-select
                        v-model="item.sexNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachSexList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="年龄偏好" prop="ageNeedId">
                      <el-select
                        v-model="item.ageNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachYearList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="风格偏好" prop="styleNeedId">
                      <el-select
                        v-model="item.styleNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachStyleList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-table
                  size="small"
                  :data="studentList"
                  style="width: 100%; margin-bottom: 20px"
                  row-key="id"
                  border
                  height="100%"
                  tooltip-effect="dark"
                  ref="studentListTable"
                >
                  <el-table-column
                    prop="name"
                    label="考试时间"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="contact"
                    label="考试类型"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="sectionName"
                    label="成绩"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="sex"
                    label="总分"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="saleName"
                    label="备注说明"
                    align="center"
                  ></el-table-column>
                  <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                      <el-button type="text" size="small">试卷预览</el-button>
                    </template>
                  </el-table-column>
                </el-table>

                <!-- <el-form-item label="其他要求">
                  <el-input placeholder="请输入内容" v-model="item.content">
                  </el-input>
                </el-form-item> -->
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave('saveRuleForm')"
          size="small"
          :disabled="isDisable"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="领取用户"
      :visible.sync="saveApplyVisible"
      width="400px"
      :close-on-click-modal="false"
      center
    >
      <div class="dialog-content">
        <div class="save_apply_tip">
          今日你可以领取的用户数量为：{{ saveApplyData.allNum }}个
        </div>
        <div class="save_apply">
          <span>您要领取的数量为：</span>
          <el-input
            v-model.number="saveApplyData.saveNum"
            class="save_apply_input"
            size="small"
          ></el-input>
          <span>个</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveApplyVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmSaveApply()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="orderDialog.title"
      :visible.sync="orderDialogVisible"
      :close-on-click-modal="false"
      width="650px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleFormOrder"
          status-icon
          :rules="saveRuleOrder"
          ref="saveRuleFormOrder"
          label-width="90px"
          size="small"
        >
          <el-form-item label="产品类型" prop="orderType">
            <el-row>
              <el-col :span="7">
                <el-select
                  v-model="saveRuleFormOrder.orderType"
                  placeholder="请选择"
                  @change="orderClick"
                  size="small"
                  :disabled="
                    orderDialog.type == 'see' || orderDialog.type == 'edit'
                  "
                >
                  <el-option value="5" label="一对一充值"></el-option>
                  <el-option value="2" label="一对多"></el-option>
                  <el-option value="3" label="试听课"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="课程类型"
            prop="courseTypeId"
            v-show="saveRuleFormOrder.orderType == 2"
          >
            <el-radio-group
              v-model="saveRuleFormOrder.courseTypeId"
              :disabled="orderDialog.type == 'see'"
              @change="orderClick"
            >
              <el-radio label="1">大班课</el-radio>
              <el-radio label="2">小班课</el-radio>
              <el-radio label="3">答疑课</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="选择产品"
            prop="productId"
            v-show="saveRuleFormOrder.orderType != 5"
            :disabled="orderDialog.type == 'see'"
          >
            <el-row>
              <el-col :span="7">
                <el-select
                  v-model="saveRuleFormOrder.productId"
                  placeholder="请选择"
                  @change="productChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productList"
                    :key="item.id"
                    :label="item.productName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="产品规格"
            v-show="saveRuleFormOrder.orderType != 5"
          >
            <el-row type="flex" v-show="saveRuleFormOrder.orderType != 2">
              <el-col :span="5">
                <el-select
                  v-model="saveRuleFormOrder.sectionId"
                  placeholder="请选择学段"
                  @change="orderSectionChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.sectionList"
                    :key="item.sectionId"
                    :label="item.sectionName"
                    :value="item.sectionId"
                  ></el-option>
                </el-select>
              </el-col>

              <el-col :span="5" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.teacherTitleId"
                  placeholder="请选择教师头衔"
                  @change="orderTeacherTitleChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.teacherTitleList"
                    :key="item.teacherTitleId"
                    :label="item.teacherTitleName"
                    :value="item.teacherTitleId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="6" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.classHourId"
                  placeholder="请选择课时"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.classHourList"
                    :key="item.id"
                    :label="item.classHourName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row type="flex" v-show="saveRuleFormOrder.orderType == 2">
              <el-col :span="12">
                <el-select
                  v-model="saveRuleFormOrder.periodId"
                  placeholder="请选择学段"
                  @change="orderSectionChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.periodList"
                    :key="item.periodId"
                    :label="item.periodName"
                    :value="item.periodId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.subjectId"
                  placeholder="请选择学科"
                  @change="orderSubjectChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.subjectList"
                    :key="item.subjectId"
                    :label="item.subjectName"
                    :value="item.subjectId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="mT10"
              v-show="saveRuleFormOrder.orderType == 2"
            >
              <el-col :span="12">
                <el-select
                  v-model="saveRuleFormOrder.teacherTitleId"
                  placeholder="请选择教师头衔"
                  @change="orderTeacherTitleChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.teacherTitleList"
                    :key="item.teacherTitleId"
                    :label="item.teacherTitleName"
                    :value="item.teacherTitleId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.classHourId"
                  placeholder="请选择课时"
                  @change="orderClassHourChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.classHourList"
                    :key="item.id"
                    :label="item.classHourName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="mT10"
              v-show="saveRuleFormOrder.classHourId != -1"
            >
              <el-col :span="12">
                <div class="course_info">
                  <span>课时单价：</span>
                  <span>{{ saveRuleFormOrder.singlePrice }}元</span>
                </div>
              </el-col>
              <el-col :span="12" class="mL20">
                <div class="course_info">
                  <span>总价：</span>
                  <span
                    >{{
                      saveRuleFormOrder.orderType != 2
                        ? saveRuleFormOrder.totalPrice
                        : saveRuleFormOrder.zdyActualPrice
                    }}元</span
                  >
                </div>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="mT20"
              v-show="saveRuleFormOrder.classHourId == -1"
            >
              <el-col :span="8">
                <el-form-item label="课时数：" prop="zdyClassHour">
                  <input
                    type="text"
                    :disabled="orderDialog.type == 'see'"
                    v-model="saveRuleFormOrder.zdyClassHour"
                    class="self_input"
                    @keyup="filterZdyClassHour"
                    onpaste="return false"
                    @blur="zdyClassHourBlur()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="单价：" prop="zdySinglePrice">
                  <input
                    type="text"
                    :disabled="orderDialog.type == 'see'"
                    v-model="saveRuleFormOrder.zdySinglePrice"
                    class="self_input"
                    onpaste="return false"
                    @keyup="fileterZdySinglePrice"
                    @blur="zdySinglePriceBlur()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="总金额：" prop="zdyTotalPrice">
                  <input
                    :disabled="orderDialog.type == 'see'"
                    type="text"
                    v-model="saveRuleFormOrder.zdyTotalPrice"
                    onpaste="return false"
                    @keyup="filterZdyTotalPrice"
                    @blur="zdyTotalPriceBlur"
                    class="self_input"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" v-show="saveRuleFormOrder.classHourId == -1">
              <el-col :span="8">
                <el-form-item label="赠送课时：" prop="zdyGiveClassHour">
                  <input
                    type="text"
                    :disabled="orderDialog.type == 'see'"
                    onpaste="return false"
                    @keyup="
                      saveRuleFormOrder.zdyGiveClassHour =
                        saveRuleFormOrder.zdyGiveClassHour.replace(/[^\d]/g, '')
                    "
                    v-model="saveRuleFormOrder.zdyGiveClassHour"
                    class="self_input"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="优惠金额：" prop="zdyDiscountAmount">
                  <input
                    :disabled="orderDialog.type == 'see'"
                    type="text"
                    v-model="saveRuleFormOrder.zdyDiscountAmount"
                    class="self_input"
                    onpaste="return false"
                    @keyup="filterZdyDiscountAmount"
                    @blur="zdyDiscountAmountBlur()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实际支付：" prop="zdyActualPrice">
                  <input
                    :disabled="orderDialog.type == 'see'"
                    type="text"
                    v-model="saveRuleFormOrder.zdyActualPrice"
                    class="self_input"
                    onpaste="return false"
                    @keyup="filterZdyActualPrice"
                    @blur="zdyActualPriceBlur"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="缴费金额"
            prop="totalPrice"
            v-show="saveRuleFormOrder.orderType == 5"
          >
            <el-input
              style="width: 140px"
              @blur="totalPriceBlur()"
              v-model="saveRuleFormOrder.totalPrice"
              placeholder="请输入缴费金额"
            ></el-input>
            <span style="color: #909399; font-size: 12px; margin-left: 10px">{{
              translateTotal
            }}</span>
          </el-form-item>
          <el-form-item
            label="赠送课时"
            prop="zdyGiveClassHourPay"
            v-show="
              saveRuleFormOrder.orderType == 5 &&
              saveRuleFormOrder.moneyType != 4
            "
          >
            <el-input
              style="width: 140px"
              min="0"
              type="number"
              v-model="saveRuleFormOrder.zdyGiveClassHour"
              placeholder="请输入赠送课时"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="优惠金额"
            prop="payType"
            v-show="saveRuleFormOrder.orderType == 2"
          >
            <el-input
              style="width: 140px"
              @blur="morenAmount()"
              v-model="saveRuleFormOrder.zdyDiscountAmount"
            ></el-input>
          </el-form-item>
          <el-form-item label="支付方式" prop="payType">
            <el-radio-group
              v-model="saveRuleFormOrder.payType"
              :disabled="orderDialog.type == 'see'"
            >
              <el-radio label="2">线下支付</el-radio>
              <el-radio label="3">余额支付</el-radio>
              <!-- <el-radio label="1">线上支付</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="收款方式"
            prop="payMent"
            v-show="
              (saveRuleFormOrder.orderType == 5 ||
                saveRuleFormOrder.orderType == 2 ||
                saveRuleFormOrder.orderType == 3) &&
              saveRuleFormOrder.payType == 2
            "
          >
            <el-radio-group
              v-model="saveRuleFormOrder.payMent"
              :disabled="orderDialog.type == 'see'"
            >
              <el-radio label="1">支付宝</el-radio>
              <el-radio label="2">微信</el-radio>
              <el-radio label="3">对公账户</el-radio>
              <el-radio label="4">POS机</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="收款类型"
            prop="moneyType"
            v-show="saveRuleFormOrder.orderType != 3"
          >
            <el-radio-group
              v-model="saveRuleFormOrder.moneyType"
              :disabled="orderDialog.type == 'see'"
            >
              <el-radio label="1">全款</el-radio>
              <el-radio label="2">定金</el-radio>
              <el-radio label="3">尾款</el-radio>
              <el-radio label="4">特殊订单</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="付款凭证"
            prop="certificate"
            v-show="saveRuleFormOrder.payType == 2"
          >
            <el-row align="middle" type="flex">
              <el-col :span="12">
                <div class="certificate_box">
                  <el-image
                    class="td-img"
                    style="width: 100px; height: 100px"
                    :src="saveRuleFormOrder.certificate"
                    v-show="saveRuleFormOrder.certificate"
                    :preview-src-list="srcList"
                  >
                  </el-image>
                </div>
                <div class="certificate_tip">
                  <span v-show="saveRuleFormOrder.certificate"
                    >点击图片查看大图</span
                  >
                  <span v-show="!saveRuleFormOrder.certificate"
                    >暂未上传凭证</span
                  >
                </div>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  :action="fileUpload"
                  :on-success="certificateSuccess"
                  :on-error="certificateError"
                  :before-upload="beforeAvatarUpload"
                  :show-file-list="false"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-button
                    size="small"
                    type="primary"
                    :disabled="orderDialog.type == 'see'"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="辅导协议" prop="coachAgreement">
            <el-row align="middle" type="flex">
              <el-col :span="12">
                <div class="coachAgreement_tip">
                  <a
                    target="view_window"
                    :href="saveRuleFormOrder.coachAgreement"
                    >{{ saveRuleFormOrder.coachAgreementName }}</a
                  >
                  <!-- <span v-show="saveRuleFormOrder.coachAgreement">{{
                    saveRuleFormOrder.coachAgreement
                  }}</span> -->
                  <span v-show="!saveRuleFormOrder.coachAgreement"
                    >还未上传辅导协议</span
                  >
                </div>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  :action="fileUpload"
                  :on-success="coachAgreementSuccess"
                  :on-error="coachAgreementError"
                  :show-file-list="false"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-button
                    size="small"
                    type="primary"
                    :disabled="orderDialog.type == 'see'"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-row>
              <el-col :span="15">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="saveRuleFormOrder.remark"
                >
                </el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        v-show="orderDialog.type != 'see'"
      >
        <el-button @click="orderDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          :disabled="fullscreenLoading"
          type="primary"
          @click="confirmSaveOrder('saveRuleFormOrder')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="studentResultDialog.title"
      :visible.sync="studentResultSync"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="studentRuleForm"
          status-icon
          :rules="studentResultRules"
          ref="studentRuleForm"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="考试时间" prop="examinationTime">
                <el-date-picker
                  class="time_selector"
                  v-model="studentRuleForm.examinationTime"
                  type="date"
                  :picker-options="expireTimeOptionBefore"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="考试类型" prop="examinationType">
                <el-input v-model="studentRuleForm.examinationType"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="成绩" prop="subjectScore">
                <el-input v-model="studentRuleForm.subjectScore"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总分" prop="subjectAllScore">
                <el-input v-model="studentRuleForm.subjectAllScore"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="备注说明" prop="remarks">
                <el-input v-model="studentRuleForm.remarks"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="试卷上传" prop="testPapers">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  :action="fileUpload"
                  :file-list="testPapers"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :limit="8"
                  :on-success="handleAvatarSuccess"
                  ><i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="studentResultSync = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmStudent('studentRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog
      width="700px"
      center
      @close="closeSaveDialog"
      title="基本信息"
      :close-on-click-modal="false"
      :visible.sync="basicDialogVisible"
    >
      <div class="cut_div">
        <el-button
          type="primary"
          icon="el-icon-arrow-left"
          size="small"
          @click="cutFn('next')"
          :disabled="!nextId"
        ></el-button>
        <el-button
          type="primary"
          icon="el-icon-arrow-right"
          size="small"
          @click="cutFn('prev')"
          :disabled="!prevId"
        ></el-button>
      </div>
      <div class="dialog-content">
        <dl class="content_dl">
          <dt><img :src="studentInfo.photo" alt="" /></dt>
          <dd>
            <h2>
              学生姓名：{{ studentInfo.name }}
              <i
                class="el-icon-user-solid"
                v-show="studentInfo.sex == 1"
                style="color: #1890ff"
              ></i>
              <i
                class="el-icon-user-solid"
                v-show="studentInfo.sex == 2"
                style="color: pink"
              ></i>
              <span
                @click="seeWhole"
                style="
                  font-size: 14px;
                  color: #1890ff;
                  cursor: pointer;
                  margin-left: 15px;
                "
                >查看全部</span
              >
            </h2>
            <p style="font-size: 18px; margin-top: 15px; font-weight: 700">
              联系方式：{{
                studentInfo.contact ? studentInfo.contact : "暂无数据"
              }}
            </p>
            <div>
              <span
                >编号：{{ studentInfo.no ? studentInfo.no : "暂无数据" }}</span
              >
              <!-- <span
                >联系方式：{{
                  studentInfo.contact ? studentInfo.contact : "暂无数据"
                }}</span
              > -->
              <span
                >年级：{{
                  studentInfo.sectionName ? studentInfo.sectionName : "暂无数据"
                }}</span
              >
              <span
                >来源渠道：{{ studentSourceType(studentInfo.sourceType) }}</span
              >
              <span
                >导入方式：{{ studentImportType(studentInfo.importType) }}</span
              >
              <span
                >星座：{{
                  studentInfo.constellationName
                    ? studentInfo.constellationName
                    : "暂无数据"
                }}</span
              >
              <p>所在学校：{{ studentInfo.schoolName }}</p>
            </div>
          </dd>
        </dl>
        <ul class="content_ul">
          <li>
            <span>出生日期：{{ studentInfo.birthday }}</span>
            <span
              >家庭住址：{{
                studentInfo.countyName
                  ? studentInfo.provinceName +
                    studentInfo.cityName +
                    studentInfo.countyName
                  : "暂无数据"
              }}</span
            >
          </li>
          <li>
            <span
              >孩子性格：{{
                studentInfo.childNature ? studentInfo.childNature : "暂无数据"
              }}</span
            >
            <span
              >微信/qq：{{
                studentInfo.parentWechat ? studentInfo.parentWechat : "暂无数据"
              }}</span
            >
          </li>
          <li>
            <span
              >孩子母亲：{{
                studentInfo.childMotherName ? studentInfo.childMotherName : ""
              }}{{
                studentInfo.childMotherPhone ? studentInfo.childMotherPhone : ""
              }}</span
            ><span
              >孩子父亲：{{
                studentInfo.childFatherName ? studentInfo.childFatherName : ""
              }}{{
                studentInfo.childFatherPhone ? studentInfo.childFatherPhone : ""
              }}</span
            >
          </li>
        </ul>
        <el-form
          :model="customerFormOrder"
          status-icon
          :rules="customerRuleOrder"
          ref="customerFormOrder"
          label-width="100px"
          size="small"
          style="margin-top: 20px"
        >
          <el-form-item label="有效客户" prop="intentionId">
            <el-radio-group v-model="customerFormOrder.intentionId">
              <el-radio label="265">有效客户</el-radio>
            </el-radio-group>
            <el-button type="primary" @click="addFollowUp()"
              >添加跟进记录</el-button
            >
          </el-form-item>
          <el-form-item label="无效客户" prop="intentionId">
            <el-radio-group v-model="customerFormOrder.intentionId">
              <el-radio label="262">未接通</el-radio>
              <el-radio label="263">号码有误</el-radio>
              <el-radio label="264">不是学生</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="basicDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          :disabled="customerDisabled"
          @click="customerSaveOrder('customerFormOrder')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="新增跟进记录"
      :visible.sync="followUpDialogVisible"
      :close-on-click-modal="false"
      width="900px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleFormFollowUp"
          status-icon
          :rules="saveRuleFollowUp"
          ref="saveRuleFormFollowUp"
          label-width="110px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="下次跟进时间" prop="nextFollowupTime">
                <el-date-picker
                  class="time_selector"
                  v-model="saveRuleFormFollowUp.nextFollowupTime"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  :picker-options="expireTimeOptionBefore"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="客户意向程度" prop="intentionId">
                <el-select
                  v-model="saveRuleFormFollowUp.intentionId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in intentionIdList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="跟进记录" prop="followContent">
              <el-input
                type="textarea"
                :rows="3"
                maxlength="500"
                show-word-limit
                placeholder="请输入内容"
                v-model="saveRuleFormFollowUp.followContent"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="图片记录" prop="photo">
              <div class="img_list">
                <div
                  class="img_item img_item_true"
                  v-for="(item, index) in photoList"
                  :key="index"
                >
                  <div class="img_hover">
                    <span
                      class="iconfont icon-yulan1 icon_preview"
                      title="预览"
                      @click="imgItemSeeClick(item, index)"
                    ></span>
                    <span
                      class="iconfont icon-shanchu4 icon_del"
                      title="删除"
                      @click="imgItemDelClick(index)"
                    ></span>
                  </div>
                  <img :src="item.url" alt="" />
                </div>
                <div class="img_item" @click="imgSelectClick">
                  <span class="iconfont icon-danyehuaban icon_new"></span>
                  <input
                    type="file"
                    ref="uploadImg"
                    @change="imgSelectChange"
                    style="display: none"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                  />
                </div>
              </div>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="followUpDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="followUpSaveFollowUp('saveRuleFormFollowUp')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <downOrder
      ref="downOrder"
      :downOrderStatus="downOrderStatus"
      :downParams="downParams"
      @CB_downOrderStatus="CB_downOrderStatus"
    ></downOrder>
  </div>
</template>

<script>
import headPng from "@/assets/image/head.png";
import {
  productListNoPage,
  productInfoDetail,
} from "@/api/operates/productManage";
import {
  studentUpdate,
  deleteStudentToken,
  studentDelete,
  listNoPage,
  studentImport,
  getSaleId,
  studentSave,
  studentInfo,
  infoByParentCodeAndValue,
  reapportionList,
  saleBack,
  academicrecordSave, //学生添加试卷信息接口
  getObjectById, //学生添加试卷信息接口
  ifEffectiveBaseStudent,
} from "@/api/sales/saleManage";
import { systemAreaList } from "@/api/system/systemManage";
import {
  studentProcessList as studentList,
  getAvliableNum,
  saleApply,
  checkOneToOneOrder,
  addOrder,
  followupSave,
  followUpUpdate,
  prevAndNext,
} from "@/api/sales/myCustomer";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  fileUpload,
  constellation,
  reg_Num,
} from "@/api/public";
import { fileUploads } from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import dayjs from "dayjs";
import dialogFileViewPrivate from "../../teachBusiness/expertTeachBusiness/chat/dialog-file-view-private.vue";
import downOrder from "../../teachBusiness/expertTeachBusiness/dialog/studentManageFile/downOrder.vue";

let id = 0;
export default {
  inject: ["reload"],
  components: {
    dialogFileViewPrivate,
    downOrder,
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log(from,'from... ')
  //   if (from.name == 'confirm') {
  //   to.meta.isBack = true;
  // }
  //   next();
  // },
  //  beforeRouteLeave(to, from, next) {
  //       console.log(to,from,'1223....')
  //       if (to.name == "myCustomer_studentInfo") {
  //             from.meta.keepAlive=true;
  //             from.meta.isBack = true;
  //         next();
  //       } else {
  //         to.meta.isBack = false;
  //         from.meta.isBack = false;
  //         from.meta.keepAlive = false;
  //         to.meta.keepAlive = false;
  //         this.$destroy();
  //         next();
  //       }
  //     },
  //     activated() {
  //       if (!this.$route.meta.isBack) {
  //         this.studentList = [];
  //         this.basicDialogVisible=false;
  //         this.listParams={
  //           isRelease: 0,
  //           beginDate: null,
  //           endDate: null,
  //           nextFollowupBeginTime:null,
  //           nextFollowupEndTime:null,
  //           pageNumber: 1,
  //           pageSize: 10,
  //           sectionId: "",
  //           keyword: "",
  //           sourceType: "",
  //           intentionId:[],
  //           contract: -1, // -1全部 0首次待跟进  1跟进中 2已试听  3已签约
  //         }
  //         this.getStudentList();
  //       }
  //       this.$route.meta.isBack = false;
  //     },
  data() {
    let checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/;
      // 当数据为空时，不进行校验
      if (!value) {
        return callback();
      }
      setTimeout(() => {
        if (phoneReg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的手机号码"));
        }
      }, 100);
    };
    let check_zdyClassHour = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback(new Error("自定义课时数不能为空！"));
        } else if (!reg_Num(2).test(value)) {
          callback(new Error("自定义课时数应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdySinglePrice = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback(new Error("自定义课时单价不能为空！"));
        } else if (!reg_Num(4).test(value)) {
          callback(new Error("自定义课时单价应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdyGiveClassHour = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback();
        } else if (!reg_Num(2).test(value)) {
          callback(new Error("赠送课时数应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    let check_zdyDiscountAmount = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback();
        } else if (!reg_Num(4).test(value)) {
          callback(new Error("优惠金额应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdyActualPrice = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback(new Error("课时总价不能为空！"));
        } else if (!reg_Num(4).test(value)) {
          callback(new Error("课时总价应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_certificate = (rule, value, callback) => {
      if (this.saveRuleFormOrder.payType == 2) {
        if (!value) {
          callback(new Error("线下支付时付款凭证不能为空！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_productId = (rule, value, callback) => {
      if (this.saveRuleFormOrder.orderType != 5) {
        if (!value) {
          callback(new Error("产品不能为空！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_totalPrice = (rule, value, callback) => {
      if (
        this.saveRuleFormOrder.orderType == 5 &&
        this.saveRuleFormOrder.moneyType != 4
      ) {
        var reg = /^\d+$|^\d+[.]?\d+$/;
        if (!value) {
          callback(new Error("缴费金额不能为空！"));
        } else if (!reg.test(value)) {
          callback(new Error("请输入正确格式金额！"));
        } else {
          callback();
        }
      } else if (
        this.saveRuleFormOrder.orderType == 5 &&
        this.saveRuleFormOrder.moneyType == 4
      ) {
        var regF = /^(^-?\d+$)$/;
        if (!value) {
          callback(new Error("缴费金额不能为空！"));
        } else if (!regF.test(value)) {
          callback(new Error("请输入正确格式金额！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      downOrderStatus: false,
      downParams: {
        id: "",
        type: "",
      },
      photoList: [],
      customerDisabled: false,
      cutId: "",
      prevId: "",
      nextId: "",
      srcList: [],
      newStudentId: "",
      newSaleId: "",
      content_height: "160",
      intentionIdList: [
        {
          value: "1",
          label: "A意向较强",
        },
        {
          value: "2",
          label: "B意向简单",
        },
        {
          value: "3",
          label: "C了解阶段",
        },
        {
          value: "4",
          label: "D无意向",
        },
      ],
      saveRuleFollowUp: {
        // nextFollowupTime: [
        //   { required: true, message: "下次跟进时间不能为空", trigger: "blur" }
        // ],
        intentionId: [
          { required: true, message: "客户意向程度不能为空", trigger: "blur" },
        ],
        followContent: [
          { required: true, message: "跟进记录不能为空", trigger: "blur" },
        ],
      },
      followUpDialogVisible: false,
      saveRuleFormFollowUp: {
        nextFollowupTime: "",
        intentionId: "",
        followContent: "",
      },
      customerFormOrder: {
        intentionId: "",
      },
      translateTotal: "",
      customerRuleOrder: {},
      studentInfo: {},
      headPng,
      cutIds: "",
      fileUpload,
      isShow: false,
      saleData: {
        saleId: "",
        saleName: "",
        record: true,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs().subtract(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(90, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      timePickerValue: "",
      intentionTimeValue: "",
      studentList: [],
      listParams: {
        isRelease: 0,
        beginDate: null,
        endDate: null,
        nextFollowupBeginTime: null,
        nextFollowupEndTime: null,
        pageNumber: 1,
        pageSize: 10,
        sectionId: "",
        keyword: "",
        sourceType: "",
        intentionId: [],
        contract: -1, // -1全部 0首次待跟进  1跟进中 2已试听  3已签约
      },
      sourceList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "公共池",
        },
        {
          value: "2",
          label: "自主登录",
        },
        {
          value: "3",
          label: "销售添加",
        },
        {
          value: "4",
          label: "教务添加",
        },
      ],
      intentionList: [
        {
          value: "",
          label: "全部",
          disabled: true,
        },
        {
          value: 1,
          label: "意向较强",
        },
        {
          value: 2,
          label: "意向简单",
        },
        {
          value: 3,
          label: "了解阶段",
        },
        {
          value: 4,
          label: "无意向",
        },
      ],
      sourceType: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "地推活动",
        },
        {
          value: "2",
          label: "线上活动",
        },
        {
          value: "3",
          label: "家长转介绍",
        },
      ],
      constellationList: [],
      tagsTotal: 0,
      total: 0,
      sourceTypeList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "地推活动",
        },
        {
          value: "2",
          label: "网络活动",
        },
        {
          value: "3",
          label: "美团活动",
        },
        {
          value: "4",
          label: "A级客户",
        },
        {
          value: "5",
          label: "家长转介绍",
        },
      ],
      holdTotalPrice: "",
      saveDialogVisible: false,
      basicDialogVisible: false,
      isDisable: false,
      saveRuleForm: {
        name: "",
        contact: "",
        sex: "1",
        sectionId: "",
        birthday: "",
        provinceId: "",
        cityId: "",
        countyId: "",
        schoolName: "",
        sourceType: "",
        areaId: "",
        constellationId: "",
        childNature: "",
        childMotherName: "",
        childMotherPhone: "",
        childFatherName: "",
        childFatherPhone: "",
        parentWechat: "",
        firstClassTime: "",
        firstClassTimeRemark: "",
        formalClassTime: "",
        formalClassTimeRemark: "",
        yuwen: 0,
        shuxue: 0,
        yingyu: 0,
        lishi: 0,
        zhengzhi: 0,
        dili: 0,
        wuli: 0,
        huaxue: 0,
        shengwu: 0,
        qitaxueke: 0,
        xmtStudentSubjectRelationEntityList: [],
      },
      firstClassTimeList: [
        [
          {
            name: "上午",
            key: "a",
            status: false,
          },
          {
            name: "上午",
            key: "b",
            status: false,
          },
          {
            name: "上午",
            key: "c",
            status: false,
          },
          {
            name: "上午",
            key: "d",
            status: false,
          },
          {
            name: "上午",
            key: "e",
            status: false,
          },
          {
            name: "上午",
            key: "f",
            status: false,
          },
          {
            name: "上午",
            key: "g",
            status: false,
          },
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false,
          },
          {
            name: "下午",
            key: "i",
            status: false,
          },
          {
            name: "下午",
            key: "j",
            status: false,
          },
          {
            name: "下午",
            key: "k",
            status: false,
          },
          {
            name: "下午",
            key: "l",
            status: false,
          },
          {
            name: "下午",
            key: "m",
            status: false,
          },
          {
            name: "下午",
            key: "n",
            status: false,
          },
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false,
          },
          {
            name: "晚上",
            key: "p",
            status: false,
          },
          {
            name: "晚上",
            key: "q",
            status: false,
          },
          {
            name: "晚上",
            key: "r",
            status: false,
          },
          {
            name: "晚上",
            key: "s",
            status: false,
          },
          {
            name: "晚上",
            key: "t",
            status: false,
          },
          {
            name: "晚上",
            key: "u",
            status: false,
          },
        ],
      ],
      formalClassTimeList: [
        [
          {
            name: "上午",
            key: "a",
            status: false,
          },
          {
            name: "上午",
            key: "b",
            status: false,
          },
          {
            name: "上午",
            key: "c",
            status: false,
          },
          {
            name: "上午",
            key: "d",
            status: false,
          },
          {
            name: "上午",
            key: "e",
            status: false,
          },
          {
            name: "上午",
            key: "f",
            status: false,
          },
          {
            name: "上午",
            key: "g",
            status: false,
          },
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false,
          },
          {
            name: "下午",
            key: "i",
            status: false,
          },
          {
            name: "下午",
            key: "j",
            status: false,
          },
          {
            name: "下午",
            key: "k",
            status: false,
          },
          {
            name: "下午",
            key: "l",
            status: false,
          },
          {
            name: "下午",
            key: "m",
            status: false,
          },
          {
            name: "下午",
            key: "n",
            status: false,
          },
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false,
          },
          {
            name: "晚上",
            key: "p",
            status: false,
          },
          {
            name: "晚上",
            key: "q",
            status: false,
          },
          {
            name: "晚上",
            key: "r",
            status: false,
          },
          {
            name: "晚上",
            key: "s",
            status: false,
          },
          {
            name: "晚上",
            key: "t",
            status: false,
          },
          {
            name: "晚上",
            key: "u",
            status: false,
          },
        ],
      ],
      saveRules: {
        sectionId: [
          { required: true, message: "年级不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "学生姓名不能为空", trigger: "blur" },
        ],
        contact: [
          {
            validator: (rule, value, callback) => {
              let mobile = /^1[3|4|5|6|7|8|9]\d{9}$/;
              let phone = /^0\d{2,3}-?\d{7,8}$/;
              if (!value) {
                callback();
              } else if (!mobile.test(value) && !phone.test(value)) {
                callback(new Error("请输入正确手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        referralphone: [
          {
            validator: checkPhone,
            message: "请输入正确的手机号码",
          },
        ],
        childMotherPhone: [
          {
            validator: checkPhone,
            message: "请输入正确的手机号码",
          },
        ],
        childFatherPhone: [
          {
            validator: checkPhone,
            message: "请输入正确的手机号码",
          },
        ],
      },
      provinceList: [],
      cityList: [],
      countyList: [],
      selectStudentList: [],
      selectStudentListObj: [],
      sectionList: [],
      sectionListSearch: [{ macroId: "", name: "全部" }],
      saleList: [],
      saleParams: {
        roleSign: "sales",
        status: 1,
      },
      saveApplyVisible: false,
      selectFile: [],
      parentId: "0",
      areaId: "",
      saveDialog: {
        title: "新增学生",
        type: "save",
      },
      dialogFileViewPrivateInfo: {
        show: false,
        type: "img",
        src: "",
      },
      subjectList: [],
      teachSpeedList: [],
      teachYearList: [],
      teachStyleList: [],
      sourceTypeList2: [
        {
          value: "1",
          label: "地推活动",
        },
        {
          value: "2",
          label: "网络活动",
        },
        {
          value: "3",
          label: "美团活动",
        },
        {
          value: "4",
          label: "A级客户",
        },
        {
          value: "5",
          label: "家长转介绍",
        },
      ],
      teachSexList: [
        {
          value: 1,
          label: "男老师",
        },
        {
          value: 2,
          label: "女老师",
        },
        {
          value: 3,
          label: "均可",
        },
      ],
      curOptStudent: {},
      isOpen: false,
      saveApplyData: {
        allNum: 0,
        saveNum: "",
      },
      nextTimePickerValue: "",
      //当选择自定义课时时，销售人员可以自己设置课时、单价、赠送课时、优惠金额等；注：课时X单价-优惠金额=总价
      saveRuleFormOrder: {
        orderType: "5", //1一对一  2一对多  3试听课
        payType: "2", //1线上支付   2线下支付
        courseTypeId: "1", //1大班课  2小班课  3答疑课
        productId: "",
        certificate: "", //付款凭证
        coachAgreement: "", //辅导协议
        sectionId: "", //学段
        subjectId: "",
        teacherTitleId: "",
        classHour: "",
        classHourId: "", //不用传到后台
        singlePrice: "",
        totalPrice: "",
        zdyClassHour: "",  // 自定义课时数
        zdySinglePrice: "",  // 自定义单价
        zdyGiveClassHour: 0,  // 自定义赠送课时 
        zdyDiscountAmount: "",  // 自定义优惠金额
        zdyActualPrice: "",  // 自定义实际支付
        zdyTotalPrice: "", //  自定义总金额
        studentId: this.id,
        productPackageId: "",
        remark: "",
        payMent: "1", //1支付宝  2微信  3对公账户  4pos机
        moneyType: "1", //1全款   2定金  3尾款
      },
      saveRuleOrder: {
        zdyClassHour: [{ validator: check_zdyClassHour, trigger: "blur" }],
        zdySinglePrice: [{ validator: check_zdySinglePrice, trigger: "blur" }],
        zdyGiveClassHour: [
          { validator: check_zdyGiveClassHour, trigger: "blur" },
        ],

        zdyDiscountAmount: [
          { validator: check_zdyDiscountAmount, trigger: "blur" },
        ],
        zdyActualPrice: [{ validator: check_zdyActualPrice, trigger: "blur" }],
        certificate: [{ validator: check_certificate, trigger: "change" }],
        productId: [{ validator: check_productId, trigger: "change" }],
        totalPrice: [{ validator: check_totalPrice, trigger: "blur" }],
      },
      productList: [],
      orderDialog: {
        title: "下单",
        type: "save",
      },
      orderDialogVisible: false,
      productDetail: {
        productPackage: [],
        sectionList: [],
        sectionIdList: [],
        subjectList: [],
        subjectIdList: [],
        teacherTitleList: [],
        teacherTitleIdList: [],
        classHourList: [],
        classHourIdList: [],
      },
      id: "",
      fullscreenLoading: false,
      studentResultSync: false,
      studentResultDialog: {
        title: "添加试卷信息",
        type: "add",
      },
      expireTimeOption: {
        disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() > Date.now();
        },
      },
      expireTimeOptionBefore: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      studentResultRules: {
        examinationTime: [
          { required: true, message: "考试时间不能为空", trigger: "blur" },
        ],
        subjectScore: [
          { required: true, message: "成绩不能为空", trigger: "blur" },
        ],
      },
      studentRuleForm: {
        examinationType: "", //考试类型,
        subjectScore: "", //成绩
        subjectAllScore: "", //总分
        examinationTime: "", //考试时间
        remarks: "", //备注说明
      },
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      testPapers: [], //存放试卷图片

      // studentList: [],
    };
  },
  watch: {
    saveDialogVisible: function (val, oldVla) {
      if (this.$refs["saveRuleForm"] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    },
  },
  methods: {
    imgItemSeeClick(item, index) {
      this.dialogFileViewPrivateInfo = {
        show: true,
        type: "img",
        src: item.fileUrl ? item.fileUrl : item.url,
        curIndex: index ? index : 0,
        imgList:
          index != undefined
            ? this.photoList.map((item) => {
                return item.fileUrl ? item.fileUrl : item.url;
              })
            : [item.fileUrl ? item.fileUrl : item.url],
      };
    },
    morenAmount() {
      this.saveRuleFormOrder.zdyActualPrice =
        Number(this.saveRuleFormOrder.zdyTotalPrice) -
        Number(this.saveRuleFormOrder.zdyDiscountAmount);
    },
    //缴费金额翻译
    totalPriceBlur() {
      var totalPriceNum = this.saveRuleFormOrder.totalPrice;
      this.numberToString(totalPriceNum);
    },
    numberToString(n) {
      if (!/^(0|\-*[1-9]\d*)(\.\d+)?$/.test(n)) return "数据非法";
      var fuhao = n.toString().indexOf("-") == 0 ? "负" : "";

      var unit = "千百拾亿千百拾万千百拾元角分",
        str = "";
      n += "00";
      //如果是负数就就截取
      if (fuhao == "负") {
        n = n.substring(1, n.length);
      }
      var p = n.indexOf(".");
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++)
        str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i);
      this.translateTotal =
        fuhao +
        str
          .replace(/零(千|百|拾|角)/g, "零")
          .replace(/(零)+/g, "零")
          .replace(/零(万|亿|元)/g, "$1")
          .replace(/(亿)万|壹(拾)/g, "$1$2")
          .replace(/^元零?|零分/g, "")
          .replace(/元$/g, "元整");
    },
    imgItemDelClick(index) {
      this.photoList.splice(index, 1);
    },
    imgSelectClick() {
      this.$refs.uploadImg.value = "";
      this.$refs.uploadImg.click();
    },
    imgSelectChange() {
      if (this.photoList.length == 8) {
        this.msgWarn("最多只能上传八张图片！");
        return;
      }
      if (
        !/\.(png|jpg|gif|jpeg|webp)$/.test(this.$refs.uploadImg.files[0].name)
      ) {
        this.msgWarn("只能是图片格式的文件！");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.uploadImg.files[0]);
      fileUploads(formData).then((uploadRes) => {
        if (uploadRes[0].url) {
          // let index = uploadRes[0].originalName.lastIndexOf(".");
          // let extname = uploadRes[0].originalName.substring(index + 1);
          this.photoList.push({
            // name: uploadRes[0].originalName,
            url: uploadRes[0].url,
          });
        }
      });
    },
    confirmSaveApply() {
      saleApply({
        selectNewUserNumCount: this.saveApplyData.saveNum,
        dailyActualReceiveUsers: this.saveApplyData.allNum,
      }).then((res) => {
        if (res.code == 0) {
          if (res.msg == "超过今日可领取最大数量") {
            this.msgWarn(res.msg);
          } else {
            this.msgSuccess(res.rows);
            this.saveApplyVisible = false;
            this.getStudentList();
          }
        }
      });
    },
    daalSaleBack(row) {
      this.$confirm("确定要释放吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        saleBack({ ids: [row.id] })
          .then((res) => {
            if (res.code == 0) {
              this.msgSuccess("释放成功！");
              this.getStudentList();
            }
          })
          .catch(() => {});
      });
    },
    deleteSubject(item) {
      item.status = false;
      item.subjectScore = "";
      item.subjectAllScore = "";
      item.sexNeedId = "";
      item.styleNeedId = "";
      item.speedNeedId = "";
      item.ageNeedId = "";
    },
    getTeachSpeedList() {
      getSelectList({
        parentCode: teachSpeed,
      }).then((res) => {
        if (res.code == 0) {
          this.teachSpeedList = res.rows;
        }
      });
    },
    getTeacYearList() {
      getSelectList({
        parentCode: teachYear,
      }).then((res) => {
        if (res.code == 0) {
          this.teachYearList = res.rows;
        }
      });
    },
    getConstellationList() {
      getSelectList({
        parentCode: constellation,
      }).then((res) => {
        if (res.code == 0) {
          this.constellationList = res.rows;
        }
      });
    },
    getTeachStyleList() {
      getSelectList({
        parentCode: teachStyle,
      }).then((res) => {
        if (res.code == 0) {
          this.teachStyleList = res.rows;
        }
      });
    },
    getProvinceList() {
      return systemAreaList({
        parentId: this.parentId,
      }).then((res) => {
        if (res.code == 0) {
          this.provinceList = res.rows;
        }
      });
    },
    getCityList() {
      return systemAreaList({
        parentId: this.saveRuleForm.provinceId,
      }).then((res) => {
        if (res.code == 0) {
          this.cityList = res.rows;
        }
      });
    },
    getCountyList() {
      return systemAreaList({
        parentId: this.saveRuleForm.cityId,
      }).then((res) => {
        if (res.code == 0) {
          this.countyList = res.rows;
        }
      });
    },
    async provinceChange() {
      await this.getCityList();
      this.saveRuleForm.cityId = this.cityList[0].areaId;
      this.cityChange();
    },
    async cityChange() {
      await this.getCountyList();
      this.saveRuleForm.countyId = this.countyList[0].areaId;
    },
    getSectionList() {
      getSelectList({
        parentCode: sectionCode,
      }).then((res) => {
        if (res.code == 0) {
          this.sectionList = res.rows;
          for (var i = 0; i < res.rows.length; i++) {
            this.sectionListSearch.push(res.rows[i]);
          }
        }
      });
    },
    filterZdyClassHour() {
      // 课时大于等于1的正整数
      this.saveRuleFormOrder.zdyClassHour =
        this.saveRuleFormOrder.zdyClassHour.replace(/[^\d]/g, "");
      if (this.saveRuleFormOrder.zdyClassHour.length == 1) {
        this.saveRuleFormOrder.zdyClassHour =
          this.saveRuleFormOrder.zdyClassHour.replace(/[^1-9]/gi, "");
      } else if (this.saveRuleFormOrder.zdyClassHour.length > 1) {
        this.saveRuleFormOrder.zdyClassHour =
          this.saveRuleFormOrder.zdyClassHour.replace(/^0/, "");
      }
    },
    fileterZdySinglePrice() {
      this.saveRuleFormOrder.zdySinglePrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdySinglePrice
      );
    },
    filterZdyDiscountAmount() {
      this.saveRuleFormOrder.zdyDiscountAmount = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyDiscountAmount
      );
    },
    filterZdyTotalPrice() {
      this.saveRuleFormOrder.zdyTotalPrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyTotalPrice
      );
    },
    filterZdyActualPrice() {
      this.saveRuleFormOrder.zdyActualPrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyActualPrice
      );
    },
    zdyClassHourBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdyClassHour) {
        // 处理单价和总价
        if (zdySinglePrice) {
          zdyTotalPrice = zdyClassHour * zdySinglePrice;
        } else if (zdyTotalPrice) {
          zdySinglePrice =
            Math.round((zdyTotalPrice / zdyClassHour) * 100) / 100;
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
        zdyTotalPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    closeSaveDialog() {
      this.customerDisabled = false;
    },
    zdySinglePriceBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdySinglePrice) {
        if (zdySinglePrice === "0") {
          this.msgWarn(
            "单价不合法，必须是必须是大于0的最多保留两位小数的数字！"
          );
          zdySinglePrice = "";
        }
        // 处理课时和总价
        if (zdyClassHour) {
          zdyTotalPrice = zdySinglePrice * zdyClassHour;
        } else if (zdyTotalPrice) {
          let remainder = zdyTotalPrice % zdySinglePrice;
          zdyClassHour =
            Math.round((zdyTotalPrice / zdySinglePrice) * 100) / 100;
          if (remainder) {
            this.msgWarn(
              `计算出的课时数为${zdyClassHour},不合法，请重新输入！`
            );
            zdyClassHour = "";
          }
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
        zdyTotalPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    zdyTotalPriceBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdyTotalPrice) {
        // 处理课时和单价
        if (zdyClassHour && zdySinglePrice) {
          if (zdyClassHour * zdySinglePrice !== zdyTotalPrice) {
            zdyClassHour = "";
            zdySinglePrice = "";
          }
        } else if (zdyClassHour && !zdySinglePrice) {
          zdySinglePrice =
            Math.round((zdyTotalPrice / zdyClassHour) * 100) / 100;
        } else if (!zdyClassHour && zdySinglePrice) {
          let remainder = zdyTotalPrice % zdySinglePrice;
          zdyClassHour =
            Math.round((zdyTotalPrice / zdySinglePrice) * 100) / 100;
          if (remainder) {
            this.msgWarn(
              `计算出的课时数为${zdyClassHour},不合法，请重新输入！`
            );
            zdyClassHour = "";
          }
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = 0;
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    zdyDiscountAmountBlur() {
      let { zdyDiscountAmount, zdyTotalPrice, zdyActualPrice } =
        this.saveRuleFormOrder;
      if (zdyDiscountAmount && zdyTotalPrice) {
        if (Number(zdyDiscountAmount) > Number(zdyTotalPrice)) {
          this.msgWarn("优惠价格不能大于总价！");
          zdyDiscountAmount = "";
        }
      }
      zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    zdyActualPriceBlur() {
      let { zdyDiscountAmount, zdyTotalPrice, zdyActualPrice } =
        this.saveRuleFormOrder;
      if (zdyActualPrice && zdyTotalPrice) {
        if (zdyActualPrice > zdyTotalPrice) {
          this.msgWarn("实际支付不能大于总价格，请重新输入！");
          zdyActualPrice = zdyTotalPrice;
        }
        zdyDiscountAmount = zdyTotalPrice - zdyActualPrice;
      } else if (zdyActualPrice && zdyDiscountAmount) {
        if (zdyActualPrice < zdyDiscountAmount) {
          zdyDiscountAmount = "";
        }
        zdyTotalPrice = zdyActualPrice - zdyDiscountAmount;
      } else {
        zdyTotalPrice = zdyActualPrice - zdyDiscountAmount;
      }
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    datePickerChange() {
      if (!this.timePickerValue) {
        this.listParams.beginDate = null;
        this.listParams.endDate = null;
        return;
      }
      this.listParams.beginDate = this.timePickerValue[0];
      this.listParams.endDate = this.timePickerValue[1];
    },
    CB_downOrderStatus() {
      this.downOrderStatus = false;
      this.getStudentList();
    },
    intentionTimeChange() {
      if (!this.intentionTimeValue) {
        this.listParams.intentionBeginTime = null;
        this.listParams.intentionEndTime = null;
        return;
      }
      this.listParams.intentionBeginTime = this.intentionTimeValue[0];
      this.listParams.intentionEndTime = this.intentionTimeValue[1];
    },
    nextPickerChange() {
      if (!this.nextTimePickerValue) {
        this.listParams.nextFollowupBeginTime = null;
        this.listParams.nextFollowupEndTime = null;
        return;
      }
      this.listParams.nextFollowupBeginTime = this.nextTimePickerValue[0];
      this.listParams.nextFollowupEndTime = this.nextTimePickerValue[1];
    },
    closeFileView() {
      this.dialogFileViewPrivateInfo.show = false;
    },
    handleSelectionChange(val) {
      this.selectStudentList = [];
      this.selectStudentListObj = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectStudentList.push(val[i].id);
          this.selectStudentListObj.push(val[i]);
        }
      }
    },
    searchFn() {
      this.getStudentList();
    },
    getStudentList() {
      let data = JSON.parse(JSON.stringify(this.listParams));
      if (data.contract == -1) {
        data.contract = null;
      }
      studentList(data).then((res) => {
        this.studentList = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getStudentList();
    },
    handleCurrentChange(currentPage) {
      sessionStorage.setItem("pageNumber", currentPage);
      this.listParams.pageNumber = currentPage;
      this.getStudentList();
    },
    confirmSave(formName) {
      //只有新增
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isDisable = true;
          let arr = [],
            firstClassTimeListArr = [],
            formalClassTimeListArr = [];
          this.saveRuleForm.yuwen = 1;
          this.saveRuleForm.shuxue = 1;
          this.saveRuleForm.yingyu = 1;
          this.saveRuleForm.lishi = 0;
          this.saveRuleForm.zhengzhi = 0;
          this.saveRuleForm.dili = 0;
          this.saveRuleForm.wuli = 0;
          this.saveRuleForm.huaxue = 0;
          this.saveRuleForm.shengwu = 0;
          this.saveRuleForm.qitaxueke = 0;
          for (let i = 0, len = this.subjectList.length; i < len; i++) {
            // if (this.subjectList[i].select) {
            //   switch (this.subjectList[i].name) {
            //     case "语文":
            //       this.saveRuleForm.yuwen = 1;
            //       break;
            //     case "数学":
            //       this.saveRuleForm.shuxue = 1;
            //       break;
            //     case "英语":
            //       this.saveRuleForm.yingyu = 1;
            //       break;
            //     case "历史":
            //       this.saveRuleForm.lishi = 1;
            //       break;
            //     case "政治":
            //       this.saveRuleForm.zhengzhi = 1;
            //       break;
            //     case "地理":
            //       this.saveRuleForm.dili = 1;
            //       break;
            //     case "物理":
            //       this.saveRuleForm.wuli = 1;
            //       break;
            //     case "化学":
            //       this.saveRuleForm.huaxue = 1;
            //       break;
            //     case "生物":
            //       this.saveRuleForm.shengwu = 1;
            //       break;
            //   }
            // }
            if (this.subjectList[i].status) {
              arr.push({
                studentId: this.saveRuleForm.id,
                subjectId: this.subjectList[i].macroId,
                subjectScore: this.subjectList[i].subjectScore,
                subjectAllScore: this.subjectList[i].subjectAllScore,
                sexNeedId: this.subjectList[i].sexNeedId,
                styleNeedId: this.subjectList[i].styleNeedId,
                speedNeedId: this.subjectList[i].speedNeedId,
                ageNeedId: this.subjectList[i].ageNeedId,
                content: this.subjectList[i].content,
              });
            }
          }
          for (let i = 0, len = this.firstClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.firstClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (this.firstClassTimeList[i][j].status) {
                firstClassTimeListArr.push(this.firstClassTimeList[i][j].key);
              }
            }
          }
          for (let i = 0, len = this.formalClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.formalClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (this.formalClassTimeList[i][j].status) {
                formalClassTimeListArr.push(this.formalClassTimeList[i][j].key);
              }
            }
          }
          this.saveRuleForm.firstClassTime = firstClassTimeListArr.join(",");
          this.saveRuleForm.formalClassTime = formalClassTimeListArr.join(",");
          this.saveRuleForm.xmtStudentSubjectRelationEntityList = arr;
          if (this.saveDialog.type == "save") {
            studentSave(this.saveRuleForm)
              .then((res) => {
                if (res.code == 0 && res.msg == "操作成功！") {
                  this.msgSuccess("操作成功！");
                  this.saveDialogVisible = false;
                  this.isDisable = false;
                  this.getStudentList();
                } else {
                  this.msgWarn(res.rows);
                  this.isDisable = false;
                }
              })
              .catch((err) => {
                this.isDisable = false;
              });
          } else {
            studentUpdate(this.saveRuleForm)
              .then((res) => {
                if (res.code == 0 && res.msg == "操作成功！") {
                  this.msgSuccess("操作成功！");
                  this.saveDialogVisible = false;
                  this.isDisable = false;
                  this.getStudentList();
                } else {
                  this.msgWarn(res.rows);
                  this.isDisable = false;
                }
              })
              .catch((err) => {
                this.isDisable = false;
              });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    beforeAvatarUpload(file) {
      console.log("file", file);
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      } else {
      }
    },
    birthdayChange() {
      let birthdayArray = this.saveRuleForm.birthday.split("-");
      let month = Number(birthdayArray[1]); //月
      let date = Number(birthdayArray[2]); //日
      console.log(birthdayArray);
      if ((month == 1 && date >= 20) || (month == 2 && date <= 18)) {
        this.saveRuleForm.constellationId = 256;
      }
      if ((month == 2 && date >= 19) || (month == 3 && date <= 20)) {
        this.saveRuleForm.constellationId = 257;
      }
      if ((month == 3 && date >= 21) || (month == 4 && date <= 19)) {
        this.saveRuleForm.constellationId = 246;
      }
      if ((month == 4 && date >= 20) || (month == 5 && date <= 20)) {
        this.saveRuleForm.constellationId = 247;
      }
      if ((month == 5 && date >= 21) || (month == 6 && date <= 21)) {
        this.saveRuleForm.constellationId = 248;
      }
      if ((month == 6 && date >= 22) || (month == 7 && date <= 22)) {
        this.saveRuleForm.constellationId = 249;
      }
      if ((month == 7 && date >= 23) || (month == 8 && date <= 22)) {
        this.saveRuleForm.constellationId = 250;
      }
      if ((month == 8 && date >= 23) || (month == 9 && date <= 22)) {
        this.saveRuleForm.constellationId = 251;
      }
      if ((month == 9 && date >= 23) || (month == 10 && date <= 22)) {
        this.saveRuleForm.constellationId = 252;
      }
      if ((month == 10 && date >= 23) || (month == 11 && date <= 21)) {
        this.saveRuleForm.constellationId = 253;
      }
      if ((month == 11 && date >= 22) || (month == 12 && date <= 21)) {
        this.saveRuleForm.constellationId = 254;
      }
      if ((month == 12 && date >= 22) || (month == 1 && date <= 19)) {
        this.saveRuleForm.constellationId = 255;
      }
    },
    cutFn(value) {
      this.customerDisabled = false;
      let id =
        value == "next"
          ? this.nextId
          : value == "prev"
          ? this.prevId
          : this.cutId;
      this.aaa = id;
      prevAndNext({
        id,
        contract: this.contract == -1 ? null : this.contract,
      }).then((res) => {
        var resAnd = res.msg;
        this.prevId = resAnd.xmtStudentUp; //后面 3
        this.nextId = resAnd.xmtStudentDown; //前面1
        studentInfo(id).then((res) => {
          this.studentInfo = res.rows;
          this.newStudentId = res.rows.id;
        });
      });
      this.customerFormOrder.intentionId = "";
    },
    addClick() {
      this.saveDialog = {
        title: "新增学生",
        type: "save",
      };
      this.getProvinceList();
      this.initClassTime();
      for (let i = 0, len = this.subjectList.length; i < len; i++) {
        this.subjectList[i].status = false;
        this.subjectList[i].select = false;
        this.subjectList[i].subjectScore = "";
        this.subjectList[i].subjectAllScore = "";
        this.subjectList[i].sexNeedId = "";
        this.subjectList[i].styleNeedId = "";
        this.subjectList[i].speedNeedId = "";
        this.subjectList[i].ageNeedId = "";
        this.subjectList[i].content = "";
      }
      this.saveRuleForm = {
        name: "",
        contact: "",
        sex: "1",
        sectionId: "",
        birthday: "",
        provinceId: "",
        cityId: "",
        countyId: "",
        schoolName: "",
        sourceType: "",
        areaId: this.saveRuleForm.areaId,
        constellationId: "",
        childNature: "",
        childMotherName: "",
        childMotherPhone: "",
        childFatherName: "",
        childFatherPhone: "",
        parentWechat: "",
        firstClassTime: "",
        firstClassTimeRemark: "",
        formalClassTime: "",
        formalClassTimeRemark: "",
        yuwen: 0,
        shuxue: 0,
        yingyu: 0,
        lishi: 0,
        zhengzhi: 0,
        dili: 0,
        wuli: 0,
        huaxue: 0,
        shengwu: 0,
        qitaxueke: 0,
        xmtStudentSubjectRelationEntityList: [],
      };
      this.saveDialogVisible = true;
    },
    contract(row) {
      if (row.contract == 0) {
        return "首次待跟进（未注册）";
      } else if (row.contract == 1) {
        return "注册已试听";
      } else if (row.contract == 2) {
        return "已签约";
      } else if (row.contract == 3) {
        return "首次待跟进(已注册)(仅代表自主登录)";
      } else if (row.contract == 4) {
        return "跟进中";
      } else if (row.contract == 5) {
        return "其他销售已签约";
      } else if (row.contract == 6) {
        return "无效客户";
      }
    },
    intentionId(row) {
      if (row.intentionId == 1) {
        return "A意向较强";
      } else if (row.intentionId == 2) {
        return "B意向简单";
      } else if (row.intentionId == 3) {
        return "C了解阶段";
      } else if (row.intentionId == 4) {
        return "D无意向";
      }
    },
    formatterBeginTime(row) {
      if (row.xmtFollowupRecordEntityList.length) {
        return row.xmtFollowupRecordEntityList[0].createTime;
      } else {
        return "暂未跟进";
      }
    },
    formatterSearchBeginTime(row) {
      if (row.xmtFollowupRecordEntityList.length) {
        return row.xmtFollowupRecordEntityList[0].nextFollowupTime;
      } else {
        return "暂未跟进";
      }
    },
    refreshClick() {
      this.reload();
    },
    delClick() {
      if (this.selectStudentList.length == 0) {
        this.msgWarn("请至少选择一个资讯！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          studentDelete(this.selectStudentList).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getStudentList();
            }
          });
        })
        .catch(() => {});
    },
    studentItemClick(row) {
      this.$refs.studentListTable.toggleRowSelection(row);
    },
    dealSex(row) {
      if (row.sex == 1) {
        return "男";
      } else if (row.sex == 2) {
        return "女";
      } else {
        return "";
      }
    },
    dealSourceType(row) {
      if (row.sourceType == 1) {
        return "地推活动";
      } else if (row.sourceType == 2) {
        return "网络活动";
      } else if (row.sourceType == 3) {
        return "美团活动";
      } else if (row.sourceType == 4) {
        return "A级客户";
      } else if (row.sourceType == 5) {
        return "家长转介绍";
      }
    },
    dealAble(row) {
      studentUpdate({
        id: row.id,
        status: 1,
      }).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.getStudentList();
        }
      });
    },
    dealDisable(row) {
      this.$prompt("请输入禁用理由", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "禁用理由不能为空！",
        closeOnClickModal: false,
      })
        .then(async ({ value }) => {
          let flag;
          let res = await deleteStudentToken(row.id);
          if (res.code == 0) {
            studentUpdate({
              id: row.id,
              status: 0,
              statusReason: value,
            }).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.getStudentList();
              }
            });
          }
        })
        .catch(() => {});
    },
    contractFn() {
      this.listParams.pageNumber = 1;
      this.getStudentList();
    },
    daalDelete(row) {
      let ids = [];
      if (row) {
        ids = [row.id];
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          studentDelete(ids).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getStudentList();
            }
          });
        })
        .catch(() => {});
    },

    saveApplyClick() {
      this.saveApplyVisible = true;
      getAvliableNum({
        contract: 0,
        dataAuthority: "user",
      }).then((res) => {
        if (res.code == 0) {
          this.saveApplyData.allNum = res.rows.dailyActualReceiveUsers
            ? res.rows.dailyActualReceiveUsers
            : 0;
        }
      });
    },
    selectFileClick() {
      this.$refs.saveApply.click();
      this.$refs.saveApply.value = "";
    },
    uploadSelectFileClick() {
      for (let i = 0, len = this.selectFile.length; i < len; i++) {
        if (!this.selectFile[i].status) {
          this.dealFileUpload(this.selectFile[i]);
        }
      }
    },
    dealFileUpload(item) {
      let formData = new FormData();
      formData.append("areaId", this.areaId);
      formData.append("file", item.file);
      studentImport(formData).then((res) => {
        if (res.code == 0) {
          if (res.errorList.length > 0) {
            item.status = 2;
            item.importMsg = res.errorList[0].importMsg;
          } else if (res.successList.length > 0) {
            item.status = 1;
            item.importMsg = "";
          }
        }
      });
    },
    selectFileDel(row) {
      for (let i = 0, len = this.selectFile.length; i < len; i++) {
        if (row && row.id == this.selectFile[i].id) {
          this.selectFile.splice(i, 1);
          break;
        }
      }
    },
    selectFileChange() {
      if (this.$refs.saveApply.files.length > 0) {
        for (let i = 0, len = this.$refs.saveApply.files.length; i < len; i++) {
          this.selectFile.push({
            status: 0,
            file: this.$refs.saveApply.files[i],
            importMsg: "",
            id: ++id,
          });
        }
      }
    },
    followUpSaveFollowUp(formName) {
      this.$refs[formName].validate(async (valid) => {
        this.saveRuleFormFollowUp.saleId = this.newSaleId;
        if (valid) {
          let photo = [];
          this.photoList.forEach((item) => {
            photo.push({
              url: item.url,
            });
          });
          followupSave({
            studentId: this.newStudentId,
            saleId: this.newSaleId,
            followContent: this.saveRuleFormFollowUp.followContent,
            nextFollowupTime: this.saveRuleFormFollowUp.nextFollowupTime,
            intentionId: this.saveRuleFormFollowUp.intentionId,
            photo,
          }).then((res) => {
            if (res.code == 0) {
              this.followUpDialogVisible = false;
              this.msgSuccess("新增跟进记录成功！");
              this.getStudentList();
              // this.getFollowUpList();
            }
          });
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    getSaleList() {
      listNoPage(this.saleParams).then((res) => {
        this.saleList = res;
      });
    },
    saleChange() {
      for (let i = 0, len = this.saleList.length; i < len; i++) {
        if (this.saleList[i].userId == this.saleData.saleId) {
          this.saleData.saleName = this.saleList[i].username;
        }
      }
    },
    getSaleId() {
      getSaleId().then((res) => {
        if (res.code == 0) {
          this.areaId = this.saveRuleForm.areaId = res.rows;
        }
      });
    },
    daalDetail(row) {
      this.$router.push({
        name: "myCustomer_studentInfo",
        params: {
          id: row.id,
          contract: this.listParams.contract,
        },
      });
    },
    customerSaveOrder(formName) {
      if (this.customerFormOrder.intentionId == "") {
        this.msgError("客户状态不能为空！");
        return false;
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.customerDisabled = true;
          if (this.customerFormOrder.intentionId == "265") {
            //有效标签
            ifEffectiveBaseStudent({
              studentId: this.newStudentId,
              ifEffective: "Y",
              code: "265",
            }).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                // setTimeout(() => {
                //   this.customerDisabled = false;
                // }, 1000)
                // this.basicDialogVisible=false;
                this.getStudentList();
              }
            });
          } else {
            ifEffectiveBaseStudent({
              studentId: this.newStudentId,
              ifEffective: "N",
              code: this.customerFormOrder.intentionId,
            }).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                // setTimeout(() => {
                //   this.customerDisabled = false;
                // }, 1000)
                this.getStudentList();
              }
            });
          }
        }
      });
    },
    //点击姓名
    getMore(row) {
      console.log(row);
    },
    addFollowUp() {
      this.followUpDialogVisible = true;

      this.saveRuleFormFollowUp = {
        nextFollowupTime: "",
        intentionId: "",
        followContent: "",
        photo: [],
      };
    },
    //查看基本信息
    getBasic(row) {
      this.customerDisabled = false;
      this.basicDialogVisible = true;
      this.cutId = row.id;
      this.newStudentId = row.id;
      this.newSaleId = row.id;
      this.photoList = [];
      this.cutFn("");
    },
    studentSourceType(sourceType) {
      switch (sourceType) {
        case 1:
          return "地推活动";
          break;
        case 2:
          return "线上活动";
          break;
        case 3:
          return "家长转介绍";
          break;
        default:
          return "暂无数据";
      }
    },
    studentImportType(item) {
      switch (item) {
        case 1:
          return "批量导入";
          break;
        case 2:
          return "销售添加";
          break;
        case 3:
          return "自主登录";
          break;
        case 4:
          return "教务添加";
          break;
        case 5:
          return "邀请码";
          break;
        default:
          return "暂无数据";
      }
    },
    initClassTime() {
      this.formalClassTimeList = [
        [
          {
            name: "上午",
            key: "a",
            status: false,
          },
          {
            name: "上午",
            key: "b",
            status: false,
          },
          {
            name: "上午",
            key: "c",
            status: false,
          },
          {
            name: "上午",
            key: "d",
            status: false,
          },
          {
            name: "上午",
            key: "e",
            status: false,
          },
          {
            name: "上午",
            key: "f",
            status: false,
          },
          {
            name: "上午",
            key: "g",
            status: false,
          },
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false,
          },
          {
            name: "下午",
            key: "i",
            status: false,
          },
          {
            name: "下午",
            key: "j",
            status: false,
          },
          {
            name: "下午",
            key: "k",
            status: false,
          },
          {
            name: "下午",
            key: "l",
            status: false,
          },
          {
            name: "下午",
            key: "m",
            status: false,
          },
          {
            name: "下午",
            key: "n",
            status: false,
          },
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false,
          },
          {
            name: "晚上",
            key: "p",
            status: false,
          },
          {
            name: "晚上",
            key: "q",
            status: false,
          },
          {
            name: "晚上",
            key: "r",
            status: false,
          },
          {
            name: "晚上",
            key: "s",
            status: false,
          },
          {
            name: "晚上",
            key: "t",
            status: false,
          },
          {
            name: "晚上",
            key: "u",
            status: false,
          },
        ],
      ];
      this.firstClassTimeList = [
        [
          {
            name: "上午",
            key: "a",
            status: false,
          },
          {
            name: "上午",
            key: "b",
            status: false,
          },
          {
            name: "上午",
            key: "c",
            status: false,
          },
          {
            name: "上午",
            key: "d",
            status: false,
          },
          {
            name: "上午",
            key: "e",
            status: false,
          },
          {
            name: "上午",
            key: "f",
            status: false,
          },
          {
            name: "上午",
            key: "g",
            status: false,
          },
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false,
          },
          {
            name: "下午",
            key: "i",
            status: false,
          },
          {
            name: "下午",
            key: "j",
            status: false,
          },
          {
            name: "下午",
            key: "k",
            status: false,
          },
          {
            name: "下午",
            key: "l",
            status: false,
          },
          {
            name: "下午",
            key: "m",
            status: false,
          },
          {
            name: "下午",
            key: "n",
            status: false,
          },
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false,
          },
          {
            name: "晚上",
            key: "p",
            status: false,
          },
          {
            name: "晚上",
            key: "q",
            status: false,
          },
          {
            name: "晚上",
            key: "r",
            status: false,
          },
          {
            name: "晚上",
            key: "s",
            status: false,
          },
          {
            name: "晚上",
            key: "t",
            status: false,
          },
          {
            name: "晚上",
            key: "u",
            status: false,
          },
        ],
      ];
    },
    getSubjectList() {
      return infoByParentCodeAndValue({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          for (let i = 0, len = res.rows.length; i < len; i++) {
            res.rows[i].status = false;
            res.rows[i].select = false;
            res.rows[i].subjectScore = "";
            res.rows[i].subjectAllScore = "";
            res.rows[i].sexNeedId = "";
            res.rows[i].styleNeedId = "";
            res.rows[i].speedNeedId = "";
            res.rows[i].ageNeedId = "";
            res.rows[i].content = "";
          }
          this.subjectList = res.rows;
        }
      });
    },
    daalAddOrder(item) {
      this.downOrderStatus = true;
      this.downParams = {
        id: item.id,
        type: "add",
      };
      // this.id = item.id;
      // this.translateTotal=""
      // this.orderDialogVisible = true;
      // this.saveRuleFormOrder = {
      //   orderType: "5", //1一对一  2一对多  4
      //   payType: "2", //1线上支付   2线下支付
      //   courseTypeId: "1", //1大班课  2小班课  3答疑课
      //   productId: "",
      //   certificate: "", //付款凭证
      //   coachAgreement: "", //辅导协议
      //   sectionId: "", //学段
      //   subjectId: "",
      //   teacherTitleId: "",
      //   classHour: "",
      //   classHourId: "", //不用传到后台
      //   singlePrice: "",
      //   totalPrice: "",
      //   zdyClassHour: "",
      //   zdySinglePrice: "",
      //   zdyGiveClassHour: 0,
      //   zdyDiscountAmount: "",
      //   zdyActualPrice: "",
      //   zdyTotalPrice:"",
      //   studentId: this.id,
      //   productPackageId: "",
      //   remark: "",
      //   payMent: "1", //1支付宝  2微信  3对公账户  4pos机
      //   moneyType: "1",
      //   coachAgreementName: "",
      // };
      // this.orderClick();
    },
    async orderClick() {
      await this.getProductList();
      this.translateTotal = "";
      if (this.productList.length > 0) {
        this.saveRuleFormOrder.productId = this.productList[0].id;
        this.getProductDetail();
      } else {
        this.saveRuleFormOrder.sectionId = "";
        this.saveRuleFormOrder.productId = "";
        this.saveRuleFormOrder.periodId = "";
        this.saveRuleFormOrder.subjectId = "";
        this.saveRuleFormOrder.teacherTitleId = "";
        this.saveRuleFormOrder.classHourId = "";
        this.saveRuleFormOrder.singlePrice = "";
        this.saveRuleFormOrder.totalPrice = "";
      }
      // this.getProductDetail();
    },
    getProductList() {
      if (this.saveRuleFormOrder.orderType == 3) {
        return productListNoPage({
          isSubscribe: 1,
          courseTypeId:
            this.saveRuleFormOrder.orderType == 2
              ? this.saveRuleFormOrder.courseTypeId
              : null,
        }).then((res) => {
          if (res.code == 0) {
            this.productList = res.rows;
          }
        });
      }
      // else if (this.saveRuleFormOrder.orderType == 1) {
      //   //一对一
      //   return productListNoPage({
      //     productType: this.saveRuleFormOrder.orderType,
      //     isSubscribe: 0,
      //     courseTypeId:
      //       this.saveRuleFormOrder.orderType == 2
      //         ? this.saveRuleFormOrder.courseTypeId
      //         : null,
      //   }).then((res) => {
      //     if (res.code == 0) {
      //       this.productList = res.rows;
      //     }
      //   });
      // }
      else if (this.saveRuleFormOrder.orderType == 2) {
        //一对多
        return productListNoPage({
          productType: this.saveRuleFormOrder.orderType,
          courseTypeId:
            this.saveRuleFormOrder.orderType == 2
              ? this.saveRuleFormOrder.courseTypeId
              : null,
        }).then((res) => {
          if (res.code == 0) {
            this.productList = res.rows;
          }
        });
      }
    },
    productChange() {
      this.getProductDetail();
    },
    getProductDetail() {
      return productInfoDetail(this.saveRuleFormOrder.productId).then((res) => {
        if (res.code == 0) {
          this.productDetail.productPackage =
            res.rows.xmtProductPackageEntityList;
          if (this.saveRuleFormOrder.orderType == 2) {
            this.dealPeriod();
          } else {
            this.dealSection();
          }
        } else {
        }
      });
    },
    dealPeriod() {
      this.productDetail.periodList = [];
      this.productDetail.periodIdList = [];
      this.saveRuleFormOrder.periodId = "";
      for (
        let i = 0, len = this.productDetail.productPackage.length;
        i < len;
        i++
      ) {
        if (
          !this.productDetail.periodIdList.includes(
            this.productDetail.productPackage[i].periodId
          ) &&
          this.productDetail.productPackage[i].periodId
        ) {
          this.productDetail.periodList.push({
            periodName: this.productDetail.productPackage[i].periodName,
            periodId: this.productDetail.productPackage[i].periodId,
          });
          this.productDetail.periodIdList.push(
            this.productDetail.productPackage[i].periodId
          );
        }
      }
      if (this.productDetail.periodList.length > 0) {
        this.saveRuleFormOrder.periodId =
          this.productDetail.periodList[0].periodId;
      }
      this.dealSubject();
    },
    dealSection() {
      this.productDetail.sectionList = [];
      this.productDetail.sectionIdList = [];
      for (
        let i = 0, len = this.productDetail.productPackage.length;
        i < len;
        i++
      ) {
        if (
          !this.productDetail.sectionIdList.includes(
            this.productDetail.productPackage[i].sectionId
          )
        ) {
          this.productDetail.sectionList.push({
            sectionName: this.productDetail.productPackage[i].sectionName,
            sectionId: this.productDetail.productPackage[i].sectionId,
          });
          this.productDetail.sectionIdList.push(
            this.productDetail.productPackage[i].sectionId
          );
        }
      }
      this.saveRuleFormOrder.sectionId =
        this.productDetail.sectionList[0].sectionId;
      this.dealSubject();
    },
    dealSubject() {
      if (this.saveRuleFormOrder.orderType == 2) {
        this.productDetail.subjectList = [];
        this.productDetail.subjectIdList = [];
        this.saveRuleFormOrder.subjectId = "";
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            !this.productDetail.subjectIdList.includes(
              this.productDetail.productPackage[i].subjectId
            )
          ) {
            this.productDetail.subjectList.push({
              subjectName: this.productDetail.productPackage[i].subjectName,
              subjectId: this.productDetail.productPackage[i].subjectId,
            });
            this.productDetail.subjectIdList.push(
              this.productDetail.productPackage[i].subjectId
            );
          }
        }
        if (this.productDetail.subjectList.length > 0) {
          this.saveRuleFormOrder.subjectId =
            this.productDetail.subjectList[0].subjectId;
        }
      }
      this.dealTeacherTitle();
    },
    dealTeacherTitle() {
      this.productDetail.teacherTitleList = [];
      this.productDetail.teacherTitleIdList = [];
      this.saveRuleFormOrder.teacherTitleId = "";
      if (this.saveRuleFormOrder.orderType == 3) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.sectionId ==
              this.productDetail.productPackage[i].sectionId &&
            !this.productDetail.teacherTitleIdList.includes(
              this.productDetail.productPackage[i].teacherTitleId
            )
          ) {
            this.productDetail.teacherTitleList.push({
              teacherTitleName:
                this.productDetail.productPackage[i].teacherTitleName,
              teacherTitleId:
                this.productDetail.productPackage[i].teacherTitleId,
            });
            this.productDetail.teacherTitleIdList.push(
              this.productDetail.productPackage[i].teacherTitleId
            );
          }
        }
        if (this.productDetail.teacherTitleList.length > 0) {
          this.saveRuleFormOrder.teacherTitleId =
            this.productDetail.teacherTitleList[0].teacherTitleId;
        }
      } else if (this.saveRuleFormOrder.orderType == 2) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            this.saveRuleFormOrder.subjectId ==
              this.productDetail.productPackage[i].subjectId &&
            !this.productDetail.teacherTitleIdList.includes(
              this.productDetail.productPackage[i].teacherTitleId
            )
          ) {
            this.productDetail.teacherTitleList.push({
              teacherTitleName:
                this.productDetail.productPackage[i].teacherTitleName,
              teacherTitleId:
                this.productDetail.productPackage[i].teacherTitleId,
            });
            this.productDetail.teacherTitleIdList.push(
              this.productDetail.productPackage[i].teacherTitleId
            );
          }
        }
        if (this.productDetail.teacherTitleList.length > 0) {
          this.saveRuleFormOrder.teacherTitleId =
            this.productDetail.teacherTitleList[0].teacherTitleId;
        }
      }
      this.dealClassHour();
    },
    dealClassHour() {
      this.productDetail.classHourList = [];
      this.productDetail.classHourIdList = [];
      this.saveRuleFormOrder.productPackageId = "";
      this.saveRuleFormOrder.classHour = "";
      this.saveRuleFormOrder.classHourId = "";
      this.saveRuleFormOrder.singlePrice = "";
      this.saveRuleFormOrder.totalPrice = "";
      if (this.saveRuleFormOrder.orderType == 3) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.sectionId ==
              this.productDetail.productPackage[i].sectionId &&
            this.saveRuleFormOrder.teacherTitleId ==
              this.productDetail.productPackage[i].teacherTitleId &&
            !this.productDetail.classHourIdList.includes(
              this.productDetail.productPackage[i].id
            )
          ) {
            this.productDetail.classHourList.push({
              classHour: this.productDetail.productPackage[i].classHour,
              classHourName:
                this.productDetail.productPackage[i].classHour + "课时",
              id: this.productDetail.productPackage[i].id,
              singlePrice: this.productDetail.productPackage[i].singlePrice,
              totalPrice: this.productDetail.productPackage[i].totalPrice,
            });
            this.productDetail.classHourIdList.push(
              this.productDetail.productPackage[i].id
            );
          }
        }
        this.productDetail.classHourList.push({
          classHour: "自定义课时",
          classHourName: "自定义课时",
          id: -1,
        });
        // if (this.saveRuleFormOrder.orderType != "3") {
        //   this.productDetail.classHourList.push({
        //     classHour: "自定义课时",
        //     classHourName: "自定义课时",
        //     id: -1,
        //   });
        // }
      } else if (this.saveRuleFormOrder.orderType == 2) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            this.saveRuleFormOrder.subjectId ==
              this.productDetail.productPackage[i].subjectId &&
            this.saveRuleFormOrder.teacherTitleId ==
              this.productDetail.productPackage[i].teacherTitleId &&
            !this.productDetail.classHourIdList.includes(
              this.productDetail.productPackage[i].id
            )
          ) {
            this.productDetail.classHourList.push({
              classHour: this.productDetail.productPackage[i].classHour,
              classHourName:
                this.productDetail.productPackage[i].classHour + "课时",
              id: this.productDetail.productPackage[i].id,
              singlePrice: this.productDetail.productPackage[i].singlePrice,
              totalPrice: this.productDetail.productPackage[i].totalPrice,
            });
            this.productDetail.classHourIdList.push(
              this.productDetail.productPackage[i].id
            );
          }
        }
      }
      this.saveRuleFormOrder.productPackageId =
        this.productDetail.classHourList[0].id;
      this.saveRuleFormOrder.classHour =
        this.productDetail.classHourList[0].classHour;
      /* if (this.saveRuleFormOrder.zdyClassHour) {
        this.saveRuleFormOrder.classHourId = -1;
      } else {
        this.saveRuleFormOrder.classHourId =
          this.productDetail.classHourList[0].id;
      } */
      this.saveRuleFormOrder.classHourId =
          this.productDetail.classHourList[0].id;
      this.saveRuleFormOrder.singlePrice =
        this.productDetail.classHourList[0].singlePrice;
      this.saveRuleFormOrder.totalPrice =
        this.productDetail.classHourList[0].totalPrice;
      if(this.saveRuleFormOrder.orderType == 3){  // 试听课
        this.saveRuleFormOrder.zdyTotalPrice = '';
        this.saveRuleFormOrder.zdyActualPrice = '';
        this.saveRuleFormOrder.zdySinglePrice = '';
        this.saveRuleFormOrder.zdyDiscountAmount = '';
        this.saveRuleFormOrder.zdyClassHour = '';
      }else{
        this.saveRuleFormOrder.zdyTotalPrice =
        this.productDetail.classHourList[0].totalPrice;
      this.saveRuleFormOrder.zdyActualPrice =
        this.productDetail.classHourList[0].totalPrice;
      }
      
    },
    seeWhole() {
      this.basicDialogVisible = false;
      this.$router.push({
        name: "myCustomer_studentInfo",
        params: {
          id: this.aaa,
          contract: this.listParams.contract,
        },
      });
    },
    orderSectionChange() {
      this.dealSubject();
    },
    orderSubjectChange() {
      this.dealTeacherTitle();
    },
    orderTeacherTitleChange() {
      this.dealClassHour();
    },
    orderClassHourChange() {
      for (
        let i = 0, len = this.productDetail.classHourList.length;
        i < len;
        i++
      ) {
        if (
          this.productDetail.classHourList[i].id ==
            this.saveRuleFormOrder.classHourId &&
          this.saveRuleFormOrder.classHourId != -1
        ) {
          this.saveRuleFormOrder.productPackageId =
            this.productDetail.classHourList[i].id;
          this.saveRuleFormOrder.classHour =
            this.productDetail.classHourList[i].classHour;
          this.saveRuleFormOrder.classHourId =
            this.productDetail.classHourList[i].id;
          this.saveRuleFormOrder.singlePrice =
            this.productDetail.classHourList[i].singlePrice;
          this.saveRuleFormOrder.totalPrice =
            this.productDetail.classHourList[i].totalPrice;
        } else {
          this.saveRuleFormOrder.productPackageId = "";
          this.saveRuleFormOrder.classHour = "";
          this.saveRuleFormOrder.classHourId =
            this.productDetail.classHourList[i].id;
          this.saveRuleFormOrder.singlePrice = "";
          this.saveRuleFormOrder.totalPrice = "";
        }
      }
    },
    certificateSuccess(response, file, fileList) {
      if (response[0].url) {
        this.saveRuleFormOrder.certificate = response[0].url;
        this.srcList = [];
        this.srcList.push(response[0].url);
      }
    },
    certificateError(err, file, fileList) {
      this.msgError("付款凭证上传失败！");
    },
    coachAgreementSuccess(response, file, fileList) {
      if (response[0].url) {
        this.saveRuleFormOrder.coachAgreement = response[0].url;
        this.saveRuleFormOrder.coachAgreementName = response[0].originalName;
      }
    },
    coachAgreementError(err, file, fileList) {
      this.msgError("辅导协议上传失败！");
    },
    //添加学生成绩
    addStudentResult() {
      this.studentResultSync = true;
    },
    handleRemove(file) {
      for (var i = 0; i < this.testPapers.length; i++) {
        if (this.testPapers[i] == file.response[0].url) {
          delete this.testPapers[i];
          break;
        }
      }

      console.log(this.testPapers, "testPapers...");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleAvatarSuccess(res) {
      this.testPapers.push(res[0].url);
    },
    confirmStudent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.studentRuleForm);
          console.log(this.testPapers);
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    dealNumTofix2(str) {
      if (str != "" && str.substr(0, 1) == ".") {
        str = "";
      }
      str = str.replace(/^0*(0\.|[1-9])/, "$1"); //解决 粘贴不生效
      str = str.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      str = str.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      str = str.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      str = str.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (str.indexOf(".") < 0 && str != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (str.substr(0, 1) == "0" && str.length == 2) {
          str = str.substr(1, str.length);
        }
      }
      return str;
    },
    confirmSaveOrder(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let obj = {};
          this.fullscreenLoading = true;
          if (this.saveRuleFormOrder.orderType == 3) {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              payType: this.saveRuleFormOrder.payType,
              productId: this.saveRuleFormOrder.productId,
              sectionId: this.saveRuleFormOrder.sectionId,
              teacherTitleId: this.saveRuleFormOrder.teacherTitleId,
              studentId: this.id,
              coachAgreement: this.saveRuleFormOrder.coachAgreement,
              coachAgreementName: this.saveRuleFormOrder.coachAgreementName,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
            };
            if (this.saveRuleFormOrder.classHourId == -1) {
              obj.zdyClassHour = this.saveRuleFormOrder.zdyClassHour;
              obj.zdySinglePrice = this.saveRuleFormOrder.zdySinglePrice;
              obj.zdyGiveClassHour = this.saveRuleFormOrder.zdyGiveClassHour;
              obj.zdyDiscountAmount = this.saveRuleFormOrder.zdyDiscountAmount;
              obj.zdyActualPrice = this.saveRuleFormOrder.zdyActualPrice;
              obj.zdyTotalPrice = this.saveRuleFormOrder.zdyTotalPrice;
              obj.classHour = "";
              obj.singlePrice = "";
              obj.totalPrice = "";
            } else {
              obj.zdyClassHour = "";
              obj.zdySinglePrice = "";
              obj.zdyGiveClassHour = 0;
              obj.zdyDiscountAmount = "";
              obj.zdyActualPrice = "";
              obj.zdyTotalPrice = "";
              obj.productPackageId = this.saveRuleFormOrder.productPackageId;
              obj.classHour = this.saveRuleFormOrder.classHour;
              obj.singlePrice = this.saveRuleFormOrder.singlePrice;
              obj.totalPrice = this.saveRuleFormOrder.totalPrice;
            }
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          } else if (this.saveRuleFormOrder.orderType == 2) {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              courseTypeId: this.saveRuleFormOrder.courseTypeId,
              payType: this.saveRuleFormOrder.payType,
              productId: this.saveRuleFormOrder.productId,
              sectionId: this.saveRuleFormOrder.sectionId,
              studentId: this.id,
              teacherTitleId: this.saveRuleFormOrder.teacherTitleId,
              subjectId: this.saveRuleFormOrder.subjectId,
              coachAgreement: this.saveRuleFormOrder.coachAgreement,
              coachAgreementName: this.saveRuleFormOrder.coachAgreementName,
              classHour: this.saveRuleFormOrder.classHour,
              singlePrice: this.saveRuleFormOrder.singlePrice,
              // totalPrice: this.saveRuleFormOrder.totalPrice,
              productPackageId: this.saveRuleFormOrder.productPackageId,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
              zdyActualPrice: this.saveRuleFormOrder.zdyActualPrice,
              zdyTotalPrice: this.saveRuleFormOrder.totalPrice,
              zdyDiscountAmount: this.saveRuleFormOrder.zdyDiscountAmount,
            };
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          } else {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              payType: this.saveRuleFormOrder.payType,
              sectionId: this.saveRuleFormOrder.sectionId,
              subjectId: this.saveRuleFormOrder.subjectId,
              studentId: this.id,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
              totalPrice: this.saveRuleFormOrder.totalPrice,
              zdyGiveClassHour: this.saveRuleFormOrder.zdyGiveClassHour,
            };
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          }
          if (this.orderDialog.type == "edit") {
            await orderRemove([this.curEditOrder]).then((res) => {
              if (res.code == 0) {
              }
            });
          }
          console.log(obj);
          addOrder(obj).then((res) => {
            if (res.code == 0 && res.msg == "下单成功") {
              this.orderDialogVisible = false;
              this.fullscreenLoading = false;
              this.msgSuccess("下单成功！");
              this.getStudentList();
            } else {
              this.fullscreenLoading = false;
              this.msgError(res.rows);
            }
          });
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
  },
  //  beforeRouteEnter(to, from, next) {
  //     next(vm=>{
  //         if(from.name == "myCustomer_studentInfo"){
  //             vm.listParams.pageNumber=Number(sessionStorage.getItem('pageNumber'))
  //             vm.handleCurrentChange( Number(sessionStorage.getItem('pageNumber')))
  //         }
  //     })
  // },
  created() {},
  mounted() {
    this.getStudentList();
    this.getSectionList();
    this.getSaleId();
    this.getConstellationList();
    this.getTeachSpeedList();
    this.getTeacYearList();
    this.getTeachStyleList();
    this.getSubjectList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 80px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .class_title {
    height: 30px;
    line-height: 30px;
    text-indent: 12px;
  }
  .table_area {
    margin-left: 20px;
    padding: 10px 4px;
    .class_tip {
      height: 40px;
      line-height: 40px;
      text-indent: 4px;
      span {
        margin-right: 20px;
      }
    }
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .self_input {
    padding-bottom: 4px;
    color: #606266;
    text-align: center;
    width: 90%;
    overflow: hidden;
    outline: none;
    border: none;
    border-bottom: 1px solid #c0c4cc;
  }
  .self_input:focus {
    border-bottom: 1px solid #1890ff;
  }
  .self_table {
    width: 100%;
    border: 1px solid #ebeef5;
    border-collapse: collapse;
    border-spacing: 0px;
    tr {
      border: 1px solid #ebeef5;
      td {
        border: 1px solid #ebeef5;
        text-align: center;
        vertical-align: middle;
        height: 40px;
      }
    }
  }
  .required_subject {
    height: auto;
    margin-bottom: 10px;
  }
  .select_subject {
    .select_subject_item {
      .item_subject_title span:nth-child(1) {
        font-weight: 900;
        padding-left: 32px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
      .item_subject_title span:nth-child(2) {
        font-weight: 500;
        font-size: 16px;
        margin-left: 20px;
        color: #000;
      }
      .item_score {
        display: flex;
        /deep/ .el-input {
          width: 40%;
        }
        > span {
          width: 20px;
          text-align: center;
        }
      }
    }
  }
  .open_tip {
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .save_apply_tip {
    margin-bottom: 20px;
  }
  .save_apply {
    .save_apply_input {
      width: 80px;
    }
  }
  .content_dl {
    display: flex;
    // padding:0 20px;
    dt {
      width: 100px;
      height: 100px;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
    dd {
      flex: 1;
      margin-left: 20px;
      h2 {
        font-size: 20px;
      }
      div {
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;
        span {
          width: 33.3%;
          // font-size:16px;
          margin-top: 10px;
        }
        p {
          margin-top: 10px;
        }
      }
    }
  }
  .content_ul {
    margin-top: 10px;
    li {
      display: flex;
      align-items: center;
      span {
        flex: 1;
        margin-top: 10px;
      }
    }
  }
  .cut_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    span {
      cursor: pointer;
    }
  }
}
>>> .td-img .el-icon-circle-close {
  color: white !important;
}
.img_list {
  width: 100%;
  min-height: 120px;
  overflow: auto;
  .img_item {
    position: relative;
    margin: 10px 10px 10px 0px;
    float: left;
    cursor: pointer;
    height: 100px;
    width: 100px;
    border: 1px solid #dddddd;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .img_hover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: none;
      z-index: 6;
      align-items: center;
      justify-content: center;
      background-color: rgba($color: #000000, $alpha: 0.7);
      .icon_del {
        margin-left: 10px;
        cursor: pointer;
        color: #ffffff;
      }
      .icon_preview {
        cursor: pointer;
        color: #ffffff;
        font-size: 14px;
      }
    }
    &:hover {
      .img_hover {
        display: flex;
      }
    }
    img {
      width: 100px;
      height: 100px;
    }
    .icon_new {
      font-size: 40px;
    }
  }
}
</style>
