<template>
    <div class="main-content sys-role-manage">
        <div class="btn-area">
      <div class="left">
        <el-button-group>
          <el-button type="default" size="small" >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>查看</span>
          </el-button>
          <el-button type="default" size="small" >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增任务</span>
          </el-button>
          <el-button type="default" size="small" >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>修改任务</span>
          </el-button>
          <el-button type="default" size="small" >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除任务</span>
          </el-button>
        </el-button-group>
      </div>
        </div>
        <div class="table-area">
      <el-table
        size="small"
        :data="dataList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="studentListTable"
        @selection-change="handleSelectionChange"
        @row-click="rowClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="batch"
          label="任务名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="number"
          label="批次"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="条数/份"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="数据份数"
          align="center"
        ></el-table-column>
         <el-table-column
          label="流转次数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="分配日期"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="分配时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="回收频率"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="回收时间"
          align="center"
        ></el-table-column>
      </el-table>
        </div>
        <!-- <div class="page-area">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="listParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="listParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            background
          ></el-pagination>
        </div> -->
        <!-- <el-dialog
      :title="apportionDialog.title"
      :visible.sync="apportionVisible"
      :close-on-click-modal="false"
      width="500px"
      center
    >
      <div class="dialog-content">
        <div class="item_select">
          <span>请选择销售：</span>
          <el-select
            v-model="saleData.saleId"
            placeholder="请选择"
            size="small"
            @change="saleChange"
          >
            <el-option
              v-for="item in saleList"
              :key="item.userId"
               :label="`${item.username}（${item.name}）`"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="apportionVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmApportion()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
    </div>
</template>
<script>
export default {
    data(){
        return{
          dataList:[],

        }
    },
    methods:{
        handleSelectionChange(){

        },
        rowClick(){

        },

    },
    mounted(){

    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content{
    .detail_type{
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color:#000;
    }
    .row_detail{
      padding: 6px 0;
    }
    .row_detail2{
      span{
        font-weight: bold;
        color:#ff3f3f;
      }
    }
    .certificate{
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
>>>.td-img .el-icon-circle-close {
    color: white !important;
  }
  a{text-decoration:none;}
</style>


