<template>
    <div class="main-content sys-role-manage">
        <div class="btn-area select-condition">
            <div class="left">
                <div class="select-condition-item">
                    <!-- <span class="item-lable">导入方式：</span> -->
                    <el-input
                        v-model="listParams.name"
                        placeholder="编号/学生姓名/联系方式"
                        size="small"
                        style="min-width:120px"
                    ></el-input>
                </div>
                <div class="select-condition-item">
                    <span class="item-lable">批次：</span>
                    <el-input
                        v-model="listParams.pici"
                        placeholder="多批次之间用”，“隔开"
                        size="small"
                    ></el-input>
                </div>
                <div class="select-condition-item">
                    <span class="item-lable">分配状态：</span>
                    <el-select
                        v-model="listParams.type"
                        placeholder="请选择"
                        size="small"
                    >
                        <el-option
                        v-for="item in type"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
                <div class="select-condition-item">
                    <span class="item-lable">年级：</span>
                    <el-select
                        v-model="listParams.sectionId"
                        placeholder="请选择"
                        size="small"
                    >
                        <el-option
                        v-for="item in sectionList"
                        :key="item.macroId"
                        :label="item.name"
                        :value="item.macroId"
                        ></el-option>
                    </el-select>
                </div>
                <div class="select-condition-item">
                  <span class="item-lable">最近分配时间：</span>
                  <el-date-picker
                    size="small"
                    v-model="timePickerValue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                    @change="datePickerChange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                  ></el-date-picker>
                </div>
                <div class="select-condition-item">
                    <span class="item-lable">无效客户：</span>
                    <el-input
                        v-model="listParams.wuxiao"
                        placeholder="填写次数"
                        size="small"
                    ></el-input>
                </div>
            </div>
        </div>
        <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>批量导入</span>
          </el-button>
          <el-button type="default" size="small" @click="batchApportion">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>人工分配</span>
          </el-button>
          <el-button type="default" size="small" >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>批量删除</span>
          </el-button>
          <el-button type="default" size="small" >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>转入黑名单</span>
          </el-button>
          <el-button type="default" size="small" >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>导出excel</span>
          </el-button>
          <el-button type="default" size="small" >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>上传记录</span>
          </el-button>
        </el-button-group>
      </div>
        </div>
        <div class="table-area">
      <el-table
        size="small"
        :data="dataList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="studentListTable"
        @selection-change="handleSelectionChange"
        @row-click="rowClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="batch"
          label="批次"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="number"
          label="编号(添加时间)"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="学生姓名"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="联系方式"
          align="center"
        ></el-table-column>
         <el-table-column
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="分配状态"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="拨打状态"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="所属销售"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              >分配</el-button
            >
            <el-button
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="listParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="listParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            background
          ></el-pagination>
        </div>
        <el-dialog
      :title="apportionDialog.title"
      :visible.sync="apportionVisible"
      :close-on-click-modal="false"
      width="500px"
      center
    >
      <div class="dialog-content">
        <div class="item_select">
          <span>请选择销售：</span>
          <el-select
            v-model="saleData.saleId"
            placeholder="请选择"
            size="small"
            @change="saleChange"
          >
            <el-option
              v-for="item in saleList"
              :key="item.userId"
               :label="`${item.username}（${item.name}）`"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="apportionVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmApportion()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    </div>
</template>
<script>
import {
  listNoPage
} from "@/api/sales/saleManage";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  fileUpload
} from "@/api/public";
import dayjs from "dayjs";
export default {
    data(){
        return{
            listParams:{
                name:"",
                pici:"",
                type:"",
                sectionId:"",
                wuxiao:"",
                pageNumber:1,
                pageSize:10,
            },
            saleData: {
              saleId: "",
              saleName: "",
              record: true
            },
            total:0,
            apportionVisible:false,
            apportionDialog: {
              title: "批量分配",
            },
            type:[
                {
                    value: "",
                    label: "全部"
                },
                {
                    value: "1",
                    label: "已分配"
                },
                {
                    value: "2",
                    label: "未分配"
                },
            ],
            pickerOptions: {
            shortcuts: [
              {
                text: "今天",
                onClick(picker) {
                  const end = new Date(
                    dayjs()
                      .add(1, "day")
                      .format("YYYY-MM-DD") + " 00:00:00"
                  );
                  const start = new Date(
                    dayjs().format("YYYY-MM-DD") + " 00:00:00"
                  );
                  picker.$emit("pick", [start, end]);
                }
              },
              {
                text: "昨天",
                onClick(picker) {
                  const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
                  const start = new Date(
                    dayjs()
                      .subtract(1, "day")
                      .format("YYYY-MM-DD") + " 00:00:00"
                  );
                  picker.$emit("pick", [start, end]);
                }
              },
              {
                text: "最近一周",
                onClick(picker) {
                  const end = new Date(
                    dayjs()
                      .add(1, "day")
                      .format("YYYY-MM-DD") + " 00:00:00"
                  );
                  const start = new Date(
                    dayjs()
                      .subtract(7, "day")
                      .format("YYYY-MM-DD") + " 00:00:00"
                  );
                  picker.$emit("pick", [start, end]);
                }
              },
              {
                text: "最近一个月",
                onClick(picker) {
                  const end = new Date(
                    dayjs()
                      .add(1, "day")
                      .format("YYYY-MM-DD") + " 00:00:00"
                  );
                  const start = new Date(
                    dayjs()
                      .subtract(30, "day")
                      .format("YYYY-MM-DD") + " 00:00:00"
                  );
                  picker.$emit("pick", [start, end]);
                }
              },
              {
                text: "最近三个月",
                onClick(picker) {
                  const end = new Date(
                    dayjs()
                      .add(1, "day")
                      .format("YYYY-MM-DD") + " 00:00:00"
                  );
                  const start = new Date(
                    dayjs()
                      .subtract(90, "day")
                      .format("YYYY-MM-DD") + " 00:00:00"
                  );
                  picker.$emit("pick", [start, end]);
                }
              }
            ]
          },
          sectionList:[],
          timePickerValue:"",
          dataList:[],
          saleList:[],
          saleParams: {
            roleSign: "sales",
            status: 1
          },
        }
    },
    created(){

    },
    mounted(){
      this.getSectionList();
    },
    methods:{
      handleSelectionChange(){

      },
      rowClick(){

      },
      handleSizeChange(){

      },
      handleCurrentChange(){

      },
      batchApportion() {
        // if (this.selectStudentListObj.length < 1) {
        //   this.msgWarn("请至少选择一个学生！");
        //   return;
        // } 
        this.getSaleList();
        this.apportionVisible = true;
      },
      getSaleList() {
        listNoPage(this.saleParams).then(res => {
          this.saleList = res;
        });
      },
      saleChange() {
        for (let i = 0, len = this.saleList.length; i < len; i++) {
          if (this.saleList[i].userId == this.saleData.saleId) {
            this.saleData.saleName = this.saleList[i].username;
          }
        }
      },
      confirmApportion(){

      },
      getSectionList() {
        getSelectList({
          parentCode: sectionCode
        }).then(res => {
          if (res.code == 0) {
            this.sectionList = res.rows;
            this.sectionList.unshift({
              macroId: '',
              name: "全部"
            });
          }
        });
      },
      datePickerChange() {
        if (!this.timePickerValue) {
          this.listParams.searchBeginDate = null;
          this.listParams.searchEndDate = null;
          return;
        }
        this.listParams.searchBeginDate = this.timePickerValue[0];
        this.listParams.searchEndDate = this.timePickerValue[1];
      },
    }
}
</script>
<style scoped lang="scss">
    .main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;  
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .class_title {
    height: 30px;
    line-height: 30px;
    text-indent: 12px;
  }
  .table_area {
    margin-left: 20px;
    padding: 10px 4px;
    .class_tip {
      height: 40px;
      line-height: 40px;
      text-indent: 4px;
      span {
        margin-right: 20px;
      }
    }
  }
  .self_table {
    width: 100%;
    border: 1px solid #ebeef5;
    border-collapse: collapse;
    border-spacing: 0px;
    tr {
      border: 1px solid #ebeef5;
      td {
        border: 1px solid #ebeef5;
        text-align: center;
        vertical-align: middle;
        height: 40px;
      }
    }
  }
  .required_subject {
    height: auto;
    margin-bottom: 10px;
  }
  .select_subject {
    .select_subject_item {
      .item_subject_title {
        font-weight: 900;
        padding-left: 32px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
      .item_score {
        display: flex;
        /deep/ .el-input {
          width: 40%;
        }
        > span {
          width: 20px;
          text-align: center;
        }
      }
    }
  }
  .open_tip {
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
 
  .item_title span:nth-child(1){
        font-weight: 900;
        padding-left: 32px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
      .item_title span:nth-child(2){
        font-weight: 500;
        font-size: 16px;
        margin-left:20px;
        color:#000;
      }
}
</style>