<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="批次/编号/学生姓名/联系方式"
            size="small"
            style="min-width:120px"
             @click="()=>(listParams.pageNumber=1,blackAreaFn())"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="listParams.sectionId"
            placeholder="请选择"
            size="small"
            @click="()=>(listParams.pageNumber=1,blackAreaFn())"
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">无效标记：</span>
          <el-input
            v-model="listParams.name"
            placeholder="填入次数"
            size="small"
             @click="()=>(listParams.pageNumber=1,blackAreaFn())"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>批量删除</span>
          </el-button>
          <el-button type="default" size="small" >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>转入管理池</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="areaData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="productListTable"
        @selection-change="handleSelectionChange"
        @row-click="rowItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="no"
          label="批次"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="编号（添加时间）"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="isSubscribe"
          label="学生姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          label="无效标记"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <!-- <el-button
              type="text"
              size="small"
              ></el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
   
  </div>
</template>
<script>
import {
  sectionCode,
  getSelectList,
} from "@/api/public";
export default{
    data(){
        return{
            areaData:[],
            total:0,
            sectionList:[],
            listParams:{
                name:"",
                sectionId:"0",
            }
        }
    },
    created(){
        this.blackAreaFn();
    },
    mounted(){
        this.getSectionList();
    },
    methods:{
        blackAreaFn(){},
        handleSelectionChange(){},
        rowItemClick(){},
        handleSizeChange(){},
        handleCurrentChange(){},
        getSectionList(){
            getSelectList({
                parentCode: sectionCode,
            }).then((res) => {
                if (res.code == 0) {
                this.sectionList = res.rows;
                this.sectionList.unshift({
                    macroId: "0",
                    name: "全部",
                });
                }
            });
        }
    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .center {
    text-align: center;
    min-height: 30px;
  }
  .line {
    height: 0;
    border-bottom: 1px solid #f1f1f1;
    margin-left: 30px;
    margin: 10px 0 10px 30px;
  }
   .row_detail {
      padding: 10px 0;
    }
    .orderList{
      padding-bottom:20px;
      li{
        display:flex;
        margin-top:15px;
        span{
          flex:1;
        }
      }
    }
}
</style>

