<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area">
      <div class="select-condition-item">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">充值记录</el-menu-item>
          <el-menu-item index="2">订单记录</el-menu-item>
        </el-menu>
      </div>
    </div>
    <div class="btn-area" v-show="switchKey == 2">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="学生姓名/联系方式/订单编号/产品名称"
            size="small"
            style="min-width: 120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="listParams.sectionId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">产品类型：</span>
          <el-select
            v-model="listParams.orderType"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in productTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">下单时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
      </div>
      <div class="right">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="() => ((listParams.pageNumber = 1), getOrderList())"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="btn-area" v-show="switchKey == 2">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">订单状态：</span>
          <el-select
            v-model="listParams.orderStatus"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="btn-area" v-show="switchKey == 1">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="rechargeParams.name"
            placeholder="学生姓名/联系方式/订单编号/产品名称"
            size="small"
            style="min-width: 120px"
          ></el-input>
        </div>

        <div class="select-condition-item">
          <span class="item-lable">充值时间：</span>
          <el-date-picker
            size="small"
            v-model="rechargePicker"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="rechargePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">审核状态：</span>
          <el-select
            v-model="rechargeParams.orderStatus"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="right">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="() => ((rechargeParams.pageNumber = 1), rechargeList())"
          >搜索</el-button
        >
      </div>
    </div>
    <p style="padding: 8px 0" v-show="switchKey == 1">
      <span>总计缴费金额：{{ countMap.totalAmount }}</span
      ><!-- <span style="margin-left: 15px"
        >剩余金额：{{ countMap.remainingAmount }}</span
      > -->
    </p>
    <div class="table-area" v-show="switchKey == 2">
      <el-table
        size="small"
        :data="orderList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
      >
        <el-table-column
          prop="studentName"
          label="学生姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="studentSectionName"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="orderNo"
          label="订单编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="formatterProductType"
          label="产品类型"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="formatterClassHour"
          prop="classHour"
          label="总课时"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="formatterTotalPrice"
          prop="totalPrice"
          label="总价"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="下单时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="moneyType"
          :formatter="formatterOrderStatus"
          label="订单状态"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="dealSeeDetail(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="dealEdit(scope.row)"
              v-show="
                scope.row.orderStatus == 1 ||
                scope.row.orderStatus == 2 ||
                scope.row.orderStatus == 8
              "
              >修改</el-button
            >
            <el-button
              type="text"
              v-show="scope.row.orderStatus == 1"
              size="small"
              @click="dealRemove(scope.row)"
              >删除</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="scope.row.orderStatus == 2"
              @click="seeReason(scope.row)"
              >查看原因</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area" v-show="switchKey == 2">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableTotal"
        background
      ></el-pagination>
    </div>
    <div class="table-area" v-show="switchKey == 1">
      <el-table
        size="small"
        :data="rechargeData"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
      >
        <el-table-column
          prop="studentName"
          label="学生姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="totalPrice"
          label="缴费金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="zdyGiveClassHour"
          label="赠送课时"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="formatterMoneyType"
          label="收款类型"
          align="center"
        ></el-table-column>
        <el-table-column
          label="充值时间"
          prop="createTime"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="formatterOrderStatus"
          label="审核状态"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="dealSeeDetail(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="dealEdit(scope.row)"
              v-show="
                scope.row.orderStatus == 1 ||
                scope.row.orderStatus == 2 ||
                scope.row.orderStatus == 8
              "
              >修改</el-button
            >
            <el-button
              type="text"
              v-show="scope.row.orderStatus == 1"
              size="small"
              @click="dealRemove(scope.row)"
              >删除</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="scope.row.orderStatus == 2"
              @click="seeReason(scope.row)"
              >查看原因</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area" v-show="switchKey == 1">
      <el-pagination
        @size-change="rechargeSizeChange"
        @current-change="rechargeCurrentChange"
        :current-page="rechargeParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="rechargeParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="rechargeTotal"
        background
      ></el-pagination>
    </div>
    <el-dialog
      title="修改订单"
      :visible.sync="orderDialogVisible"
      :close-on-click-modal="false"
      width="650px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleFormOrder"
          status-icon
          :rules="saveRuleOrder"
          ref="saveRuleFormOrder"
          label-width="90px"
          size="small"
        >
          <el-form-item label="产品类型" prop="orderType">
            <el-row>
              <el-col :span="7">
                <el-select
                  v-model="saveRuleFormOrder.orderType"
                  placeholder="请选择"
                  @change="orderClick"
                  size="small"
                  :disabled="
                    orderDialog.type == 'see' || orderDialog.type == 'edit'
                  "
                >
                  <el-option value="5" label="一对一充值"></el-option>
                  <el-option value="2" label="一对多"></el-option>
                  <el-option value="3" label="试听课"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="选择产品"
            prop="productId"
            v-show="saveRuleFormOrder.orderType != 5"
            :disabled="orderDialog.type == 'see'"
          >
            <el-row>
              <el-col :span="7">
                <el-select
                  v-model="saveRuleFormOrder.productId"
                  placeholder="请选择"
                  @change="productChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productList"
                    :key="item.id"
                    :label="item.productName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="产品规格"
            v-show="saveRuleFormOrder.orderType != 5"
          >
            <el-row type="flex" v-show="saveRuleFormOrder.orderType != 2">
              <el-col :span="5">
                <el-select
                  v-model="saveRuleFormOrder.sectionId"
                  placeholder="请选择学段"
                  @change="orderSectionChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.sectionList"
                    :key="item.sectionId"
                    :label="item.sectionName"
                    :value="item.sectionId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="5" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.teacherTitleId"
                  placeholder="请选择教师头衔"
                  @change="orderTeacherTitleChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.teacherTitleList"
                    :key="item.teacherTitleId"
                    :label="item.teacherTitleName"
                    :value="item.teacherTitleId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="6" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.classHourId"
                  placeholder="请选择课时"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.classHourList"
                    :key="item.id"
                    :label="item.classHourName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row type="flex" v-show="saveRuleFormOrder.orderType == 2">
              <el-col :span="12">
                <el-select
                  v-model="saveRuleFormOrder.periodId"
                  placeholder="请选择学段"
                  @change="orderSectionChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.periodList"
                    :key="item.periodId"
                    :label="item.periodName"
                    :value="item.periodId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.subjectId"
                  placeholder="请选择学科"
                  @change="orderSubjectChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.subjectList"
                    :key="item.subjectId"
                    :label="item.subjectName"
                    :value="item.subjectId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="mT10"
              v-show="saveRuleFormOrder.orderType == 2"
            >
              <el-col :span="12">
                <el-select
                  v-model="saveRuleFormOrder.teacherTitleId"
                  placeholder="请选择教师头衔"
                  @change="orderTeacherTitleChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.teacherTitleList"
                    :key="item.teacherTitleId"
                    :label="item.teacherTitleName"
                    :value="item.teacherTitleId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.classHourId"
                  placeholder="请选择课时"
                  @change="orderClassHourChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.classHourList"
                    :key="item.id"
                    :label="item.classHourName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="mT10"
              v-show="saveRuleFormOrder.classHourId != -1"
            >
              <el-col :span="12">
                <div class="course_info">
                  <span>课时单价：</span>
                  <span>{{ saveRuleFormOrder.singlePrice }}元</span>
                </div>
              </el-col>
              <el-col :span="12" class="mL20">
                <div class="course_info">
                  <span>总价：</span>
                  <span
                    >{{
                      saveRuleFormOrder.orderType != 2
                        ? saveRuleFormOrder.totalPrice
                        : saveRuleFormOrder.zdyActualPrice
                    }}元</span
                  >
                </div>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="mT20"
              v-show="saveRuleFormOrder.classHourId == -1"
            >
              <el-col :span="8">
                <el-form-item label="课时数：" prop="zdyClassHour">
                  <input
                    type="text"
                    :disabled="orderDialog.type == 'see'"
                    v-model="saveRuleFormOrder.zdyClassHour"
                    class="self_input"
                    onpaste="return false"
                    @keyup="filterZdyClassHour"
                    @blur="zdyClassHourBlur()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="单价：" prop="zdySinglePrice">
                  <input
                    type="text"
                    :disabled="orderDialog.type == 'see'"
                    v-model="saveRuleFormOrder.zdySinglePrice"
                    class="self_input"
                    onpaste="return false"
                    @keyup="fileterZdySinglePrice"
                    @blur="zdySinglePriceBlur()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="总金额：" prop="zdyTotalPrice">
                  <input
                    :disabled="orderDialog.type == 'see'"
                    type="text"
                    v-model="saveRuleFormOrder.zdyTotalPrice"
                    onpaste="return false"
                    @keyup="filterZdyTotalPrice"
                    @blur="zdyTotalPriceBlur"
                    class="self_input"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" v-show="saveRuleFormOrder.classHourId == -1">
              <el-col :span="8">
                <el-form-item label="赠送课时：" prop="zdyGiveClassHour">
                  <input
                    type="text"
                    :disabled="orderDialog.type == 'see'"
                    v-model="saveRuleFormOrder.zdyGiveClassHour"
                    class="self_input"
                    onpaste="return false"
                    @keyup="
                      saveRuleFormOrder.zdyGiveClassHour =
                        saveRuleFormOrder.zdyGiveClassHour.replace(/[^\d]/g, '')
                    "
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="优惠金额：" prop="zdyDiscountAmount">
                  <input
                    :disabled="orderDialog.type == 'see'"
                    type="text"
                    v-model="saveRuleFormOrder.zdyDiscountAmount"
                    class="self_input"
                    onpaste="return false"
                    @keyup="filterZdyDiscountAmount"
                    @blur="zdyDiscountAmountBlur()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实际支付：" prop="zdyActualPrice">
                  <input
                    :disabled="orderDialog.type == 'see'"
                    type="text"
                    v-model="saveRuleFormOrder.zdyActualPrice"
                    class="self_input"
                    onpaste="return false"
                    @keyup="filterZdyActualPrice"
                    @blur="zdyActualPriceBlur"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="缴费金额"
            prop="totalPrice"
            v-show="saveRuleFormOrder.orderType == 5"
          >
            <el-input
              style="width: 140px"
              maxlength="7"
              v-model="saveRuleFormOrder.totalPrice"
              @blur="totalPriceBlur()"
              placeholder="请输入缴费金额"
            ></el-input>
            <span style="color: #909399; font-size: 12px; margin-left: 10px">{{
              translateTotal
            }}</span>
          </el-form-item>
          <el-form-item
            label="赠送课时"
            prop="zdyGiveClassHour"
            v-show="
              saveRuleFormOrder.orderType == 5 &&
              saveRuleFormOrder.moneyType != 4
            "
          >
            <el-input
              style="width: 140px"
              type="number"
              min="0"
              v-model="saveRuleFormOrder.zdyGiveClassHour"
              placeholder="请输入赠送课时"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="优惠金额"
            prop="payType"
            v-show="saveRuleFormOrder.orderType == 2"
          >
            <el-input
              style="width: 140px"
              @blur="morenAmount()"
              v-model="saveRuleFormOrder.zdyDiscountAmount"
            ></el-input>
          </el-form-item>
          <el-form-item label="支付方式" prop="payType">
            <el-radio-group
              v-model="saveRuleFormOrder.payType"
              :disabled="orderDialog.type == 'see'"
            >
              <!-- <el-radio label="1">线上支付</el-radio> -->
              <el-radio label="2">线下支付</el-radio>
              <el-radio label="3">余额支付</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="收款方式"
            prop="payMent"
            v-show="
              (saveRuleFormOrder.orderType == 5 ||
                saveRuleFormOrder.orderType == 2 ||
                saveRuleFormOrder.orderType == 3) &&
              saveRuleFormOrder.payType == 2
            "
          >
            <el-radio-group
              v-model="saveRuleFormOrder.payMent"
              :disabled="orderDialog.type == 'see'"
            >
              <el-radio label="1">支付宝</el-radio>
              <el-radio label="2">微信</el-radio>
              <el-radio label="3">对公账户</el-radio>
              <el-radio label="4">POS机</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="收款类型"
            prop="moneyType"
            v-show="saveRuleFormOrder.orderType != 3"
          >
            <el-radio-group
              v-model="saveRuleFormOrder.moneyType"
              :disabled="orderDialog.type == 'see'"
            >
              <el-radio label="1">全款</el-radio>
              <el-radio label="2">定金</el-radio>
              <el-radio label="3">尾款</el-radio>
              <el-radio label="4">特殊订单</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="付款凭证"
            prop="certificate"
            v-show="saveRuleFormOrder.payType == 2"
          >
            <el-row align="middle" type="flex">
              <el-col :span="12">
                <div class="certificate_box">
                  <el-image
                    class="td-img"
                    style="width: 100px; height: 100px"
                    :src="saveRuleFormOrder.certificate"
                    v-if="saveRuleFormOrder.certificate"
                    :preview-src-list="srcList"
                    @click.stop="handleClickItem2"
                  >
                  </el-image>
                </div>
                <div class="certificate_tip">
                  <span v-show="saveRuleFormOrder.certificate"
                    >点击图片查看大图</span
                  >
                  <span v-show="!saveRuleFormOrder.certificate"
                    >暂未上传凭证</span
                  >
                </div>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  :action="fileUpload"
                  :on-success="certificateSuccess"
                  :on-error="certificateError"
                  :show-file-list="false"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-button
                    size="small"
                    type="primary"
                    :disabled="orderDialog.type == 'see'"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="辅导协议" prop="coachAgreement">
            <el-row align="middle" type="flex">
              <el-col :span="12">
                <div class="coachAgreement_tip">
                  <span v-show="saveRuleFormOrder.coachAgreement"
                    ><a
                      target="view_window"
                      :href="saveRuleFormOrder.coachAgreement"
                      >{{ saveRuleFormOrder.coachAgreementName }}</a
                    >
                  </span>
                  <span v-show="!saveRuleFormOrder.coachAgreement"
                    >还未上传辅导协议</span
                  >
                </div>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  :action="fileUpload"
                  :on-success="coachAgreementSuccess"
                  :on-error="coachAgreementError"
                  :show-file-list="false"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-button
                    size="small"
                    type="primary"
                    :disabled="orderDialog.type == 'see'"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-row align="middle" type="flex">
              <el-col :span="17">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="saveRuleFormOrder.remark"
                >
                </el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        v-show="orderDialog.type != 'see'"
      >
        <el-button @click="orderDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          :disabled="isDisable"
          @click="confirmSaveOrder('saveRuleFormOrder')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <span>{{ centerData }}</span>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false"
          >好，知道了</el-button
        >
      </div>
    </el-dialog>
    <downOrder
      ref="downOrder"
      :downOrderStatus="downOrderStatus"
      :downParams="downParams"
      @CB_downOrderStatus="CB_downOrderStatus"
    ></downOrder>
    <orderInfo
      ref="orderInfo"
      :orderInfoStatus="orderInfoStatus"
      :orderInfoParams="orderInfoParams"
      @CB_orderInfoShow="CB_orderInfoShow"
    ></orderInfo>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  constellation,
  teachStyle,
  teachYear,
  teachSpeed,
} from "@/api/public";
import {
  orderListNoPage,
  orderDetail,
  orderRemove,
  checkOneToOneOrder,
  addOrder,
  logicalDeletion,
} from "@/api/sales/myCustomer";
import { studentInfo } from "@/api/sales/saleManage.js";
import orderInfo from "../../finance/dialog/orderInfo.vue";
import {
  productListNoPage,
  productInfoDetail,
} from "@/api/operates/productManage";
import downOrder from "../../teachBusiness/expertTeachBusiness/dialog/studentManageFile/downOrder.vue";
export default {
  components: {
    downOrder,
    orderInfo,
  },
  data() {
    return {
      orderInfoStatus: false,
      orderInfoParams: {
        id: "",
        studentId: "",
      },
      downOrderStatus: false,
      downParams: {
        id: "",
      },
      translateTotal: "",
      rechargePicker: "",
      switchKey: "1",
      activeIndex: "1",
      isDisable: false,
      id: "",
      centerData: "",
      centerDialogVisible: false,
      fileUpload,
      countMap: {
        totalAmount: 0,
        remainingAmount: 0,
      },
      listParams: {
        dataAuthority: "sale",
        pageNumber: 1,
        pageSize: 10,
        name: "",
        orderType: "",
        courseTypeId: "",
        sectionId: "",
        orderStatus: "",
      },
      rechargeParams: {
        dataAuthority: "sale",
        pageNumber: 1,
        pageSize: 10,
        name: "",
        orderType: 5,
        courseTypeId: "",
        sectionId: "",
        orderStatus: "",
      },
      total: 0,
      totalPriceNum: "",
      sectionList: [],
      timePickerValue: "",
      orderList: [],
      orderDialogVisible: false,
      srcList: [],
      infoOrderType: "",
      studentDetail: {},
      orderDetail: {},
      saveRuleFormOrder: {},
      saveRuleOrder: {},
      orderDialog: {
        title: "修改下单",
        type: "edit",
      },
      tableTotal: 0,
      productList: [],
      subjectList: [],
      productPid: "",
      productDetail: {
        productPackage: [],
        sectionList: [],
        sectionIdList: [],
        subjectList: [],
        subjectIdList: [],
        teacherTitleList: [],
        teacherTitleIdList: [],
        classHourList: [],
        classHourIdList: [],
      },
      courseTypeIdList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "大班课",
        },
        {
          value: 2,
          label: "小班课",
        },
        {
          value: 3,
          label: "答疑课",
        },
      ],
      orderStatusList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "待财务审核",
        },
        {
          value: 2,
          label: "财务审核拒绝",
        },
        {
          value: 3,
          label: "教务主管分配中",
        },
        {
          value: 4,
          label: "待排课",
        },
        {
          value: 5,
          label: "排课中",
        },
        {
          value: 6,
          label: "已退款",
        },
        {
          value: 7,
          label: "订单已完成",
        },
        {
          value: 8,
          label: "订单作废",
        },
        {
          value: 9,
          label: "退款中",
        },
      ],
      productTypeList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 3,
          label: "试听课",
        },
        {
          value: 2,
          label: "一对多",
        },
        {
          value: 4,
          label: "公开课",
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs().subtract(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(90, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      rechargeData: [],
      rechargeTotal: 0,
    };
  },
  created() {
    this.rechargeList();
  },
  methods: {
    CB_orderInfoShow() {
      this.orderInfoStatus = false;
    },
    handleSelect(key, keyPath) {
      if (key == 1) {
        //充值记录
        this.switchKey = key;
        this.rechargeList();
      } else if (key == 2) {
        //订单记录
        this.switchKey = key;
        this.getOrderList();
      }
    },
    rechargeList() {
      orderListNoPage(this.rechargeParams).then((res) => {
        if (res.countMap) {
          this.countMap = {
            remainingAmount: res.countMap.remainingAmount,
            totalAmount: res.countMap.totalAmount,
          };
        }
        this.rechargeData = res.rows;
        this.rechargeTotal = res.total;
      });
    },
    datePickerChange() {
      if (!this.timePickerValue) {
        this.listParams.searchBeginDate = null;
        this.listParams.searchEndDate = null;
        return;
      }
      this.listParams.searchBeginDate = this.timePickerValue[0];
      this.listParams.searchEndDate = this.timePickerValue[1];
    },
    morenAmount() {
      this.saveRuleFormOrder.zdyActualPrice =
        Number(this.saveRuleFormOrder.zdyTotalPrice) -
        Number(this.saveRuleFormOrder.zdyDiscountAmount);
    },
    CB_downOrderStatus() {
      this.downOrderStatus = false;
      this.getOrderList();
      this.rechargeList();
    },
    rechargePickerChange() {
      if (!this.rechargePicker) {
        this.rechargeParams.searchBeginDate = null;
        this.rechargeParams.searchEndDate = null;
        return;
      }
      this.rechargeParams.searchBeginDate = this.timePickerValue[0];
      this.rechargeParams.searchEndDate = this.timePickerValue[1];
    },
    getOrderList() {
      orderListNoPage(this.listParams).then((res) => {
        this.orderList = res.rows;
        this.tableTotal = res.total;
      });
    },
    formatterMoneyType(row) {
      switch (row.moneyType) {
        case 1:
          return "全款";
        case 2:
          return "定金";
        case 3:
          return "尾款";
        case 4:
          return "特殊订单";
        case 5:
          return "余额转充值";
        default:
          return "/";
      }
    },
    dealEdit(row) {
      // this.orderDialogVisible=true;
      // this.productPid=row.productPid;
      //  this.id=row.studentId;
      //  this.srcList=[]
      // this.getOrderDetail(row.id);
      this.downOrderStatus = true;
      this.downParams = {
        id: row.studentId,
        type: "edit",
        classId: row.id,
      };
    },
    dealRemove(row) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          logicalDeletion({ id: row.id }).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getOrderList();
            }
          });
        })
        .catch(() => {});
    },
    getOrderDetail(id) {
      orderDetail(id).then((res) => {
        if (res.code == 0) {
          this.curEditOrder = id;
          if (res.rows.productType == 2) {
            this.editOrderId = res.rows.productId;
          } else {
            this.editOrderId = res.rows.productPid;
          }
          this.saveRuleFormOrder = {
            orderType: String(res.rows.orderType), //1一对一  2一对多  3试听课
            payType: String(res.rows.payType), //1线上支付   2线下支付
            courseTypeId: res.rows.courseTypeId
              ? String(res.rows.courseTypeId)
              : null, //1大班课  2小班课  3答疑课
            productId: res.rows.productId,
            certificate: res.rows.certificate, //付款凭证
            coachAgreement: res.rows.coachAgreement, //辅导协议
            sectionId: res.rows.sectionId, //学段
            subjectId: res.rows.subjectId,
            teacherTitleId: res.rows.teacherTitleId,
            classHour: res.rows.classHour,
            classHourId: res.rows.classHourId, //不用传到后台
            singlePrice: res.rows.singlePrice,
            totalPrice: res.rows.totalPrice,
            zdyClassHour: res.rows.zdyClassHour,
            zdySinglePrice: res.rows.zdySinglePrice,
            zdyGiveClassHour: res.rows.zdyGiveClassHour,
            zdyDiscountAmount: res.rows.zdyDiscountAmount,
            zdyActualPrice: res.rows.zdyActualPrice,
            studentId: this.id,
            productPackageId: res.rows.productPackageId,
            remark: res.rows.remark,
            payMent: res.rows.payMent ? String(res.rows.payMent) : null,
            moneyType: res.rows.moneyType ? String(res.rows.moneyType) : null,
            coachAgreementName: res.rows.coachAgreementName,
            zdyTotalPrice: res.rows.zdyTotalPrice,
          };
          this.srcList.push(res.rows.certificate);
          this.numberToString(res.rows.totalPrice);
          this.orderClick();
        }
      });
      console.log(this.saveRuleFormOrder, "totalPrice...");
    },
    async orderClick() {
      await this.getProductList();
      this.translateTotal = "";
      for (var i = 0; i < this.productList.length; i++) {
        if (this.productList[i].id == this.editOrderId) {
          this.saveRuleFormOrder.productId = this.productList[i].id;
        }
      }
      if (this.saveRuleFormOrder.orderType != 5) {
        this.getProductDetail();
      }
      // await this.getProductList();
      // if(this.productList.length >0){
      //   for(var i=0;i<this.productList.length;i++){
      //     if(this.productList[i].id== this.editOrderId){
      //       this.saveRuleFormOrder.productId = this.productList[i].id;
      //     }
      //   }
      //   this.getProductDetail()
      // }else{
      //   this.saveRuleFormOrder.sectionId = ""
      //   this.saveRuleFormOrder.productId = ""
      //   this.saveRuleFormOrder.periodId = ""
      //   this.saveRuleFormOrder.subjectId = ""
      //   this.saveRuleFormOrder.teacherTitleId = ""
      //   this.saveRuleFormOrder.classHourId = ""
      //   this.saveRuleFormOrder.singlePrice = ""
      //   // this.saveRuleFormOrder.totalPrice  = ""
      // }
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getOrderList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getOrderList();
    },
    rechargeSizeChange(pageSize) {
      this.rechargeParams.pageSize = pageSize;
      this.rechargeList();
    },
    rechargeCurrentChange(currentPage) {
      this.rechargeParams.pageNumber = currentPage;
      this.rechargeList();
    },
    getProductList() {
      if (this.saveRuleFormOrder.orderType == 3) {
        //试听课
        return productListNoPage({
          isSubscribe: 1,
          courseTypeId:
            this.saveRuleFormOrder.orderType == 2
              ? this.saveRuleFormOrder.courseTypeId
              : null,
        }).then((res) => {
          if (res.code == 0) {
            this.productList = res.rows;
          }
        });
      } else if (this.saveRuleFormOrder.orderType == 1) {
        //一对一
        return productListNoPage({
          productType: this.saveRuleFormOrder.orderType,
          isSubscribe: 0,
          courseTypeId:
            this.saveRuleFormOrder.orderType == 2
              ? this.saveRuleFormOrder.courseTypeId
              : null,
        }).then((res) => {
          if (res.code == 0) {
            this.productList = res.rows;
          }
        });
      } else if (this.saveRuleFormOrder.orderType == 2) {
        //一对多
        return productListNoPage({
          productType: this.saveRuleFormOrder.orderType,
          courseTypeId:
            this.saveRuleFormOrder.orderType == 2
              ? this.saveRuleFormOrder.courseTypeId
              : null,
        }).then((res) => {
          if (res.code == 0) {
            this.productList = res.rows;
          }
        });
      }
    },
    totalPriceBlur() {
      var totalPriceNum = this.saveRuleFormOrder.totalPrice;
      this.numberToString(totalPriceNum);
    },
    numberToString(n) {
      if (!/^(0|\-*[1-9]\d*)(\.\d+)?$/.test(n)) return "数据非法";
      var fuhao = n.toString().indexOf("-") == 0 ? "负" : "";

      var unit = "千百拾亿千百拾万千百拾元角分",
        str = "";
      n += "00";
      //如果是负数就就截取
      if (fuhao == "负") {
        n = n.substring(1, n.length);
      }
      var p = n.indexOf(".");
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++)
        str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i);
      this.translateTotal =
        fuhao +
        str
          .replace(/零(千|百|拾|角)/g, "零")
          .replace(/(零)+/g, "零")
          .replace(/零(万|亿|元)/g, "$1")
          .replace(/(亿)万|壹(拾)/g, "$1$2")
          .replace(/^元零?|零分/g, "")
          .replace(/元$/g, "元整");
    },
    getProductDetail() {
      return productInfoDetail(this.saveRuleFormOrder.productId).then((res) => {
        if (res.code == 0) {
          this.productDetail.productPackage =
            res.rows.xmtProductPackageEntityList;
          if (this.saveRuleFormOrder.orderType == 2) {
            this.dealPeriod();
          } else {
            this.dealSection();
          }
        }
      });
    },
    dealPeriod() {
      this.productDetail.periodList = [];
      this.productDetail.periodIdList = [];
      this.saveRuleFormOrder.periodId = "";
      for (
        let i = 0, len = this.productDetail.productPackage.length;
        i < len;
        i++
      ) {
        if (
          !this.productDetail.periodIdList.includes(
            this.productDetail.productPackage[i].periodId
          ) &&
          this.productDetail.productPackage[i].periodId
        ) {
          this.productDetail.periodList.push({
            periodName: this.productDetail.productPackage[i].periodName,
            periodId: this.productDetail.productPackage[i].periodId,
          });
          this.productDetail.periodIdList.push(
            this.productDetail.productPackage[i].periodId
          );
        }
      }
      if (this.productDetail.periodList.length > 0) {
        this.saveRuleFormOrder.periodId =
          this.productDetail.periodList[0].periodId;
      }
      this.dealSubject();
    },
    seeReason(row) {
      this.centerDialogVisible = true;
      this.centerData = row.disagreeCause ? row.disagreeCause : "";
    },
    dealSection() {
      this.productDetail.sectionList = [];
      this.productDetail.sectionIdList = [];
      for (
        let i = 0, len = this.productDetail.productPackage.length;
        i < len;
        i++
      ) {
        if (
          !this.productDetail.sectionIdList.includes(
            this.productDetail.productPackage[i].sectionId
          )
        ) {
          this.productDetail.sectionList.push({
            sectionName: this.productDetail.productPackage[i].sectionName,
            sectionId: this.productDetail.productPackage[i].sectionId,
          });
          this.productDetail.sectionIdList.push(
            this.productDetail.productPackage[i].sectionId
          );
        }
      }
      this.saveRuleFormOrder.sectionId =
        this.productDetail.sectionList[0].sectionId;
      this.dealSubject();
    },
    dealSubject() {
      if (this.saveRuleFormOrder.orderType == 2) {
        this.productDetail.subjectList = [];
        this.productDetail.subjectIdList = [];
        //  this.saveRuleFormOrder.subjectId = "";
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.sectionId ==
              this.productDetail.productPackage[i].sectionId &&
            !this.productDetail.subjectIdList.includes(
              this.productDetail.productPackage[i].subjectId
            )
          ) {
            this.productDetail.subjectList.push({
              subjectName: this.productDetail.productPackage[i].subjectName,
              subjectId: this.productDetail.productPackage[i].subjectId,
            });
            this.productDetail.subjectIdList.push(
              this.productDetail.productPackage[i].subjectId
            );
          }
        }
        if (this.productDetail.subjectList.length > 0) {
          this.saveRuleFormOrder.subjectId =
            this.productDetail.subjectList[0].subjectId;
        }
      }
      this.dealTeacherTitle();
    },
    dealTeacherTitle() {
      this.productDetail.teacherTitleList = [];
      this.productDetail.teacherTitleIdList = [];
      if (this.saveRuleFormOrder.orderType != 2) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.sectionId ==
              this.productDetail.productPackage[i].sectionId &&
            !this.productDetail.teacherTitleIdList.includes(
              this.productDetail.productPackage[i].teacherTitleId
            )
          ) {
            this.productDetail.teacherTitleList.push({
              teacherTitleName:
                this.productDetail.productPackage[i].teacherTitleName,
              teacherTitleId:
                this.productDetail.productPackage[i].teacherTitleId,
            });
            this.productDetail.teacherTitleIdList.push(
              this.productDetail.productPackage[i].teacherTitleId
            );
          }
        }
        this.saveRuleFormOrder.teacherTitleId =
          this.productDetail.teacherTitleList[0].teacherTitleId;
      } else if (this.saveRuleFormOrder.orderType == 2) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            this.saveRuleFormOrder.subjectId ==
              this.productDetail.productPackage[i].subjectId &&
            !this.productDetail.teacherTitleIdList.includes(
              this.productDetail.productPackage[i].teacherTitleId
            )
          ) {
            this.productDetail.teacherTitleList.push({
              teacherTitleName:
                this.productDetail.productPackage[i].teacherTitleName,
              teacherTitleId:
                this.productDetail.productPackage[i].teacherTitleId,
            });
            this.productDetail.teacherTitleIdList.push(
              this.productDetail.productPackage[i].teacherTitleId
            );
          }
        }
        this.saveRuleFormOrder.teacherTitleId =
          this.productDetail.teacherTitleList[0].teacherTitleId;
      }
      this.dealClassHour();
    },
    dealClassHour() {
      this.productDetail.classHourList = [];
      this.productDetail.classHourIdList = [];
      if (this.saveRuleFormOrder.orderType != 2) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.sectionId ==
              this.productDetail.productPackage[i].sectionId &&
            this.saveRuleFormOrder.teacherTitleId ==
              this.productDetail.productPackage[i].teacherTitleId &&
            !this.productDetail.classHourIdList.includes(
              this.productDetail.productPackage[i].id
            )
          ) {
            this.productDetail.classHourList.push({
              classHour: this.productDetail.productPackage[i].classHour,
              classHourName:
                this.productDetail.productPackage[i].classHour + "课时",
              id: this.productDetail.productPackage[i].id,
              singlePrice: this.productDetail.productPackage[i].singlePrice,
              totalPrice: this.productDetail.productPackage[i].totalPrice,
            });
            this.productDetail.classHourIdList.push(
              this.productDetail.productPackage[i].id
            );
          }
        }
        //  if(this.saveRuleFormOrder.orderType !=3){

        this.productDetail.classHourList.push({
          classHour: "自定义课时",
          classHourName: "自定义课时",
          id: -1,
        });

        console.log(
          this.productDetail.classHourList,
          "this.productDetail.classHourList..."
        );
      } else if (this.saveRuleFormOrder.orderType == 2) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            this.saveRuleFormOrder.subjectId ==
              this.productDetail.productPackage[i].subjectId &&
            this.saveRuleFormOrder.teacherTitleId ==
              this.productDetail.productPackage[i].teacherTitleId &&
            !this.productDetail.classHourIdList.includes(
              this.productDetail.productPackage[i].id
            )
          ) {
            this.productDetail.classHourList.push({
              classHour: this.productDetail.productPackage[i].classHour,
              classHourName:
                this.productDetail.productPackage[i].classHour + "课时",
              id: this.productDetail.productPackage[i].id,
              singlePrice: this.productDetail.productPackage[i].singlePrice,
              totalPrice: this.productDetail.productPackage[i].totalPrice,
            });
            this.productDetail.classHourIdList.push(
              this.productDetail.productPackage[i].id
            );
          }
        }
      }
      this.saveRuleFormOrder.productPackageId =
        this.productDetail.classHourList[0].id;
      this.saveRuleFormOrder.classHour =
        this.productDetail.classHourList[0].classHour;
      if (this.saveRuleFormOrder.zdyClassHour) {
        this.saveRuleFormOrder.classHourId = -1;
      } else {
        this.saveRuleFormOrder.classHourId =
          this.productDetail.classHourList[0].id;
      }

      this.saveRuleFormOrder.singlePrice =
        this.productDetail.classHourList[0].singlePrice;
      this.saveRuleFormOrder.totalPrice =
        this.productDetail.classHourList[0].totalPrice;
      this.saveRuleFormOrder.zdyTotalPrice =
        this.productDetail.classHourList[0].totalPrice;
      this.saveRuleFormOrder.zdyActualPrice =
        this.productDetail.classHourList[0].totalPrice;
    },
    orderClassHourChange() {
      for (
        let i = 0, len = this.productDetail.classHourList.length;
        i < len;
        i++
      ) {
        if (
          this.productDetail.classHourList[i].id ==
            this.saveRuleFormOrder.classHourId &&
          this.saveRuleFormOrder.classHourId != -1
        ) {
          this.saveRuleFormOrder.productPackageId =
            this.productDetail.classHourList[i].id;
          this.saveRuleFormOrder.classHour =
            this.productDetail.classHourList[i].classHour;
          this.saveRuleFormOrder.classHourId =
            this.productDetail.classHourList[i].id;
          this.saveRuleFormOrder.singlePrice =
            this.productDetail.classHourList[i].singlePrice;
          this.saveRuleFormOrder.totalPrice =
            this.productDetail.classHourList[i].totalPrice;
        } else {
          this.saveRuleFormOrder.productPackageId = "";
          this.saveRuleFormOrder.classHour = "";
          this.saveRuleFormOrder.classHourId =
            this.productDetail.classHourList[i].id;
          this.saveRuleFormOrder.singlePrice = "";
          this.saveRuleFormOrder.totalPrice = "";
        }
      }
    },
    zdySinglePriceBlur() {
      this.saveRuleFormOrder.zdyActualPrice =
        Number(this.saveRuleFormOrder.zdyClassHour) *
          Number(this.saveRuleFormOrder.zdySinglePrice) -
        Number(this.saveRuleFormOrder.zdyDiscountAmount);
    },
    orderTeacherTitleChange() {
      this.dealClassHour();
    },
    productChange() {
      this.getProductDetail();
    },
    orderSectionChange() {
      this.dealSubject();
    },
    certificateSuccess(response, file, fileList) {
      if (response[0].url) {
        this.saveRuleFormOrder.certificate = response[0].url;
        this.srcList = [];
        this.srcList.push(response[0].url);
      }
      console.log(this.srcList);
    },
    certificateError(err, file, fileList) {
      this.msgError("付款凭证上传失败！");
    },
    coachAgreementError(err, file, fileList) {
      this.msgError("辅导协议上传失败！");
    },
    coachAgreementSuccess(response, file, fileList) {
      if (response[0].url) {
        this.saveRuleFormOrder.coachAgreement = response[0].url;
        this.saveRuleFormOrder.coachAgreementName = response[0].originalName;
      }
    },
    orderSubjectChange() {
      this.dealTeacherTitle();
    },
    confirmSaveOrder(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let obj = {};
          this.isDisable = true;
          if (this.saveRuleFormOrder.orderType == 3) {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              payType: this.saveRuleFormOrder.payType,
              productId: this.saveRuleFormOrder.productId,
              sectionId: this.saveRuleFormOrder.sectionId,
              teacherTitleId: this.saveRuleFormOrder.teacherTitleId,
              studentId: this.id,
              coachAgreement: this.saveRuleFormOrder.coachAgreement,
              coachAgreementName: this.saveRuleFormOrder.coachAgreementName,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
            };
            if (this.saveRuleFormOrder.classHourId == -1) {
              obj.zdyClassHour = this.saveRuleFormOrder.zdyClassHour;
              obj.zdySinglePrice = this.saveRuleFormOrder.zdySinglePrice;
              obj.zdyGiveClassHour = this.saveRuleFormOrder.zdyGiveClassHour;
              obj.zdyDiscountAmount = this.saveRuleFormOrder.zdyDiscountAmount;
              obj.zdyActualPrice = this.saveRuleFormOrder.zdyActualPrice;
              obj.zdyTotalPrice = this.saveRuleFormOrder.zdyTotalPrice;
              obj.classHour = "";
              obj.singlePrice = "";
              obj.totalPrice = "";
            } else {
              obj.zdyClassHour = "";
              obj.zdySinglePrice = "";
              obj.zdyGiveClassHour = 0;
              obj.zdyDiscountAmount = "";
              obj.zdyActualPrice = "";
              obj.zdyTotalPrice = "";
              obj.productPackageId = this.saveRuleFormOrder.productPackageId;
              obj.classHour = this.saveRuleFormOrder.classHour;
              obj.singlePrice = this.saveRuleFormOrder.singlePrice;
              obj.totalPrice = this.saveRuleFormOrder.totalPrice;
            }
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          } else if (this.saveRuleFormOrder.orderType == 2) {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              courseTypeId: this.saveRuleFormOrder.courseTypeId,
              payType: this.saveRuleFormOrder.payType,
              productId: this.saveRuleFormOrder.productId,
              sectionId: this.saveRuleFormOrder.sectionId,
              studentId: this.id,
              teacherTitleId: this.saveRuleFormOrder.teacherTitleId,
              subjectId: this.saveRuleFormOrder.subjectId,
              coachAgreement: this.saveRuleFormOrder.coachAgreement,
              coachAgreementName: this.saveRuleFormOrder.coachAgreementName,
              classHour: this.saveRuleFormOrder.classHour,
              singlePrice: this.saveRuleFormOrder.singlePrice,
              // totalPrice: this.saveRuleFormOrder.totalPrice,
              productPackageId: this.saveRuleFormOrder.productPackageId,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
              zdyActualPrice: this.saveRuleFormOrder.zdyActualPrice,
              zdyTotalPrice: this.saveRuleFormOrder.totalPrice,
              zdyDiscountAmount: this.saveRuleFormOrder.zdyDiscountAmount,
            };
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          } else {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              payType: this.saveRuleFormOrder.payType,
              sectionId: this.saveRuleFormOrder.sectionId,
              subjectId: this.saveRuleFormOrder.subjectId,
              studentId: this.id,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
              totalPrice: this.saveRuleFormOrder.totalPrice,
              zdyGiveClassHour: this.saveRuleFormOrder.zdyGiveClassHour,
            };
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          }
          if (this.orderDialog.type == "edit") {
            await orderRemove([this.curEditOrder]).then((res) => {
              if (res.code == 0 && res.msg == "操作成功！") {
              } else {
                this.msgWarn(res.rows);
                this.isDisable = false;
                return false;
              }
            });
          }
          addOrder(obj).then((res) => {
            if (res.code == 0 && res.msg == "下单成功") {
              this.orderDialogVisible = false;
              this.isDisable = false;
              this.msgSuccess("下单成功！");
              this.getOrderList();
              this.rechargeList();
            } else {
              this.msgError(res.rows);
              this.isDisable = false;
            }
          });
        } else {
          this.msgWarn("必填字段不能为空！");
          this.isDisable = false;
          return false;
        }
      });
    },
    getSectionList() {
      getSelectList({
        parentCode: sectionCode,
      }).then((res) => {
        if (res.code == 0) {
          this.sectionList = res.rows;
          this.sectionList.unshift({
            macroId: "",
            name: "全部",
          });
        }
      });
    },
    filterZdyClassHour() {
      // 课时大于等于1的正整数
      this.saveRuleFormOrder.zdyClassHour =
        this.saveRuleFormOrder.zdyClassHour.replace(/[^\d]/g, "");
      if (this.saveRuleFormOrder.zdyClassHour.length == 1) {
        this.saveRuleFormOrder.zdyClassHour =
          this.saveRuleFormOrder.zdyClassHour.replace(/[^1-9]/gi, "");
      } else if (this.saveRuleFormOrder.zdyClassHour.length > 1) {
        this.saveRuleFormOrder.zdyClassHour =
          this.saveRuleFormOrder.zdyClassHour.replace(/^0/, "");
      }
    },
    zdyClassHourBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdyClassHour) {
        // 处理单价和总价
        if (zdySinglePrice) {
          zdyTotalPrice = zdyClassHour * zdySinglePrice;
        } else if (zdyTotalPrice) {
          zdySinglePrice =
            Math.round((zdyTotalPrice / zdyClassHour) * 100) / 100;
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
        zdyTotalPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    fileterZdySinglePrice() {
      this.saveRuleFormOrder.zdySinglePrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdySinglePrice
      );
    },
    zdySinglePriceBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdySinglePrice) {
        if (zdySinglePrice === "0") {
          this.msgWarn(
            "单价不合法，必须是必须是大于0的最多保留两位小数的数字！"
          );
          zdySinglePrice = "";
        }
        // 处理课时和总价
        if (zdyClassHour) {
          zdyTotalPrice = zdySinglePrice * zdyClassHour;
        } else if (zdyTotalPrice) {
          let remainder = zdyTotalPrice % zdySinglePrice;
          zdyClassHour =
            Math.round((zdyTotalPrice / zdySinglePrice) * 100) / 100;
          if (remainder) {
            this.msgWarn(
              `计算出的课时数为${zdyClassHour},不合法，请重新输入！`
            );
            zdyClassHour = "";
          }
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
        zdyTotalPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    filterZdyTotalPrice() {
      this.saveRuleFormOrder.zdyTotalPrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyTotalPrice
      );
    },
    zdyTotalPriceBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdyTotalPrice) {
        // 处理课时和单价
        if (zdyClassHour && zdySinglePrice) {
          if (zdyClassHour * zdySinglePrice !== zdyTotalPrice) {
            zdyClassHour = "";
            zdySinglePrice = "";
          }
        } else if (zdyClassHour && !zdySinglePrice) {
          zdySinglePrice =
            Math.round((zdyTotalPrice / zdyClassHour) * 100) / 100;
        } else if (!zdyClassHour && zdySinglePrice) {
          let remainder = zdyTotalPrice % zdySinglePrice;
          zdyClassHour =
            Math.round((zdyTotalPrice / zdySinglePrice) * 100) / 100;
          if (remainder) {
            this.msgWarn(
              `计算出的课时数为${zdyClassHour},不合法，请重新输入！`
            );
            zdyClassHour = "";
          }
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    filterZdyDiscountAmount() {
      this.saveRuleFormOrder.zdyDiscountAmount = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyDiscountAmount
      );
    },
    zdyDiscountAmountBlur() {
      let { zdyDiscountAmount, zdyTotalPrice, zdyActualPrice } =
        this.saveRuleFormOrder;
      if (zdyDiscountAmount && zdyTotalPrice) {
        if (zdyDiscountAmount > zdyTotalPrice) {
          this.msgWarn("优惠价格不能大于总价！");
          zdyDiscountAmount = "";
        }
      }
      zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    filterZdyActualPrice() {
      this.saveRuleFormOrder.zdyActualPrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyActualPrice
      );
    },
    zdyActualPriceBlur() {
      let { zdyDiscountAmount, zdyTotalPrice, zdyActualPrice } =
        this.saveRuleFormOrder;
      if (zdyActualPrice && zdyTotalPrice) {
        if (zdyActualPrice > zdyTotalPrice) {
          this.msgWarn("实际支付不能大于总价格，请重新输入！");
          zdyActualPrice = zdyTotalPrice;
        }
        zdyDiscountAmount = zdyTotalPrice - zdyActualPrice;
      } else if (zdyActualPrice && zdyDiscountAmount) {
        if (zdyActualPrice < zdyDiscountAmount) {
          zdyDiscountAmount = "";
        }
        zdyTotalPrice = zdyActualPrice - zdyDiscountAmount;
      } else {
        zdyTotalPrice = zdyActualPrice - zdyDiscountAmount;
      }
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    dealNumTofix2(str) {
      if (str != "" && str.substr(0, 1) == ".") {
        str = "";
      }
      str = str.replace(/^0*(0\.|[1-9])/, "$1"); //解决 粘贴不生效
      str = str.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      str = str.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      str = str.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      str = str.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (str.indexOf(".") < 0 && str != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (str.substr(0, 1) == "0" && str.length == 2) {
          str = str.substr(1, str.length);
        }
      }
      return str;
    },
    searchFn() {
      this.getOrderList();
    },
    formatterProductType(item) {
      switch (item.productType) {
        case 1:
        case 3:
          return item.isSubscribe == 1 ? "试听课" : "一对一";
        case 2:
        case 5:
          return "一对多";
        case 4:
          return "公开课";
      }
    },
    formatterCourseType(item) {
      switch (item.courseTypeId) {
        case 1:
          return "大班课";
        case 2:
          return "小班课";
        case 3:
          return "答疑课";
        default:
          return "/";
      }
    },
    //  orderStatus1、待财务审核  2、财务审核拒绝  3、教务主管分配中（财务审核通过）  4、待排课  5、排课中  6、已退款 7、订单已完成  8、订单已作废
    formatterOrderStatus(item) {
      switch (item.orderStatus) {
        case 1:
          return "待财务审核";
        case 2:
          return "财务审核拒绝";
        case 3:
          return "教务主管分配中（财务审核通过）";
        case 4:
          return "待排课";
        case 5:
          return "排课中";
        case 6:
          return "已退款";
        case 7:
          return "订单已完成";
        case 8:
          return "订单已作废";
        case 9:
          return "退款中";
        default:
          return "";
      }
    },
    formatterClassHour(item) {
      if (item.zdyClassHour != null || item.classHour != null) {
        return item.zdyClassHour
          ? item.zdyClassHour + "课时"
          : item.classHour + "课时";
      } else {
        return "";
      }
    },
    formatterTotalPrice(item) {
      if (item.zdyActualPrice != null || item.totalPrice != null) {
        return item.zdyActualPrice != null
          ? item.zdyActualPrice + "元"
          : item.totalPrice + "元";
      } else {
        return "";
      }
    },
    handleClickItem() {
      this.$nextTick(() => {
        let domImageMask = document.querySelector(".el-image-viewer__mask");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
          document.querySelector(".el-image-viewer__close").click();
        });
      });
    },
    handleClickItem2() {
      this.$nextTick(() => {
        let domImageMask = document.querySelector(".el-image-viewer__mask");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
          document.querySelector(".el-image-viewer__close").click();
        });
      });
    },
    dealSeeDetail(row) {
      this.orderInfoStatus = true;
      this.orderInfoParams = {
        id: row.id,
        studentId: row.studentId,
      };
    },
  },
  mounted() {
    this.getSectionList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
    .right {
      width: 100px;
      display: flex;
      // justify-content: flex-end;
      /deep/ .el-button + .el-button {
        margin-left: 20px;
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color: #000;
    }
    .row_detail {
      padding: 6px 0;
    }
    .row_detail2 {
      span {
        font-weight: bold;
        color: #ff3f3f;
      }
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .self_input {
    padding-bottom: 4px;
    color: #606266;
    text-align: center;
    width: 90%;
    overflow: hidden;
    outline: none;
    border: none;
    border-bottom: 1px solid #c0c4cc;
  }
  .self_input:focus {
    border-bottom: 1px solid #1890ff;
  }
   a {
    text-decoration: none;
  }
}
/deep/.el-image-viewer__close {
  color: #fff !important;
}
>>> .td-img .el-icon-circle-close {
  color: white !important;
}
</style>
